<template>
  <div class="sl-mb-10px sl-sub-header-charcoal-light-40">Site Contact</div>

  <!-- Line 1 -->
  <div class="sl-flex-justify-content-space-btwn sl-mb-14px">
    <div style="width: 50%" class="sl-mr-10px">
      <div class="sl-para-small">First name</div>
      <div>
        <input type="text"
               maxlength="50"
               class="sl-input-type-1"
               v-model="this.passed_in_order_form_data.site_contact_data.first_name" />
      </div>
    </div>

    <div style="width: 50%">
      <div class="sl-para-small">Last Name</div>
      <div>
        <input type="text" maxlength="50" class="sl-input-type-1" v-model="this.passed_in_order_form_data.site_contact_data.last_name" />
      </div>
    </div>
  </div>

  <!-- Line 2 -->
  <div class="sl-flex-justify-content-space-btwn sl-mb-14px">
    <div style="width: 45%" class="sl-mr-10px">
      <div class="sl-para-small">Primary Phone</div>
      <div>
        <input type="text"
               maxlength="50"
               class="sl-input-type-1"
               v-model="this.passed_in_order_form_data.site_contact_data.phone" />
      </div>
    </div>

    <div style="width: 45%" class="sl-mr-10px">
      <div class="sl-para-small">Email</div>
      <div>
        <input type="text" maxlength="50" class="sl-input-type-1" v-model="this.passed_in_order_form_data.site_contact_data.email" />
      </div>
    </div>

    <div style="width: 120px;" class="sl-flex-align-items-flex-end">
      <div>
        <input type="checkbox"
               id="site_contact_data_do_not_contact"
               true-value="true"
               false-value=""
               v-model="this.passed_in_order_form_data.site_contact_data.do_not_contact" />
        <label for="site_contact_data_do_not_contact">Do not contact</label>
      </div>
    </div>
  </div>

  <!-- Line 3 -->
  <div class="sl-flex-justify-content-space-btwn sl-mb-14px">
    <div style="width: 50%" class="sl-mr-10px">
      <div class="sl-para-small">Relationship to Property</div>
      <div>
        <input type="text" maxlength="50" class="sl-input-type-1" v-model="this.passed_in_order_form_data.site_contact_data.relationship_to_property" />
      </div>
    </div>

    <div style="width: 50%">
      <div class="sl-para-small">Site Visit Request Date(s)</div>
      <div>
        <input type="text" maxlength="50" class="sl-input-type-1" v-model="this.passed_in_order_form_data.site_contact_data.site_visit_request_dates" />
      </div>
    </div>
  </div>

  <div class="sl-mb-10px sl-sub-header-charcoal-light-40">Property Information Contact</div>

  <!-- Line 1 -->
  <div class="sl-flex-justify-content-space-btwn sl-mb-14px">
    <div style="width: 50%" class="sl-mr-10px">
      <div class="sl-para-small">First name</div>
      <div>
        <input type="text" maxlength="50" class="sl-input-type-1" v-model="this.passed_in_order_form_data.property_information_contact_data.first_name" />
      </div>
    </div>

    <div style="width: 50%">
      <div class="sl-para-small">Last Name</div>
      <div>
        <input type="text" maxlength="50" class="sl-input-type-1" v-model="this.passed_in_order_form_data.property_information_contact_data.last_name" />
      </div>
    </div>
  </div>

  <!-- Line 2 -->
  <div class="sl-flex-justify-content-space-btwn sl-mb-14px">
    <div style="width: 45%" class="sl-mr-10px">
      <div class="sl-para-small">Primary Phone</div>
      <div>
        <input type="text" maxlength="50" class="sl-input-type-1" v-model="this.passed_in_order_form_data.property_information_contact_data.phone" />
      </div>
    </div>

    <div style="width: 45%" class="sl-mr-10px">
      <div class="sl-para-small">Email</div>
      <div>
        <input type="text" maxlength="50" class="sl-input-type-1" v-model="this.passed_in_order_form_data.property_information_contact_data.email" />
      </div>
    </div>

    <div style="width: 120px;" class="sl-flex-align-items-flex-end">
      <div>
        <input type="checkbox"
               id="property_information_contact_data_do_not_contact"
               true-value="true"
               false-value=""
               v-model="this.passed_in_order_form_data.property_information_contact_data.do_not_contact" />
        <label for="property_information_contact_data_do_not_contact">Do not contact</label>
      </div>
    </div>
  </div>

  <!-- Line 3 -->
  <div class="sl-flex-justify-content-space-btwn sl-mb-14px">
    <div style="width: 50%" class="sl-mr-10px">
      <div class="sl-para-small">Relationship to Property</div>
      <div>
        <input type="text"
               maxlength="50"
               class="sl-input-type-1"
               v-model="this.passed_in_order_form_data.property_information_contact_data.relationship_to_property" />
      </div>
    </div>

    <div style="width: 50%">
      <div class="sl-para-small">Intended Use</div>
      <div>
        <input type="text" maxlength="50" class="sl-input-type-1" v-model="this.passed_in_order_form_data.property_information_contact_data.intended_use" />
      </div>
    </div>
  </div>

  <!-- Line 4 -->
  <div class="sl-mb-14px">
    <div>
      <div class="sl-para-small">Intended Users</div>
      <div>
        <input type="text" maxlength="50" class="sl-input-type-1" v-model="this.passed_in_order_form_data.property_information_contact_data.intended_users" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    passed_in_order_form_data: {
      type: Object,
    },
    dropdown_selections: {
      type: Object,
    },
  },
};
</script>
