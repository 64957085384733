<template>
  <!-- display text and link -->
  <div class="sl-mt-50px sl-mb-20px sl-paragraph-med-bold sl-partner-blue-text sl-plr-30px sl-ptb-40px" style="background-color: var(--charcoal-light-40);">
    Click
    <span class="sl-cursor-pointer sl-custom-underlined" @click="show_modal">here</span>
    to select additional services.
  </div>

  <!-- Modal -->
  <div v-if="modal_visibility" class="vue-modal-background">
    <div v-if="show_confirmation_prompt" class="sl-custom-overlay"></div>
    <div v-if="show_confirmation_prompt" class="sl-custom-confirm-box sl-text-align-center sl-flex-justify-align-center">
      <div class="sl-p-all-20px">
        <p class="sl-paragraph-med-bold sl-partner-blue-text">Are you sure?</p>

        <p class="sl-paragraph-med">Any updates made will not be saved.</p>

        <p class="sl-mt-30px">
          <button class="sl-simple-outline-btn sl-mr-30px" @click="cancel_close_modal">cancel</button>
          <button class="sl-simple-outline-btn" @click="close_modal">Continue</button>
        </p>
      </div>
    </div>

    <div class="vue-modal-content">
      <div class="sl-flex-justify-content-space-btwn sl-border-bottom-2px-charcoal sl-pb-5px sl-mb-10px">
        <div class="sl-paragraph-med-bold">Partner’s Complete List of Services</div>
        <xIcon class="sl-cursor-pointer" @click="close_with_no_save" />
      </div>

      <div class="sl-flex-justify-content-space-evenly">
        <!-- Col 1 -->
        <div class="sl-border-right--charcoal-medium-60 sl-pr-10px sl-custom-col-style">
          <div class="sl-flex-justify-content-space-btwn sl-mb-10px sl-border-bottom-2px-charcoal sl-pb-5px">
            <div class="sl-paragraph-med-bold">Current Selections</div>
          </div>

          <div v-if="this.order_form_data_to_update.requested_services_data.length === 0">
            <div class="sl-paragraph-med sl-ptb-10px">No services selected</div>
          </div>

          <div v-else>
            <div v-for="service in this.order_form_data_to_update.requested_services_data" :key="service.id" class="sl-flex-only sl-ptb-10px">
              <span class="sl-mr-10px">
                <xIcon class="sl-cursor-pointer sl-x-btn" @click="remove_service(service)" />
              </span>
              <span class="sl-paragraph-med">{{ service.service_name }}</span>
            </div>
          </div>

          <button @click="save_services" class="sl-generic-blue-background-btn sl-custom-btn-padding-2 sl-mt-10px">Continue</button>

          <div class="sl-font-size-12px sl-ptb-10px">
            Partner provides over 300 services. Contact your RM if the service you need is not shown.
          </div>
        </div>

        <!-- Col 2 -->
        <!-- // Note: -->
        <!-- // !groups[group.department] is Un-collapsed (shown) -->
        <!-- // groups[group.department] is Collapsed (hidden) -->
        <div class="sl-border-right--charcoal-medium-60 sl-plr-10px sl-custom-col-style">
          <div class="sl-mb-14px" v-for="group in all_services_col_1" :key="group.department" >
            <div class="sl-flex-justify-content-space-btwn sl-mb-10px sl-custom-header-blue-10" @click="toggle_group(group.department)">
              <div class="sl-paragraph-med-bold">{{ group.department }}</div>
              <div v-if="!groups[group.department]">—</div>
              <div v-if="groups[group.department]">+</div>
            </div>

            <div v-for="service in group.services" :key="service.id" v-if="!groups[group.department]">
              <label class='sl-custom-checkbox sl-pt-2px'>
                <input type="checkbox"
                       :id="'pop_up_checkbox_' + service.id"
                       :value="service"
                       v-model="this.order_form_data_to_update.requested_services_data" />
                <label :for="'pop_up_checkbox_' + service.id" class="sl-font-size-12px">
                  <span>{{ service.service_name }}</span>
                </label>
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>

        <!-- Col 3 -->
        <div class="sl-pl-10px sl-custom-col-style">
          <div class="sl-mb-14px" v-for="group in all_services_col_2" :key="group.department" >
            <div class="sl-flex-justify-content-space-btwn sl-mb-10px sl-custom-header-blue-10" @click="toggle_group(group.department)">
              <div class="sl-paragraph-med-bold">{{ group.department }}</div>
              <div v-if="!groups[group.department]">—</div>
              <div v-if="groups[group.department]">+</div>
            </div>

            <div v-for="service in group.services" :key="service.id" v-if="!groups[group.department]">
              <label class='sl-custom-checkbox sl-pt-2px'>
                <input type="checkbox"
                       :id="'pop_up_checkbox_' + service.id"
                       :value="service"
                       v-model="this.order_form_data_to_update.requested_services_data" />
                <label :for="'pop_up_checkbox_' + service.id" class="sl-font-size-12px">
                  <span>{{ service.service_name }}</span>
                </label>
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep as _cloneDeep, isEqual as _isEqual } from 'lodash';
import axios from 'axios';
import xIcon from '../../../../assets/images/icons/Icon-Clear_Charcoal-Dark-100_24.svg';

export default {
  props: {
    passed_in_order_form_data: {
      type: Object,
    },
    additional_services: {
      type: Array,
    },
  },

  components: {
    xIcon,
  },

  data() {
    return {
      show_confirmation_prompt: false,
      modal_visibility: false,
      all_services_col_1: [],
      all_services_col_2: [],
      groups: {}, // used to toggle group visibility
      order_form_data_to_update: {},
    };
  },

  async mounted() {
    await axios.get('/project_orders/return_service_list', {
      params: {
        defaults_only: false,
      },
    })
      .then((response) => {
        const services_mid_point = Math.ceil(response.data.service_list.length / 2);
        this.all_services_col_1 = response.data.service_list.slice(0, services_mid_point);
        this.all_services_col_2 = response.data.service_list.slice(services_mid_point, response.data.service_list.length);
      })
      .catch((error) => {
        alert(error);
      });
  },

  methods: {
    show_modal() {
      this.modal_visibility = true;

      // Make a copy of the object passed_in_order_form_data to order_form_data_to_update
      // do not directly assign the objects to each other because we want to keep the original object if the user cancels the changes
      this.order_form_data_to_update = _cloneDeep(this.passed_in_order_form_data);
    },

    close_with_no_save() {
      // Check if the order_form_data_to_update is different from the original passed_in_order_form_data
      if (_isEqual(this.passed_in_order_form_data, this.order_form_data_to_update)) {
        this.modal_visibility = false;
      } else {
        // Show "are you sure" section
        this.show_confirmation_prompt = true;
      }
    },

    // Closes the modal
    close_modal() {
      this.show_confirmation_prompt = false;
      this.modal_visibility = false;
    },

    // reset the show_confirmation_prompt to false
    cancel_close_modal() {
      this.show_confirmation_prompt = false;
    },

    // Toggles the visibility of the group
    // Note:
    // !groups[group.department] is Un-collapsed (shown)
    // groups[group.department] is Collapsed (hidden)
    toggle_group(group) {
      this.groups[group] = !this.groups[group];
    },

    // Saves the services selected in the modal and closes the modal
    save_services() {
      this.$emit('update_order_form_data_from_service_list_modal', _cloneDeep(this.order_form_data_to_update));

      this.modal_visibility = false;
    },

    // Removes a service from the selected services list
    remove_service(service) {
      this.order_form_data_to_update.requested_services_data = this.order_form_data_to_update.requested_services_data.filter((item) => item.id !== service.id);
    },
  },
};
</script>

<style scoped>
  .sl-custom-header-blue-10 {
    font-family: Roboto;
    font-weight: 700;
    font-size: 14px;
    background: var(--blue-10);
    padding: 5px 10px 5px 10px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .sl-custom-col-style {
    width: 33%;
  }

  .sl-border-right--charcoal-medium-60 {
    border-right: 2px solid var(--charcoal-medium-60);
  }

  .sl-custom-underlined {
    font-weight: initial;
    text-decoration: underline;
  }

  .vue-modal-background {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.1);
  }

  .vue-modal-content {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    width: 100%;
    max-width: 1200px;
    max-height: 90%;
    overflow: auto;
  }

  .sl-custom-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .sl-custom-confirm-box {
    position: absolute;
    z-index: 4;
    background-color: #fefefe;
    box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
    max-width: 600px;
  }
</style>
