<template>
  <SpinningModal v-if="showLoadingModal"
    :modalLoadingText="'Saving, Please Wait...'"
  />
  <div class="sl-flex-justify-content-space-btwn sl-flex-align-items-center  sl-border-b-1px-black
              sl-pb-5px sl-mb-10px sl-order-form-service-header">
    <span class="sl-bold-text sl-font-size-14px">Service Details Management</span>
    <span class="sl-bold-text" style="color: #F04C25">{{ alertMessage }}</span>
    <a class='sl-simple-outline-btn' href='/order_form_services'>Cancel</a>
  </div>
  <div style='background-color: #E5EEF5' class="sl-h-28px sl-flex-align-items-center sl-border-b-1px-black">
    <span class="sl-bold-text sl-ml-10px">Service Name and Details</span>
  </div>
  <div @input="serviceChanged" class="sl-flex-only sl-w-100per sl-mb-20px">
    <div class="sl-mr-50px">
      <div class="sl-flex-only">
        <div class="sl-mr-10px">
          <div class="sl-text-charcoal"><br>Category *</div>
          <select v-model='orderFormService.category_type_id' class="sl-w-250px sl-h-28px sl-border-1px-medium-charcoal" style="color: #333E47">
            <option :value=null selected disabled>Choose a Selection</option>
            <option v-for='categoryType in categoryTypes' :value='categoryType.id'>{{ categoryType.name }}</option>
          </select>
        </div>
        <div>
          <div class="sl-text-charcoal"><br>Department *</div>
          <select v-model='orderFormService.department_type_id' class="sl-w-250px sl-h-28px sl-border-1px-medium-charcoal" style="color: #333E47">
            <option :value=null selected>Choose a Selection</option>
            <option v-for='departmentType in departmentTypes' :value='departmentType.id'>{{ departmentType.name }}</option>
          </select>
        </div>
      </div>
      <div class="sl-flex-only">
        <div class="sl-mr-10px">
          <div class="sl-text-charcoal"><br>Service *</div>
          <select v-model='orderFormService.workscope_id' class="sl-w-250px sl-h-28px sl-border-1px-medium-charcoal" style="color: #333E47">
            <option :value=null selected>Choose a Selection</option>
            <option v-for='workscope in workscopes' :value='workscope.id'>{{ workscope.name }}</option>
          </select>
        </div>
        <div>
          <div class="sl-text-charcoal"><br>Display Name (If different)</div>
          <input v-model="orderFormService.display_name"
                 class="sl-w-245px sl-h-28px sl-border-1px-medium-charcoal sl-pl-5px text-input"
                 type='text'/>
        </div>
      </div>
      <div class="sl-flex-only">
        <div v-if='clientSpecific' class="sl-mr-10px">
          <div class="sl-text-charcoal"><br>Pricing *</div>
          <select class="sl-w-250px sl-h-28px sl-border-1px-medium-charcoal" style="color: #333E47">
            <option :value=null selected>Choose a Selection</option>
          </select>
        </div>
        <div class="sl-flex-align-items-center sl-mt-20px">
          <label class='sl-custom-checkbox'>
            <input type='checkbox'/>
            <span class='checkmark'></span>
          </label>
          <span class="sl-mt-10px">Display on main order form page</span>
        </div>
      </div>
    </div>
    <div>
      <div class="sl-text-charcoal"><br>Service Description</div>
      <textarea v-model="orderFormService.description"
                class="sl-border-1px-medium-charcoal sl-w-600px sl-h-126px sl-plr-5px"
                style="resize: none;">
      </textarea>
    </div>
  </div>
  <div style='background-color: #E5EEF5' class="sl-h-28px sl-flex-align-items-center sl-border-b-1px-black sl-mb-20px">
    <span class="sl-bold-text sl-ml-10px">Pricing</span>
  </div>
  <ClientFactors v-if="clientSpecific"
    :conversionFee='conversionFee'
    @updateConversionFee='updateConversionFee'
    @serviceChanged="serviceChanged"
  />
  <PricingFactors v-else
    :pricingFactors='pricingFactors'
    :orderFormServiceId='orderFormServiceId'
    :timePeriodFee="timePeriodFee"
    @serviceChanged="serviceChanged"
    :userIsDbAdmin="userIsDbAdmin"
  />
  <div class="sl-flex-justify-content-end sl-mb-10px" style="width: 1214px">
    <button class="sl-simple-outline-btn" @click="update">Save</button>
  </div>
  <div class="sl-border-t-2px-medium-charcoal sl-flex-justify-content-center sl-pt-10px">
    <div class="sl-w-25per">Created By [{{ createdBy }}]</div>
    <div class="sl-w-25per">Created On [{{createdAt }}]</div>
    <div class="sl-w-25per">Modified By [{{ updatedBy }}]</div>
    <div class="sl-w-25per">Modified On [{{ updatedAt }}]</div>
  </div>
</template>
<script>
import axios from 'axios';
import PricingFactors from './PricingFactors.vue';
import ClientFactors from './ClientFactors.vue';
import SpinningModal from '../../components/SpinningModal.vue';

export default {
  components: {
    PricingFactors,
    ClientFactors,
    SpinningModal,
  },

  data() {
    return {
      orderFormServiceId: window.location.href.split('/')[4],
      orderFormService: {},
      categoryTypes: [],
      departmentTypes: [],
      workscopes: [],
      pricingFactors: [],
      conversionFee: null,
      timePeriodFee: null,
      createdBy: null,
      createdAt: null,
      updatedBy: null,
      updatedAt: null,
      alertMessage: null,
      clientSpecific: false,
      showLoadingModal: false,
      userIsDbAdmin: false,
    };
  },

  mounted() {
    this.fetchOrderFormService();
  },

  methods: {
    async fetchOrderFormService() {
      await axios.get('/order_form_services/return_order_form_service', {
        params: {
          id: this.orderFormServiceId,
        },
      })
        .then((response) => {
          this.orderFormService = response.data.order_form_service;
          this.categoryTypes = response.data.category_types;
          this.departmentTypes = response.data.department_types;
          this.workscopes = response.data.workscopes;
          this.pricingFactors = response.data.pricing_factors;
          this.conversionFee = response.data.conversion_fee;
          this.timePeriodFee = response.data.time_period_fee;
          this.createdBy = response.data.created_by;
          this.createdAt = response.data.created_at;
          this.updatedBy = response.data.updated_by;
          this.updatedAt = response.data.updated_at;
          this.clientSpecific = response.data.client_specific;
          this.userIsDbAdmin = response.data.is_db_admin;
        });
    },

    async update() {
      this.showLoadingModal = true;
      await axios.post('/order_form_services/update_order_form_service', {
        id: this.orderFormServiceId,
        order_form_service: this.orderFormService,
        pricing_factors: this.pricingFactors,
        conversion_fee: this.conversionFee,
        time_period_fee: this.timePeriodFee,
      })
        .then((response) => {
          this.updatedBy = response.data.updated_by;
          this.updatedAt = response.data.updated_at;
          this.alertMessage = 'Updated Successful';
        })
        .catch(() => {
          alert('Something went wrong, update failed');
        })
        .finally(() => {
          setTimeout(() => {
            this.showLoadingModal = false;
          }, 300);
        });
    },

    updateConversionFee(conversionFee) {
      this.conversionFee = conversionFee;
    },

    updateTimePeriodFee(timePeriodFee) {
      this.timePeriodFee = timePeriodFee;
    },

    serviceChanged() {
      this.alertMessage = null;
    },
  },
};
</script>
<style>
  .text-input:focus {
    outline: none;
  }

  .pricing-factor-field-1 {
    width: 230px;
    height: 28px;
    display: flex;
    align-items: center;
    padding-left: 5px;
    border: 1px solid var(--medium-charcoal);
    background-color: #F6F6F6;
  }

  .pricing-factor-field-2 {
    width: 550px;
    height: 28px;
    display: flex;
    align-items: center;
    padding-left: 5px;
    border: 1px solid var(--medium-charcoal);
    background-color: #F6F6F6;
  }

  .pricing-factor-field-3 {
    width: 200px;
    height: 28px;
    display: flex;
    align-items: center;
    padding-left: 5px;
    border: 1px solid var(--medium-charcoal);
  }

  .pricing-factor-field-3:focus {
    outline: none;
  }

  .sl-order-form-service-header {
    position: sticky;
    top: -1px;
    background-color: white;
    z-index: 2;
  }

  .sl-pricing-factor-hover-text {
    position: absolute;
    margin-left: 20px;
    border: 1px solid #E9E9E9;
    padding: 10px;
    color: white;
    background-color: #333E47;
    border-radius: 10px;
    z-index: 2;
    width: 150px;
    text-wrap: wrap;
    text-align: left;
  }
</style>
