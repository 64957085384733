<template>
  <div id='pl_files_not_added_box' class='sl-flex-justify-content-center'>
    <div>
      <div id='pl_files_not_added_title' class='sl-text-align-center'></div>
      <div id='pl_files_not_added_text' class='sl-text-align-center'></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DropzoneFilesNotAdded',
};
</script>
