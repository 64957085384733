<template>
  <SpinningModal v-if="showLoadingModal"/>
  <form>
    <div class="sl-mt-10px sl-flex-justify-content-end">
       <select v-model="selectedScenario"
               @change="setScenario()"
               class="sl-w-175px sl-border-2px-medium-charcoal sl-pt-2px sl-pb-3px">
        <option :value=0 selected>Manual Scenario</option>
        <option :value=1>Scenario 1</option>
        <option :value=2>Scenario 2</option>
        <option :value=3>Scenario 3</option>
       </select>
    </div>
    <div class="sl-flex-only sl-mt-10px">
      <!-- Fixed left column -->
      <table class="sl-esg-decarbonization-table">
        <thead>
          <tr class="sl-table__row sl-table__row--esg sl-h-60px">
            <td class="sl-w-40px">
              <label class="sl-custom-checkbox">
                <input @click="setAllMeasuresActive()" v-model="isOneMeasureActive" type="checkbox">
                <span class="checkmark sl-ml-5px"></span>
              </label>
            </td>
            <td class="sl-w-355px sl-border-r-1px-medium-charcoal">
              <a @click="sort('index_number')" class="links-center">Measure Description</a>
            </td>
          </tr>
        </thead>
        <tbody class="sl-border-b-1px">
          <!-- Loop through different measure types: Energy Efficiency, Electrification, Renewables -->
          <div v-for="(measureType, name) in measures">
            <!-- Subheader Row -->
            <tr v-if="measureType.length" class="sl-table__row sl-h-28px sl-esg-table-sub-header-row">
              <td class="sl-w-40px"></td>
              <td class="sl-w-355px">{{ `${name} Measures` }}</td>
            </tr>
            <!-- Description -->
            <tr v-for="measure in measureType" class="sl-table__row sl-table__row--esg sl-h-45px">
              <td class="sl-w-40px">
                <label class="sl-custom-checkbox">
                  <input @click="setMeasureActive(measure)" v-model="measure.bool_active" type="checkbox" column="bool_active">
                  <span class="checkmark sl-ml-5px"></span>
                </label>
              </td>
              <td class="sl-border-r-1px-medium-charcoal sl-overflow-y-scroll sl-text-wrap sl-w-355px">
                <div class="sl-pt-10px sl-pl-10px sl-w-330px sl-text-align-left">
                  {{ `EE - ${measure.index} ${measure.description}` }}
                </div>
              </td>
            </tr>
            <!-- Subtotals -->
            <tr v-if="showSubTotals && measures[name].length"
                class="sl-table__row sl-table__row--esg sl-h-45px sl-border-t-2px-primary-blue sl-white-background-override">
              <td class="sl-w-40px"></td>
              <td class="sl-w-355px sl-border-r-1px-medium-charcoal">
                <strong>{{ `Totals of  ${name} Measures` }}</strong>
              </td>
            </tr>
          </div>
          <!-- Totals -->
          <tr v-if="totals['Active']"
              class="sl-table__row sl-table__row--esg sl-h-45px sl-border-t-2px-primary-blue sl-white-background-override sl-border-b-1px">
            <td class="sl-w-40px"></td>
            <td class="sl-w-355px sl-border-r-1px-medium-charcoal">
              <strong>Totals of All Selected Measures</strong>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="sl-esg-overflow-container">
        <table class="sl-esg-decarbonization-table" id ="sl-ee-measure-table">
          <thead>
            <tr class="sl-table__row sl-table__row--esg sl-h-60px">
              <Header :column="'year_implemented'" :sortValues="sortValues()">
                Year<br>Implemented
              </Header>
              <Header :column="'cost'" :sortValues="sortValues()">
                Cost
              </Header>
              <Header :column="'cost_savings'" :sortValues="sortValues()">
                Cost Savings
              </Header>
              <Header :column="'kwh_savings'" :sortValues="sortValues()">
                Electric Savings<br>(kWh)
              </Header>
              <Header :column="'therm_savings'" :sortValues="sortValues()">
                {{ secondaryFuelType.display_name }} Savings<br>({{ secondaryFuelType.unit_of_measure }})
              </Header>
              <Header :column="'rul_year'" :sortValues="sortValues()">
                RUL Year
              </Header>
              <Header :column="'payback'" :sortValues="sortValues()">
                Payback<br>(Years)
              </Header>
              <Header :column="'co2e_savings_current_year'" :sortValues="sortValues()">
                <span v-if="sortColumn === 'co2e_savings_current_year'">CO<sub>2</sub>e Savings<br>Current Year</span>
                <span v-else>CO<sub>2</sub>e Savings<br>Current Year<br>(Metric Tons)</span>
              </Header>
              <Header :column="'co2e_savings_year2050'" :sortValues="sortValues()">
                <span v-if="sortColumn === 'co2e_savings_year2050'">CO<sub>2</sub>e Savings<br>Year 2050</span>
                <span v-else>CO<sub>2</sub>e Savings<br>Year 2050<br>(Metric Tons)</span>
              </Header>
              <Header :column="'cr_percentage_current_year'" :sortValues="sortValues()">
                <span v-if="sortColumn === 'cr_percentage_current_year'">CR<br>Current Year</span>
                <span v-else>Carbon<br>Reduction<br>Current Year</span>
              </Header>
              <Header :column="'cr_percentage2050'" :sortValues="sortValues()">
                <span v-if="sortColumn === 'cr_percentage2050'">CR 2050</span>
                <span v-else>Carbon<br>Reduction<br>in 2050</span>
              </Header>
              <Header :column="'degradation'" :sortValues="sortValues()">
                Degradation
              </Header>
              <Header :column="'partial_measure'" :sortValues="sortValues()">
                Partial<br>Measure
              </Header>
              <Header :column="'spread_years'" :sortValues="sortValues()">
                Spread Years
              </Header>
            </tr>
          </thead>
          <tbody class="main-table-body">
            <!-- Loop through different measure types: Energy Efficiency, Electrification, Renewables -->
            <div v-for="(measureType, name) in measures">
              <!-- Subheader -->
              <tr v-if="measureType.length" class="sl-table__row sl-esg-table-sub-header-row sl-h-28px"></tr>
              <!-- Main Data -->
              <tr v-for="measure in measureType" class="sl-table__row sl-table__row--esg sl-h-45px">
                <td>
                  <input v-model="measure.year_implemented" @change="saveMeasure(measure, $event)"
                         type="number" column="year_implemented" class="sl-disable-number-input-up-down-arrows"/>
                </td>
                <td>{{ measure.cost }}</td>
                <td>{{ measure.cost_savings }}</td>
                <td>{{ measure.kwh_savings }}</td>
                <td>{{ measure.therm_savings }}</td>
                <td v-html="measure.rul_year"></td>
                <td>{{ measure.payback }}</td>
                <td>{{ measure.co2e_savings_current_year }}</td>
                <td>{{ measure.co2e_savings_year2050 }}</td>
                <td>{{ measure.cr_percentage_current_year }}</td>
                <td>{{ measure.cr_percentage2050 }}</td>
                <td>
                  <input v-model="measure.degradation" @change="saveMeasure(measure, $event)"
                         type="text" column="degradation"/>
                </td>
                <td>
                  <input v-model="measure.partial_measure" @change="saveMeasure(measure, $event)"
                         type="text" column="partial_measure"/>
                </td>
                <td>
                  <input v-model="measure.spread_years" @change="saveMeasure(measure, $event)"
                         type="number" step="1" column="spread_years" class="sl-disable-number-input-up-down-arrows"/>
                </td>
              </tr>
              <!-- Subtotal Row -->
              <tr v-if="showSubTotals && measures[name].length"
                  class="sl-table__row sl-table__row--esg sl-h-45px sl-border-t-2px-primary-blue sl-white-background-override">
                <td>&#8212</td>
                <td>{{ totals[name].cost }}</td>
                <td>{{ totals[name].cost_savings }}</td>
                <td>{{ totals[name].kwh_savings }}</td>
                <td>{{ totals[name].therm_savings }}</td>
                <td>&#8212</td>
                <td>{{ totals[name].payback }}</td>
                <td>{{ totals[name].co2e_savings_current_year }}</td>
                <td>{{ totals[name].co2e_savings_year2050 }}</td>
                <td>{{ totals[name].cr_percentage_current_year }}</td>
                <td>{{ totals[name].cr_percentage2050 }}</td>
                <td>{{ totals[name].degradation }}</td>
                <td>&#8212</td>
                <td>&#8212</td>
              </tr>
            </div>

            <!-- Totals of all selected measures -->
            <tr v-if="totals['Active']" class="sl-table__row sl-table__row--esg sl-h-45px sl-border-t-2px-primary-blue sl-white-background-override">
              <td>&#8212</td>
              <td>{{ totals['Active'].cost }}</td>
              <td>{{ totals['Active'].cost_savings }}</td>
              <td>{{ totals['Active'].kwh_savings }}</td>
              <td>{{ totals['Active'].therm_savings }}</td>
              <td>&#8212</td>
              <td>{{ totals['Active'].payback }}</td>
              <td>{{ totals['Active'].co2e_savings_current_year }}</td>
              <td>{{ totals['Active'].co2e_savings_year2050 }}</td>
              <td>{{ totals['Active'].cr_percentage_current_year }}</td>
              <td>{{ totals['Active'].cr_percentage2050 }}</td>
              <td>{{ totals['Active'].degradation }}</td>
              <td>&#8212</td>
              <td>&#8212</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </form>
  <br>
  <br>
</template>
<script>
import axios from 'axios';
import Header from '/javascript/pages/partner_energy/carbon_proforma/MeasuresTableHeaderCell.vue';
import SpinningModal from '/javascript/components/SpinningModal.vue';
import { toHandlers } from 'vue';

export default {
  components: {
    SpinningModal,
    Header,
  },

  data() {
    const urlParams = new URLSearchParams(window.location.search);
    const baselineMetricId = urlParams.get('baseline_metric_id');
    return {
      measures: {},
      totals: {},
      sortColumn: 'id',
      sortDirection: 'asc',
      baselineMetricId,
      baselineYear: null,
      maxDecarbYear: null,
      isOneMeasureActive: null,
      showLoadingModal: false,
      showSubTotals: null,
      secondaryFuelType: {},
      selectedScenario: null,
    };
  },

  async created() {
    this.fetchMeasures();
  },

  methods: {
    async fetchMeasures() {
      await axios.get('/energy_efficiency_measures/vue_index', {
        params: {
          baseline_metric_id: this.baselineMetricId,
          sort_column: this.sortColumn,
          sort_direction: this.sortDirection,
        },
      })
        .then((response) => {
          this.measures = response.data.measures;
          this.totals = response.data.totals;
          this.baselineYear = response.data.baselineYear;
          this.maxDecarbYear = response.data.maxDecarbYear;
          this.showSubTotals = response.data.showSubTotals;
          this.secondaryFuelType = response.data.secondaryFuelType;
          this.selectedScenario = response.data.selectedScenario;
          this.checkForActiveMeasure();
        })
        .catch((error) => {
          console.log(error);
          this.showErrorNotice();
        });
    },

    // Called when a table column header is clicked
    sort(column) {
      // When the user hits the same column twice, toggle between ascending and descending
      if (this.sortColumn === column && this.sortDirection === 'asc') {
        this.sortDirection = 'desc';
      } else {
        this.sortDirection = 'asc';
      }
      this.sortColumn = column;
      this.fetchMeasures();
    },

    // Return object is passed into header components
    sortValues() {
      return {
        sort: this.sort,
        column: this.sortColumn,
        direction: this.sortDirection,
      };
    },

    // Save a single measure
    async saveMeasure(measure, event) {
      const value = this.formatValue(event);
      const column = event.target.getAttribute('column');
      if (this.validateInput(column, value)) {
        await axios.put('/energy_efficiency_measures/vue_update', null, {
          params: {
            id: measure.id,
            value,
            column,
            baseline_metric_id: this.baselineMetricId,
          },
        })
          .then((response) => {
            this.totals = response.data.totals;
            // Update the measure with the updated values from the server
            Object.assign(measure, response.data.measure);
            this.checkForActiveMeasure();
            this.flashSaveNotice();
            // Reset border to normal color if there was previously an invalid input
            event.target.style.border = '2px solid #9F9EA4';
          })
          .catch((error) => {
            console.log(error);
            this.showErrorNotice();
          });
      } else {
        // Red error border on invalid input
        event.target.style.border = '2px solid red';
      }
    },

    // Save all measures active status at once
    async saveAllMeasures() {
      await axios.put('/energy_efficiency_measures/vue_update_all', null, {
        params: {
          baseline_metric_id: this.baselineMetricId,
          active_status: !this.isOneMeasureActive,
        },
      })
        .then(() => {
          this.flashSaveNotice();
          this.fetchMeasures();
        })
        .catch((error) => {
          console.log(error);
          this.showErrorNotice();
        });
    },

    // Degradation and Partial Measure are text inputs to let the percentage sign show in the input,
    // format these values as regular numbers before saving
    formatValue(event) {
      let { value } = event.target;
      const column = event.target.getAttribute('column');
      if (['degradation', 'partial_measure'].includes(column)) {
        value = value.replace(/[^\d.-]/g, ''); // Replace non-numerical characters
        if (value === '') { value = 0; } // If no numbers were entered, replace value with 0
        event.target.value = `${value}%`; // Add the percent sign back to the value displayed in the input
      }
      return value;
    },

    // Checks that values entered into inputs are in between specific parameters
    validateInput(column, value) {
      let message = '';
      if (column === 'year_implemented' && value < this.baselineYear) {
        message = `The year implemented must be greater than or equal to the baseline year, ${this.baselineYear}.`;
      } else if (column === 'year_implemented' && value > this.maxDecarbYear) {
        message = `The year implemented must be less than or equal to ${this.maxDecarbYear}.`;
      } else if (column === 'partial_measure' && value < 0) {
        message = 'The partial measure percentage must be greater than 0.';
      } else if (column === 'degradation' && value < 0) {
        message = "The measure's degradation must be greater than or equal to 0.";
      } else if (column === 'spread_years' && value <= 0) {
        message = "The measure's spread years must be greater than 0.";
      } else {
        $jq('#carbon-proforma-error-message').text(message);
        return true;
      }
      $jq('#carbon-proforma-error-message').text(message);
    },

    // Returns true if at least one measure is set to active, else, returns false
    checkForActiveMeasure() {
      let isOneMeasureActive = false;
      for (const type in this.measures) {
        this.measures[type].forEach((measure) => {
          if (measure.bool_active) {
            isOneMeasureActive = true;
          }
        });
      }
      this.isOneMeasureActive = isOneMeasureActive;
    },

    // Update a single measure's active status
    async setMeasureActive(measure) {
      // Set the measures scenario column to the opposite of what it currently is
      const scenario_column = `scenario_${this.selectedScenario}`;
      measure[scenario_column] = !measure[scenario_column];
      await axios.put('/energy_efficiency_measures/update_active_status', {
        baseline_metric_id: this.baselineMetricId,
        measure_id: measure.id,
        scenario: this.selectedScenario,
      })
        .then((response) => {
          this.totals = response.data.totals;
          this.isOneMeasureActive = response.data.isOneMeasureActive;
          this.flashSaveNotice();
        })
        .catch(() => {
          this.showErrorNotice();
        });
    },

    // Update active status for all measures
    async setAllMeasuresActive() {
      const scenario_column = `scenario_${this.selectedScenario}`;
      for (const type in this.measures) {
        this.measures[type].forEach((measure) => {
          measure.bool_active = !this.isOneMeasureActive;
          measure[scenario_column] = !this.isOneMeasureActive;
        });
      }
      
      await axios.put('/energy_efficiency_measures/update_all_active_status', {
        baseline_metric_id: this.baselineMetricId,
        scenario: this.selectedScenario,
        active_status: !this.isOneMeasureActive,
      })
        .then((response) => {
          this.totals = response.data.totals;
          this.isOneMeasureActive = response.data.isOneMeasureActive;
          this.flashSaveNotice();
        })
        .catch(() => {
          this.showErrorNotice();
        });
    },

    flashSaveNotice() {
      $jq('#carbon-proforma-alert-message').text('Table Saved');
      setTimeout(() => { $jq('#carbon-proforma-alert-message').text(''); }, 2000);
    },

    showErrorNotice() {
      $jq('#carbon-proforma-error-message').text('An error has occurred and the data entered has not been saved');
    },

    // Set measures active that are included in the selected scenario
    async setScenario() {
      await axios.put('/energy_efficiency_measures/update_scenario', null, {
        params: {
          scenario: this.selectedScenario,
          baseline_metric_id: this.baselineMetricId,
        },
      })
        .then((response) => {
          for (const type in this.measures) {
            this.measures[type].forEach((measure) => {
              const scenario_column = `scenario_${this.selectedScenario}`;
              // If the measure is included in the scenario, set it to active
              measure.bool_active = measure[scenario_column];
            });
          }
          this.isOneMeasureActive = response.data.isOneMeasureActive;
          this.totals = response.data.totals;
          this.flashSaveNotice();
        })
        .catch(() => {
          this.showErrorNotice();
        });
    },
  },
};
</script>
<style scoped>
  .main-table-body td {
    width: 100px;
  }
</style>
