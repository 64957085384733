<template>
  <SpinningModal v-if='showLoadingModal'/>
  <div v-if='costFactorCity'>
    <div class='sl-mb-20px sl-flex-justify-content-space-btwn'>
      <span class='sl-font-size-16px sl-bold-text' style='color: #005199'>{{ `${costFactorCity.city}, ${costFactorCity.state}` }}</span>
      <span class='sl-font-size-14px' style='color: #005199'>Version: {{ version.name }}</span>
    </div>
    <br>
    <div class='sl-flex-justify-content-center sl-h-15px sl-mb-5px' style='color: red'>{{ alertMessage }}</div>
    <form @submit.prevent='update'>
      <div class='sl-flex-justify-content-center'>
        <table class='sl-border-t-2px-primary-blue sl-border-b-2px' style='border-collapse: collapse;'>
          <tbody>
            <tr v-for='category in categories' class='sl-table__row--striped sl-h-42px'>
              <td class='sl-pl-10px sl-w-400px sl-bold-text'>{{ category }}</td>
              <td class='sl-pr-10px'>
                <input v-model='costFactorCity[category]' type='number' required :name='category' step='0.01'
                       class='sl-disable-number-input-up-down-arrows sl-text-align-right
                              sl-border-1px-medium-charcoal sl-pr-10px sl-ptb-5px'/>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <br>
      <br>
      <div class='sl-flex-justify-content-center'>
        <button type='submit' class='sl-simple-outline-btn sl-w-130px sl-ml-10px sl-h-32px'>Save</button>
      </div>
    </form>
  </div>
</template>
<script>
import axios from 'axios';
import SpinningModal from '../../components/SpinningModal.vue';

export default {
  components: {
    SpinningModal,
  },

  data() {
    const url = window.location.pathname;
    return {
      // Get the id of the record from the url
      id: url.substring(url.lastIndexOf('/') + 1),
      costFactorCity: null,
      version: null,
      categories: null,
      alertMessage: '',
      showLoadingModal: false,
    };
  },

  async created() {
    this.fetchcostFactorCity();
  },

  methods: {
    async fetchcostFactorCity() {
      this.showLoadingModal = true;
      await axios.get(`/pca_cost_factor_cities/${this.id}/edit`)
        .then((response) => {
          this.costFactorCity = response.data.costFactorCity;
          this.version = response.data.version;
          this.categories = response.data.categories;
          setTimeout(() => { this.showLoadingModal = false; }, 500);
        })
        .catch(() => {
          alert('Something went wrong. Contact admin@sitelynx.net for support.');
        });
    },

    async update() {
      await axios.put(`/pca_cost_factor_cities/${this.id}`, null, {
        params: {
          pca_cost_factor_city: this.costFactorCity,
        },
      })
        .then(() => {
          this.alertMessage = 'Update Successful';
          setTimeout(() => { this.alertMessage = ''; }, 3000);
        })
        .catch(() => {
          this.alertMessage = 'Something went wrong saving the cost factors. Contact admin@sitelynx.net for support.';
        });
    },
  },
};
</script>
