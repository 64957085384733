<template>
  <div class='sl-flex-justify-content-center sl-pb-10px'>
    <div class='sl-w-500px'>
      <div class='sl-flex-justify-content-and-align-items-center sl-mb-20px'>
        <div class='sl-box-shadow-1 sl-p-all-10px'>
          <div class='sl-mb-10px'>
            <strong>Upload Data:</strong>
          </div>
          <form @submit.prevent='submit' class='sl-flex-column'>
            <input type='file' name='file' accept='.csv, .xls, .xlsx'
                   class='sl-mb-10px file-input'
                   id='upload_datafile'
                   ref='upload_datafile'
                   @change='setUploadFile()'/>
            <button class='sl-simple-outline-btn sl-w-100px'
                    :class="{'sl-simple-outline-btn-disabled':!file}">
                    Upload
            </button>
          </form>
        </div>
      </div>
      <ol>
        <li class='report-table-instructions'>
          <a @click.prevent="$emit('deleteAll')" href='/' class='sl-bold-text'>Delete all</a>
          the rows in the table before uploading.
        </li>
        <li class='report-table-instructions'>
          After deleting all the rows, download the
          <a :href="`/pca_report_tables/download_import_template?template=${tableName}`" class='sl-bold-text'>template</a>
          and fill out the data in either excel or csv format.
        </li>
        <li class='report-table-instructions'>
          Select the file with the data in the template format, and then press upload.
        </li>
        <br>
        <span v-if="tableName==='multi havac equipment'" class='report-table-instructions sl-bold-text'>
          Note: The energy efficiency ratio is not included in the PCA report.
        </span>
      </ol>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    this.project_id = urlParams.get('project_id');
  },

  props: ['tableName'],

  data() {
    return {
      project_id: null,
      file: null,
      allowedFileTypes: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv'],
    };
  },

  methods: {
    setUploadFile() {
      const file = this.$refs.upload_datafile.files[0];
      if (file && this.allowedFileTypes.includes(file.type)) {
        this.file = file;
      }
    },

    async submit() {
      const formData = new FormData();
      formData.append('datafile', this.file);
      formData.append('project_id', this.project_id);
      formData.append('the_group', this.tableName);
      await axios.post('/pca_report_tables/import_table', formData)
        .then(() => {
          this.file = null;
          this.$emit('importComplete');
        });
    },
  },
};
</script>
