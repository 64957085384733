<template>
  <div class='sl-flex-justify-content-center sl-pb-30px'>
    <div class='sl-w-500px'>
      <ol>
        <li class='report-table-instructions'>
          Place an asterisk next to the unit number in the <strong>Testing Location</strong> column if
          it was tested to meet 25% threshold, tested side-by-side and averaged.
        </li>
        <li class='report-table-instructions'>
          Place two asterisks next to the average radon concentration if based on average of test results,
          mitigation is recommended.
        </li>
      </ol>
    </div>
  </div>
</template>
