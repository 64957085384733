<template>
  <form class="dropzone dz-clickable" id="file-cpm-supplemental-doc" controller="projectdocs" action="/projectdocs" enctype="multipart/form-data" accept-charset="UTF-8" data-remote="true" method="post">
    <input name="utf8" type="hidden" value="✓" autocomplete="off">
    <input autocomplete="off" type="hidden" :value="project_id" name="projectdoc[project_id]" id="projectdoc_project_id">
    <input type="hidden" name="cpm_working_doc" id="cpm_working_doc" value="true" autocomplete="off">
    <div class="dz-message sl-working-doc-drag-and-drop-zone" data-dz-message="">
      <upload class="sl-svg-icon-size-3 figma-icon-medium-charcoal" />
      <div class="sl-drag-and-drop-text-container">
        <div class="sl-drag-and-drop-main-text">Upload</div>
        <div class="sl-drag-and-drop-subtext">(Drag and drop files here to upload)</div>
      </div>
    </div>
  </form>
</template>

<script>
import upload from "../../../../assets/images/icons/Icon-Add_Charcoal-Dark-100_24.svg"

export default {
  components: {
    upload,
  },

  data() {
    return {
      project_id: null,
    };
  },

  async created() {
    const urlParams = new URLSearchParams(window.location.search);
    const project_id = urlParams.get('id');
    this.project_id = project_id;
  },
};
</script>
