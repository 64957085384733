<template>
  <!-- Line 1 -->
  <div class="sl-flex-justify-content-space-btwn sl-mb-14px">
    <div style="width: 50%" class="sl-mr-10px">
      <div class="sl-para-small">First name</div>
      <div>
        <input type="text" maxlength="50" class="sl-input-type-1" v-model="this.passed_in_order_form_data.invoice_data.first_name" />
      </div>
    </div>

    <div style="width: 50%">
      <div class="sl-para-small">Last Name</div>
      <div>
        <input type="text" maxlength="50" class="sl-input-type-1" v-model="this.passed_in_order_form_data.invoice_data.last_name" />
      </div>
    </div>
  </div>

  <!-- Line 2 -->
  <div class="sl-flex-justify-content-space-btwn sl-mb-14px">
    <div style="width: 45%" class="sl-mr-10px">
      <div class="sl-para-small">Company name</div>
      <div>
        <input type="text" maxlength="50" class="sl-input-type-1" v-model="this.passed_in_order_form_data.invoice_data.company_name" />
      </div>
    </div>

    <div style="width: 45%" class="sl-mr-10px">
      <div class="sl-para-small">Email</div>
      <div>
        <input type="text" maxlength="50" class="sl-input-type-1" v-model="this.passed_in_order_form_data.invoice_data.email" />
      </div>
    </div>

    <div style="width: 120px;" class="sl-flex-align-items-flex-end">
      <div>
        <input type="checkbox"
               id="invoice_data_do_not_contact"
               true-value="true"
               false-value=""
               v-model="this.passed_in_order_form_data.invoice_data.do_not_contact" />
        <label for="invoice_data_do_not_contact">Do not contact</label>
      </div>
    </div>
  </div>

  <!-- Line 3 -->
  <div class="sl-flex-justify-content-space-btwn sl-mb-14px">
    <div style="width: 33.33%" class="sl-mr-10px">
      <div class="sl-para-small">Country</div>
      <div>
        <input type="text" maxlength="50" class="sl-input-type-1" v-model="this.passed_in_order_form_data.invoice_data.country" />
      </div>
    </div>

    <div style="width: 33.33%" class="sl-mr-10px">
      <div class="sl-para-small">Address 1</div>
      <div>
        <input type="text" maxlength="50" class="sl-input-type-1" v-model="this.passed_in_order_form_data.invoice_data.address" />
      </div>
    </div>

    <div style="width: 33.33%">
      <div class="sl-para-small">Address 2</div>
      <div>
        <input type="text" maxlength="50" class="sl-input-type-1" v-model="this.passed_in_order_form_data.invoice_data.address_2" />
      </div>
    </div>
  </div>

  <!-- Line 4 -->
  <div class="sl-flex-justify-content-space-btwn sl-mb-14px">
    <div style="width: 40%" class="sl-mr-10px">
      <div class="sl-para-small">City</div>
      <div>
        <input type="text" maxlength="50" class="sl-input-type-1" v-model="this.passed_in_order_form_data.invoice_data.city" />
      </div>
    </div>

    <div style="width: 40%" class="sl-mr-10px">
      <div class="sl-para-small">State</div>
      <div>
        <input type="text" maxlength="50" class="sl-input-type-1" v-model="this.passed_in_order_form_data.invoice_data.state" />
      </div>
    </div>

    <div style="width: 20%">
      <div class="sl-para-small">Zip</div>
      <div>
        <input type="text"
               maxlength="10"
               oninput="this.value = this.value.replace(/[^0-9-]/g, '')"
               class="sl-input-type-1"
               v-model="this.passed_in_order_form_data.invoice_data.zip" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    passed_in_order_form_data: {
      type: Object,
    },
    dropdown_selections: {
      type: Object,
    },
  },
};
</script>
