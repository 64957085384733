<template>
  <div class="sl-flex-only" style="margin: 0 auto; min-height: 600px;">
    <!-- Col 1 -->
    <div class="sl-p-all-10pxr" style="width: 20%;">
      <h1>Portfolio Bluelynx Instructions</h1>

      <div>
        <p v-for="report_type in report_types" :key="report_type">
          <button @click="view_instructions(report_type)" class="sl-simple-outline-btn">{{ report_type }}</button>
        </p>
      </div>
    </div>

    <!-- Col 2 -->
    <div class="sl-p-all-10px" style="width: 80%; border-left: 5px solid #005199;">
      <div v-if="current_instructions !== null">
        <h1>Viewing BlueLynx Instructions For {{ current_instructions.report_type }}</h1>

        <div class="sl-mt-10px">
          <table class="sl-simple-table-2">
            <tr>
              <td>Report Type</td>
              <td>{{ current_instructions.report_type }}</td>
            </tr>
            <tr>
              <td>Active</td>
              <td>{{ current_instructions_active }}</td>
            </tr>
            <!-- created by, at, updated by, at -->
            <tr>
              <td>Created By</td>
              <td>{{ current_instructions.created_by }}</td>
            </tr>
            <tr>
              <td>Created At</td>
              <td>{{ current_instructions.created_at }}</td>
            </tr>
            <tr>
              <td>Updated By</td>
              <td>{{ current_instructions.updated_by }}</td>
            </tr>
            <tr>
              <td>Updated At</td>
              <td>{{ current_instructions.updated_at }}</td>
            </tr>
          </table>
        </div>

        <div class="sl-mt-10px">Title (Optional)</div>
        <textarea style="width: 50%; height: 40px;" v-model="current_instructions.title"></textarea>

        <div class="sl-mt-20px">Description (Optional)</div>
        <textarea style="width: 50%; height: 80px;" v-model="current_instructions.body"></textarea>

        <div class="sl-mt-20px">Currently Attached PDF</div>
        <div>
          <a :href="current_instructions.doc_url" target="_blank">{{ current_instructions.filename }}</a>
        </div>

        <div class="sl-mt-20px">Attach New PDF (This will overwrite the current PDF above)</div>
        <div>
          <input type="file" accept=".pdf" @change="addFile" />
        </div>

        <div class="sl-mt-20px">Activate (Once checked and updated, all users will begin seeing the instructions pop-up)</div>
        <input type="checkbox" v-model="current_instructions.active" />

        <div class="sl-mt-20px">
          <button @click="update_record" class="sl-simple-outline-btn">Update</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      report_types: [
        'PCA Debt',
        'ESA Phase 1',
      ],
      current_instructions: null,
      current_instructions_active: null,
      new_file: null,
    };
  },

  methods: {
    view_instructions(report_type) {
      // get the portfolio id from url params
      const urlParams = new URLSearchParams(window.location.search);
      const portfolio_id = urlParams.get('id');

      axios.get('/portfolio_bluelynx_instructions/return_portfolio_bluelynx_instructions', {
        params: {
          portfolio_id,
          report_type,
        },
      })
        .then((response) => {
          this.current_instructions = response.data.portfolio_bluelynx_instruction;
          this.current_instructions_active = response.data.portfolio_bluelynx_instruction.active;
        })
        .catch((error) => {
          alert(error);
        });
    },

    addFile(e) {
      const [file_1] = e.target.files;
      this.new_file = file_1;
    },

    update_record() {
      axios.post('/portfolio_bluelynx_instructions/ajax_update', {
        id: this.current_instructions.id,
        title: this.current_instructions.title,
        body: this.current_instructions.body,
        active: this.current_instructions.active,
        new_file: this.new_file,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((response) => {
          alert(response.data.result);
          this.current_instructions = response.data.portfolio_bluelynx_instruction;
          this.current_instructions_active = response.data.portfolio_bluelynx_instruction.active;
          this.new_file = null;
        })
        .catch((error) => {
          alert(error);
        });
    },
  },
};
</script>

<style>

</style>
