<template>
  <br>
  <div v-if='!dataReady' class='sl-spinning-wheel-20px sl-mt-10px sl-mlr-10px sl-h-106px'></div>
  <div v-if='dataReady && !primaryPathwaySelected && !secondaryPathwaySelected'
       class='sl-border-b-2px sl-font-size-14px sl-pt-10px sl-pb-20px sl-flex-justify-align-center sl-h-200px'>
    No Carbon Pathway Selected
  </div>
  <div v-if='dataReady'>
    <div class='sl-text-align-center sl-h-15px'>
      <span v-if='alertMessage' class='sl-partner-blue-text sl-bold-text'>{{ alertMessage }}</span>
      <span v-else style='color: red;'>{{ errorMessage }}</span>
    </div>
    <div v-if='primaryPathwaySelected'>
      <CarbonOffsetTable :pathwayName='primaryPathwayName'
                         :localOrdinance='primaryLocalOrdinance'
                         :totalOffset='primaryTotalOffset'
                         :carbonOffsets='primaryCarbonOffsets'
                         :offsetTotals='primaryOffsetTotals'
                         :years='years'
                         :category="'primary'"
                         :costUnits='primaryCostUnits'
                         :customPathway='primaryCustomPathway'
                         @updateCarbonOffset='updateCarbonOffset'/>
    </div>
    <br>
    <div v-if='secondaryPathwaySelected'>
      <CarbonOffsetTable :pathwayName='secondaryPathwayName'
                         :localOrdinance='secondaryLocalOrdinance'
                         :totalOffset='secondaryTotalOffset'
                         :carbonOffsets='secondaryCarbonOffsets'
                         :offsetTotals='secondaryOffsetTotals'
                         :years='years'
                         :category="'secondary'"
                         :costUnits='secondaryCostUnits'
                         :customPathway='secondaryCustomPathway'
                         @updateCarbonOffset='updateCarbonOffset'/>
      </div>
  </div>
</template>
<script>
import axios from 'axios';
import CarbonOffsetTable from './CarbonOffsetTable.vue';

export default {
  components: {
    CarbonOffsetTable,
  },

  data() {
    return {
      baselineMetricId: null,
      baselineMetric: null,
      years: [],
      primaryTotalOffset: null,
      primaryCarbonOffsets: [],
      primaryOffsetTotals: {},
      primaryPathwayName: '',
      primaryLocalOrdinance: false,
      primaryCostUnits: '$/tCO2e',
      primaryCustomPathway: false,
      secondaryTotalOffset: null,
      secondaryCarbonOffsets: [],
      secondaryOffsetTotals: {},
      secondaryPathwayName: '',
      secondaryLocalOrdinance: false,
      dataReady: false,
      alertMessage: '',
      errorMessage: '',
      primaryOffsetType: 'annual',
      secondaryOffsetType: 'annual',
      primaryPathwaySelected: false,
      secondaryPathwaySelected: false,
      secondaryCostUnits: '$/tCO2e',
      secondaryCustomPathway: false,
    };
  },

  mounted() {
    this.baselineMetricId = new URLSearchParams(window.location.search).get('baseline_metric_id');
    this.fetchCarbonOffsets();
  },

  methods: {
    async fetchCarbonOffsets() {
      await axios.get('/carbon_offset_values', {
        params: {
          baseline_metric_id: this.baselineMetricId,
        },
      })
        .then((response) => {
          this.baselineMetric = response.data.baseline_metric;
          this.primaryPathwaySelected = response.data.primary_pathway_selected;
          this.secondaryPathwaySelected = response.data.secondary_pathway_selected;
          this.primaryTotalOffset = response.data.primary_total_offset;
          this.primaryCarbonOffsets = response.data.primary_carbon_offsets;
          this.primaryOffsetTotals = response.data.primary_offset_totals;
          this.primaryPathwayName = response.data.primary_pathway_name;
          this.primaryLocalOrdinance = response.data.primary_local_ordinance;
          this.primaryCostUnits = response.data.primary_cost_units;
          this.primaryCustomPathway = response.data.primary_custom_pathway_present;
          this.secondaryTotalOffset = response.data.secondary_total_offset;
          this.secondaryCarbonOffsets = response.data.secondary_carbon_offsets;
          this.secondaryOffsetTotals = response.data.secondary_offset_totals;
          this.secondaryPathwayName = response.data.secondary_pathway_name;
          this.secondaryLocalOrdinance = response.data.secondary_local_ordinance;
          this.secondaryCostUnits = response.data.secondary_cost_units;
          this.secondaryCustomPathway = response.data.secondary_custom_pathway_present;
          this.years = response.data.years;
          this.dataReady = true;
        })
        .catch(() => {
          this.errorMessage = 'Error Fetching Data';
        });
    },

    async updateCarbonOffset(carbonOffset, category, index = null) {
      await axios.put(`/carbon_offset_values/${carbonOffset.id}`, {
        baseline_metric_id: this.baselineMetricId,
        price_per_metric_ton: this.formatPrice(carbonOffset),
      })
        .then((response) => {
          if (carbonOffset.offset_type === 'total' && category === 'primary') {
            this.primaryTotalOffset = response.data.carbon_offset;
          } else if (carbonOffset.offset_type === 'total' && category === 'secondary') {
            this.secondaryTotalOffset = response.data.carbon_offset;
          } else if (category === 'primary') {
            this.primaryCarbonOffsets[index] = response.data.carbon_offset;
            this.primaryOffsetTotals = response.data.offset_totals;
          } else {
            this.secondaryCarbonOffsets[index] = response.data.carbon_offset;
            this.secondaryOffsetTotals = response.data.offset_totals;
          }
          this.alertMessage = 'Table Saved';
          setTimeout(() => { this.alertMessage = ''; }, 1000);
        })
        .catch(() => {
          this.errorMessage = 'Error Saving Table';
        });
    },

    formatPrice(carbonOffset) {
      // Replace non-numerical characters
      return carbonOffset.price_per_metric_ton.replace(/[^\d.-]/g, '');
    },
  },
};
</script>
