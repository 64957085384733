<template>
  <h1>Add Order Form Service</h1>
  <form @submit="create_new_record" class="sl-mt-20px">
    <div class="sl-flex-only sl-mtb-20px">
      <div class="sl-mr-10px">
        <div>Department</div>
        <select v-model="this.client_scope.department_header" :required="true">
          <option disabled value="">Choose a Selection</option>
          <option v-for="item in this.department_types" :key="item" :value="item">{{ item }}</option>
        </select>
      </div>

      <div class="sl-mr-10px">
        <div>Service</div>
        <select v-model="this.client_scope.workscope_id" :required="true">
          <option disabled value="">Choose a Selection</option>
          <option v-for="item in this.workscopes" :key="item.id" :value="item.id">{{ item.name }}</option>
        </select>
      </div>

      <div class="sl-mr-10px">
        <div>Agency</div>
        <select v-model="this.client_scope.fannie_or_freddie">
          <option value=""></option>
          <option v-for="(value, key) in this.agency_types" :key="key" :value="value">{{ value }}</option>
        </select>
      </div>

      <div class="sl-mr-10px">
        <div>Loan</div>
        <select v-model="this.client_scope.loan_type">
          <option value=""></option>
          <option v-for="(value, key) in this.dynamic_loan_types" :key="key" :value="value">{{ value }}</option>
        </select>
      </div>

      <div class="sl-mr-10px">
        <div>Unit Range Bottom</div>
        <input type="number" step="0.01" v-model="this.client_scope.unit_range_bottom">
      </div>

      <div class="sl-mr-10px">
        <div>Unit Range Top</div>
        <input type="number" step="0.01" v-model="this.client_scope.unit_range_top">
      </div>
    </div>
    <!--  -->
    <div class="sl-flex-only sl-mtb-20px">
      <div class="sl-mr-10px">
        <div>Display Service <br>On Main Page</div>
        <input type="checkbox" v-model="this.client_scope.display_on_main">
      </div>

      <div class="sl-mr-10px">
        <div>Service Display Name</div>
        <input type="text" v-model="this.client_scope.name">
      </div>

      <div class="sl-mr-10px">
        <div>Price 1 (15 Bus. Days)</div>
        <input type="number" step="0.01" v-model="this.client_scope.default_fee">
      </div>

      <div class="sl-mr-10px">
        <div>Price 2 (10 Bus. Days)</div>
        <input type="number" step="0.01" v-model="this.client_scope.middle_fee">
      </div>

      <div class="sl-mr-10px">
        <div>Price 3 (Rush)</div>
        <input type="number" step="0.01" v-model="this.client_scope.rush_fee">
      </div>
    </div>

    <div class="sl-mt-10px">
      <button type="submit">create</button>
    </div>
  </form>
  <br>
  <br>
  <hr>
  <br>
  <br>
  <h1>Order Form Services - Master List For All Clients</h1>
  <div class="sl-overflow-x-auto sl-p-all-10px">
    <table class="sl-simple-table-1" style="width: 2000px;">
      <thead>
        <tr>
          <th></th>
          <th>Department</th>
          <th>Service</th>
          <th>Agency</th>
          <th>Loan</th>
          <th>Unit Range <br>Bottom</th>
          <th>Unit Range <br>Top</th>
          <th>Display Service<br>On Main Page</th>
          <th>Service <br>Display Name</th>
          <th>Price 1 <br>(15 Bus. Days)</th>
          <th>Price 2 <br>(10 Bus. Days)</th>
          <th>Price 3 <br>(Rush)</th>
          <th>Updated By</th>
          <th>Created At</th>
          <th>Updated At</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in client_scopes" :key="client_scope.id">
          <td><button @click="edit(item)">Edit</button></td>
          <td>{{ item.department_header }}</td>
          <td>{{ item.workscope }}</td>
          <td>{{ item.fannie_or_freddie }}</td>
          <td>{{ item.loan_type }}</td>
          <td>{{ item.unit_range_bottom }}</td>
          <td style="border-right: 2px solid #005199;">{{ item.unit_range_top }}</td>
          <td>{{ item.display_on_main ? 'yes' : '-' }}</td>
          <td>{{ item.name ? item.name : '-' }}</td>
          <td>{{ item.default_fee > 0 ? item.default_fee : 'RFP' }}</td>
          <td>{{ item.middle_fee > 0 ? item.middle_fee : 'RFP' }}</td>
          <td>{{ item.rush_fee > 0 ? item.rush_fee : 'RFP' }}</td>
          <td>{{ item.updated_by }}</td>
          <td>{{ item.created_at }}</td>
          <td>{{ item.updated_at }}</td>
          <td>
            <button @click="destroy(item.id)">Delete</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- modal -->
  <div class="vue-modal-background" v-if="edit_order_form_scope">
    <div class="vue-modal-content">
      <h1>Edit Order Form Scope</h1>
      <form @submit="update_record" class="sl-mt-20px">
        <div class="sl-flex-only sl-mtb-20px">
          <div class="sl-mr-10px">
            <div>Department</div>
            <select v-model="this.edit_order_form_scope.department_header" :required="true">
              <option disabled value="">Choose a Selection</option>
              <option v-for="item in this.department_types" :key="item" :value="item">{{ item }}</option>
            </select>
          </div>

          <div class="sl-mr-10px">
            <div>Service</div>
            <select v-model="this.edit_order_form_scope.workscope_id" :required="true">
              <option disabled value="">Choose a Selection</option>
              <option v-for="item in this.workscopes" :key="item.id" :value="item.id">{{ item.name }}</option>
            </select>
          </div>

          <div class="sl-mr-10px">
            <div>Agency</div>
            <select v-model="this.edit_order_form_scope.fannie_or_freddie">
              <option value=""></option>
              <option v-for="(value, key) in this.agency_types" :key="key" :value="value">{{ value }}</option>
            </select>
          </div>

          <div class="sl-mr-10px">
            <div>Loan</div>
            <select v-model="this.edit_order_form_scope.loan_type">
              <option value=""></option>
              <option v-for="(value, key) in this.dynamic_loan_types" :key="key" :value="value">{{ value }}</option>
            </select>
          </div>

          <div class="sl-mr-10px">
            <div>Unit Range Bottom</div>
            <input type="number" step="0.01" v-model="this.edit_order_form_scope.unit_range_bottom">
          </div>

          <div class="sl-mr-10px">
            <div>Unit Range Top</div>
            <input type="number" step="0.01" v-model="this.edit_order_form_scope.unit_range_top">
          </div>
        </div>
        <!--  -->
        <div class="sl-flex-only sl-mtb-20px">
          <div class="sl-mr-10px">
            <div>Display Service <br>On Main Page</div>
            <input type="checkbox" v-model="this.edit_order_form_scope.display_on_main">
          </div>

          <div class="sl-mr-10px">
            <div>Service Display Name</div>
            <input type="text" v-model="this.edit_order_form_scope.name">
          </div>

          <div class="sl-mr-10px">
            <div>Price 1 (15 Bus. Days)</div>
            <input type="number" step="0.01" v-model="this.edit_order_form_scope.default_fee">
          </div>

          <div class="sl-mr-10px">
            <div>Price 2 (10 Bus. Days)</div>
            <input type="number" step="0.01" v-model="this.edit_order_form_scope.middle_fee">
          </div>

          <div class="sl-mr-10px">
            <div>Price 3 (Rush)</div>
            <input type="number" step="0.01" v-model="this.edit_order_form_scope.rush_fee">
          </div>
        </div>

        <div class="sl-mt-10px">
          <button type="submit">Update</button>
        </div>
      </form>
      <br>
      <button @click="close_edit">Close Edit</button>
   </div>
  </div>
</template>

<script>
import axios from 'axios';
import { cloneDeep as _cloneDeep } from 'lodash';

export default {
  data() {
    return {
      client_scopes: [],
      workscopes: [],
      department_types: [],
      agency_types: [],
      loan_types: [],
      client_scope: {
        default_fee: 0,
        middle_fee: 0,
        rush_fee: 0,
        fannie_or_freddie: '',
        loan_type: '',
      },
      edit_order_form_scope: null,
    };
  },

  computed: {
    dynamic_loan_types() {
      let view_type = this.edit_order_form_scope;

      if (this.edit_order_form_scope === null) {
        view_type = this.client_scope;
      }

      if (view_type.fannie_or_freddie === 'Fannie Mae') {
        return this.loan_types.type_1;
      }

      if (view_type.fannie_or_freddie === 'Freddie Mac') {
        return this.loan_types.type_2;
      }

      if (view_type.fannie_or_freddie === 'Undecided') {
        return this.loan_types.type_3;
      }

      return [];
    },
  },

  methods: {
    update_record(e) {
      e.preventDefault();
      const currentObj = this;

      // Remove unneeded fields
      delete currentObj.edit_order_form_scope.workscope;
      delete currentObj.edit_order_form_scope.updated_by;
      delete currentObj.edit_order_form_scope.created_at;
      delete currentObj.edit_order_form_scope.updated_at;

      axios.post('/client_scopes/ajax_update', {
        id: currentObj.edit_order_form_scope.id,
        client_scope: currentObj.edit_order_form_scope,
      })
        .then((response) => {
          // Update the list
          currentObj.client_scopes = response.data.client_scopes;
          currentObj.edit_order_form_scope = null;
        })
        .catch((error) => {
          alert(error);
        });
    },

    close_edit() {
      this.edit_order_form_scope = null;
    },

    edit(item) {
      this.edit_order_form_scope = _cloneDeep(item);
    },

    create_new_record(e) {
      e.preventDefault();
      const currentObj = this;

      axios.post('/client_scopes/ajax_create', {
        client_scope: currentObj.client_scope,
      })
        .then((response) => {
        // Update the list
          currentObj.client_scopes = response.data.client_scopes;
        })
        .catch((error) => {
          alert(error);
        });
    },

    destroy(id) {
      const currentObj = this;

      axios.post('/client_scopes/ajax_destroy', {
        id,
      })
        .then((response) => {
          // Update the list
          currentObj.client_scopes = response.data.client_scopes;
        })
        .catch((error) => {
          alert(error);
        });
    },
  },

  async created() {
    await axios.get('/client_scopes/return_primary_list')
      .then((response) => {
        this.client_scopes = response.data.client_scopes;
        this.workscopes = response.data.workscopes;
        this.department_types = response.data.department_types;
        this.agency_types = response.data.agency_types;
        this.loan_types = response.data.loan_types;
      })
      .catch((error) => {
        alert(error);
      });
  },
};
</script>

<style scoped>
  .vue-modal-background {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.1);
  }

  .vue-modal-content {
    background-color: #fefefe;
    padding: 20px;
    margin-bottom: 10%;
    border: 1px solid #888;
    min-width: 600px
  }
</style>
