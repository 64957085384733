<template>
  <SpinningModal v-if="showLoadingModal"/>
  <div class="sl-flex-justify-content-center sl-h-15px sl-mt-10px sl-red-text">{{ noticeMessage }}</div>
  <br>
  <div class="sl-flex-justify-content-center">
    <a @click="showNewForm()" class="sl-blue-link" >New Egrid Subregion</a>
  </div>
  <br>

  <div v-if="egridSubregions" class="sl-flex-justify-content-center">
    <EsgLookupDataTable
      :indexItems="egridSubregions"
      :tableData="tableData()"
      :showEditForm="showEditForm"
      :fetchItems="fetchEgridSubregions"
      :renderLoadingModal="renderLoadingModal"
      :showNoticeMessage="showNoticeMessage"
    />
    <EsgLookupDataForm v-if="formObject"
      :formData="formData()"
      :formObject="formObject"
      :httpAction="httpAction"
      :closeForm="closeForm"
      :fetchItems="fetchEgridSubregions"
      :renderLoadingModal="renderLoadingModal"
      :showNoticeMessage="showNoticeMessage"
    />
  </div>
</template>

<script>
import axios from 'axios';
import EsgLookupDataTable from './EsgLookupDataTable.vue';
import EsgLookupDataForm from './EsgLookupDataForm.vue';
import SpinningModal from '/javascript/components/SpinningModal.vue';

export default {
  components: {
    EsgLookupDataTable,
    EsgLookupDataForm,
    SpinningModal,
  },

  data() {
    return {
      egridSubregions: null,
      formObject: null,
      httpAction: null,
      showLoadingModal: null,
      noticeMessage: null,
    };
  },

  async created() {
    this.fetchEgridSubregions();
  },

  methods: {
    async fetchEgridSubregions() {
      await axios.get('/egrid_subregions/vue_index')
        .then((response) => {
          this.egridSubregions = response.data.egridSubregions;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    tableData() {
      return {
        name: 'Egrid Subregion',
        className: 'egrid_subregions',
        headers: ['Name', 'Acronym', 'NREL Pathway Available'],
        columnNames: ['name', 'acronym', 'nrel_pathway_available'],
      };
    },

    formData() {
      return {
        name: 'Egrid Subregion',
        className: 'egrid_subregions',
        labels: ['Name', 'Acronym', 'NREL Pathway Available'],
        columns: ['name', 'acronym', 'nrel_pathway_available'],
        inputTypes: ['text', 'text', 'select'],
        selectOptions: {
          nrel_pathway_available: {
            options: [{ value: true, name: 'True' }, { value: false, name: 'False' }],
            value: 'value',
            displayValue: 'name',
          },
        },
      };
    },

    showNewForm() {
      this.formObject = {};
      this.httpAction = 'POST';
    },

    showEditForm(object) {
      this.formObject = object;
      this.httpAction = 'PUT';
    },

    closeForm() {
      this.formObject = null;
    },

    renderLoadingModal(boolean) {
      this.showLoadingModal = boolean;
    },

    showNoticeMessage(text, time) {
      this.noticeMessage = text;
      if (time) {
        setTimeout(() => { this.noticeMessage = ''; }, time);
      }
    },
  },
};

</script>
