<template>
  <h2 class="sl-static-h2-style">Additional Selectable Clients</h2>

  <div v-if="user_being_edited" class="sl-font-size-14px" style='margin: 0 auto; width: 1000px;'>
    <p class="sl-text-align-center sl-bold-text">Editing User Id: {{ user_being_edited.id }}, Email: {{ user_being_edited.email }}, Username: {{ user_being_edited.name }}</p>
    <p class="sl-text-align-center">This feature is editable by DB Admins only. This is to allow client users to be able to switch to more than 1 client if needed.</p>

    <div class="sl-flex-justify-content-center">
      <div style="width: 400px;">
        <p class="sl-bold-text">User's Current Client: </p>
        <p>{{ user_current_client }}</p>
        <br>
        <form @submit="formAddClient">
          <select :required="true" v-model="client_id_to_add" class="sl-w-250px">
            <option v-for="client in active_clients_lookup" v-bind:value="client.id" >{{ client.name }}</option>
          </select>

          <button class="sl-ml-10px sl-simple-outline-btn ">Add Client</button>
        </form>

        <p class="sl-bold-text">Additional added clients for the current user:</p>
        <p class="sl-bold-text sl-red-text">Note: Make sure the user's original client is added to the list below so they can switch back to their original client.</p>

        <div v-if="loading_client_list" class="sl-spinning-wheel-20px"></div>
        <div v-else>
          <div v-if="user_additional_selectable_clients.length === 0">No additional clients</div>

          <table class="sl-simple-table-1">
            <tbody>
              <tr v-for="client in user_additional_selectable_clients" :key="client.id">
                <td>{{ client.name }}</td>
                <td>
                  <form @submit="formRemoveClient">
                    <input type="hidden" name="client_id_to_remove" :value="client.id">
                    <button class="sl-simple-outline-btn">Remove</button>
                  </form>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <!-- Spinning wheel loader -->
  <div v-else >
    <div class="sl-flex-justify-align-center" style="width: 100%; height: 400px;">
      <div class="sl-spinning-wheel-50px"></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      user_being_edited: null,
      user_current_client: null,
      user_additional_selectable_clients: [],
      active_clients_lookup: [],
      loading_client_list: false,
      add_client_ajax_called: false,
    };
  },

  async created() {
    const urlParams = new URLSearchParams(window.location.search);

    await axios.get('/users/return_user_being_edited', {
      params: {
        user_id: urlParams.get('user_id'), // get by the param name
      },
    })
      .then((response) => {
        // The setTimeout is just to simulate a delay, so the loader will show for a second
        setTimeout(() => {
          this.user_being_edited = response.data.user_being_edited,
          this.user_current_client = response.data.user_current_client;
          this.user_additional_selectable_clients = response.data.user_additional_selectable_clients;
          this.active_clients_lookup = response.data.active_clients_lookup;
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
      });
  },

  methods: {
    // Function to add a client from the dropdown list form
    formAddClient(e) {
      e.preventDefault();
      const currentObj = this;
      let client_already_added = false;

      // If the ajax call is already running, don't run it again and return
      if (currentObj.add_client_ajax_called) { return; }
      // This is to prevent click spamming, it'll get reset to false once the axios call is done
      currentObj.add_client_ajax_called = true;

      // check if client_id_to_add is already in the list
      currentObj.user_additional_selectable_clients.forEach((client) => {
        if (client.id === currentObj.client_id_to_add) {
          client_already_added = true;
        }
      });

      // if true, alert box will appear, and the function will stop, and not continue to the axios call
      if (client_already_added) {
        alert('Client already added');
        currentObj.add_client_ajax_called = false;
        return;
      }

      axios.post('/users/update_additional_selectable_clients_for_user', {
        user_id: currentObj.user_being_edited.id,
        client_id_to_add: currentObj.client_id_to_add,
      })
        .then((response) => {
          currentObj.loading_client_list = true;

          // The setTimeout is just to simulate a delay, so the loader will show for a second
          setTimeout(() => {
            currentObj.user_additional_selectable_clients = response.data.user_additional_selectable_clients;
            currentObj.loading_client_list = false;
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          // This will always run, regardless if the call was successful or not
          // This is where we reset the add_client_ajax_called variable to false, so it can be called again
          setTimeout(() => {
            currentObj.add_client_ajax_called = false;
          }, 1000);
        });
    },

    // Function to remove a client from the list
    formRemoveClient(e) {
      e.preventDefault();
      const currentObj = this;
      const client_id_to_remove = e.target.querySelector('input').value;

      axios.post('/users/remove_additional_selectable_clients_for_user', {
        user_id: currentObj.user_being_edited.id,
        client_id_to_remove,
      })
        .then((response) => {
          currentObj.loading_client_list = true;

          setTimeout(() => {
            currentObj.user_additional_selectable_clients = response.data.user_additional_selectable_clients;
            currentObj.loading_client_list = false;
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>
/*
  Any styles very specific to this page only go here.
  If you need to add a style that will be used in other pages, add it to the global styles folder
  app/assets/stylesheets
*/
</style>
