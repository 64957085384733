<template>
  <div class='sl-flex-justify-content-center sl-pb-30px'>
    <div class='sl-w-500px'>
      <ol>
        <li class='report-table-instructions'>
          Include bordering streets, alleys, or easement as well as diagonal corner properties in the table below; these are considered adjoining.
        </li>
        <li class='report-table-instructions'>
          Adjoining properties are those beyond the roadway (including highways) and not the roadways themselves.
        </li>
        <li class='report-table-instructions'>
          Additionally, leave the field blank if we do not have an adjoining property in that direction.
        </li>
      </ol>
    </div>
  </div>
</template>
