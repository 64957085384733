<template>
  <div class='sl-modal-submit-doc'>
    <div class='sl-modal-submit-doc-content'>
      <div style='position: relative'>
        <div class='sl-modal-submit-text'>
          By clicking Submit Report you will no longer be able to make changes to any data without contacting admin@sitelynx.net.
        </div>
        <div class='sl-centered-modal-buttons'>
          <button class='pl-gray-border-and-fill-text-rect-btn sl-modal-submit-doc-back' type='button'>Go Back</button>
          <button class='pl-blue-border-and-fill-text-rect-btn sl-modal-submit-doc-confirm-submit' type='button'>Submit Report</button>
        </div>
      </div>
    </div>
    <div class='sl-modal-submit-confirm-content'>
      <div style='position: relative'>
        <div class='sl-modal-submit-text'>
          Thank you!
        </div>
        <div class='sl-modal-submit-text sl-modal-submit-subtext'>
          Your report has been submitted. Should you need to make any changes please contact contact admin@sitelynx.net.
        </div>
        <div class='sl-centered-modal-buttons'>
          <button class='pl-gray-border-and-fill-text-rect-btn sl-modal-submit-doc-back' type='button'>Go Back</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubmitReportModal',
};
</script>
