<template>
  <table  class="sl-esg-decarbonization-table">
    <thead>
      <tr class="sl-table__row sl-table__row--esg">
        <td class="sl-w-75px"></td>
        <td  v-for="header in tableData.headers" class="sl-w-200px">{{ header }}</td>
        <td class="sl-w-75px"></td>
      </tr>
    </thead>

    <tbody v-if="indexItems.length">
      <tr v-for="(object, index) in indexItems" class="sl-table__row sl-table__row--esg">
        <td class="sl-w-75px">
          <span class="sl-mr-5px">{{ index + 1 }}.</span>
          <a class="sl-blue-link" @click=showEditForm(object)>Edit</a>
        </td>
        <td v-for="columnName in tableData.columnNames" class="sl-w-200px">{{ object[columnName] }}</td>
        <td @click="deleteItem(object)" class="sl-w-75px sl-blue-link">Destroy</td>
      </tr>
    </tbody>
    <div v-else class="sl-flex-justify-align-center sl-h-200px">
      <span class="sl-font-size-14px">No results to display.</span>
    </div>
  </table>
</template>

<script>
import axios from 'axios';

export default {
  props: ['tableData', 'indexItems', 'showEditForm', 'fetchItems', 'renderLoadingModal', 'showNoticeMessage'],

  methods: {
    async deleteItem(item) {
      if (confirm(`Are you sure you want to delete the ${item.name} ${this.tableData.name}?`)) {
        this.renderLoadingModal(true);
        const url = `${this.tableData.className}/${item.id}`;
        await axios.delete(url, item)
          .then(() => {
            this.fetchItems();
            setTimeout(() => {
              this.renderLoadingModal(false);
              this.showNoticeMessage(`${this.tableData.name} successfully deleted.`, 2000);
            }, 500);
          })
          .catch((error) => {
            this.renderLoadingModal(false);
            console.log(error);
            this.showNoticeMessage('An error has occurred. Please check your internet connection and refresh your browser.');
          });
      }
    },
  },
};
</script>
