<template>
  <form v-if='renderFields()'
    class='main-form sl-mt-20px sl-mb-20px sl-border-b-1px'>
    <div class='sl-w-750px sl-pb-20px'>
      <div v-if='isFieldPresent' class='sl-new-text-area sl-flex-align-items-center'>
        <div class='sl-mr-20px sl-w-150px'>{{ field.question_text }}</div>
        <input v-model='field.data'
              @change="$emit('update', field.data)"
              :name='field.name'
              type='text'/>
      </div>

      <!-- Requirements Code Input -->
      <div v-if="addRequirementCodeAvaialable()"
           class='sl-new-text-area sl-flex-align-items-center sl-mt-20px'>
        <div class='sl-mr-20px sl-w-150px'>
          <button @click.prevent="$emit('updateRequirementsCode', requirementsCode)"
                  class='sl-simple-outline-btn sl-w-125px'>
                  Add Code For All
          </button>
        </div>
        <input v-model='requirementsCode'
              @change="$emit('update', field.data)"
              :name='field.name'
              type='text'/>
      </div>

      <!-- Table Import -->
      <div v-if="importTableAvaialable()"
           class='sl-new-text-area sl-flex-align-items-center sl-mt-20px'>
        <div class='sl-mr-20px sl-w-150px'>
          <button @click.prevent="$emit('importTable', importTable)"
                  class='sl-simple-outline-btn sl-w-125px'>
                  Copy Data From
          </button>
        </div>
        <div class='sl-w-700px'>
          <select v-model='importTable' class='sl-h-32px sl-w-300px sl-border-1px-medium-charcoal'>
            <option :value='importTable'>{{ importTableDisplayName }}</option>
            <option :value="`${importTable} two`">{{ importTableDisplayName }} 2</option>
            <option :value="`${importTable} three`">{{ importTableDisplayName }} 3</option>
            <option :value="`${importTable} four`">{{ importTableDisplayName }} 4</option>
          </select>
        </div>
      </div>

    </div>
  </form>
</template>
<script>
export default {
  props: ['projectId', 'tableName', 'field', 'isFieldPresent'],

  mounted() {
    if (this.tableName.includes('bulk requirements')) {
      this.importTable = 'bulk requirements';
      this.importTableDisplayName = 'Bulk Requirements';
    } else if (this.tableName.includes('mixed use')) {
      this.importTable = 'mixed use';
      this.importTableDisplayName = 'Mixed Use';
    } else if (this.tableName.includes('optional requirements')) {
      this.importTable = 'optional requirements';
      this.importTableDisplayName = 'Optional Requirements';
    } else if (this.tableName.includes('split requirements')) {
      this.importTable = 'split requirements';
      this.importTableDisplayName = 'Split Requirements';
    }
  },

  data() {
    return {
      requirementsCode: null,
      importTable: null,
      importTableDisplayName: null,
    };
  },

  methods: {
    addRequirementCodeAvaialable() {
      return this.tableName.includes('bulk requirements')
             || this.tableName.includes('mixed use')
             || this.tableName.includes('optional requirements')
             || this.tableName.includes('split requirements');
    },

    importTableAvaialable() {
      return this.tableName.includes('bulk requirements')
             || this.tableName.includes('mixed use')
             || this.tableName.includes('optional requirements')
             || this.tableName.includes('split requirements');
    },

    renderFields() {
      return this.isFieldPresent || this.addRequirementCodeAvaialable() || this.importTableAvaialable();
    },
  },
};
</script>
<style scoped>
  .selected {
    background-color: #005199 !important;
    color: white !important;
  }

  .selected:hover {
    background-color: #005199;
    color: white !important;
  }
</style>
