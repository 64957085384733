<template>
  <SpinningModal v-if="show_loading_modal"/>
  <div v-if="show_main_modal" id="sl-data-transfer-modal-container" class="sl-flex-only">
    <div class="sl-data-transfer-modal-content" style="max-width: 1200px;">
       <!-- Modal messages -->
      <div class="sl-flex-justify-content-center sl-h-15px" style="color: red">{{ error_modal_message }}</div>
      <div class="sl-flex-justify-content-center sl-h-15px" style="color: blue">{{ success_modal_message }}</div>

      <div class="sl-flex-justify-content-space-btwn">
        <div class="sl-flex-column sl-w-750px">
          <span class="pl-2-column-area sl-mb-10px">Communication Log Data Transfer</span>
        </div>
        <div class="sl-w-40px">
          <CloseIcon class='figma-icon' @click="close_modal" id="sl-history-log-close"/>
        </div>
      </div>

      <div class="sl-w-100per sl-mt-20px sl-border-t-2px-primary-blue"></div>

      <!-- Search for Source Project -->
      <div class="sl-flex-justify-content-center">
        <div style='width: 50%'>
          <div class="sl-flex-column sl-flex-align-items-center sl-mt-20px">
            <p>Search for Source Project</p>
            <textarea v-model="originating_project_number"
                      class="sl-border-1px-medium-charcoal"
                      placeholder="Project Number"></textarea>
            <div class="sl-flex-justify-content-end sl-mt-10px">
              <button @click="search_origin" class="sl-simple-outline-btn sl-w-100px sl-h-28px">Search</button>
            </div>
          </div>
        </div>

        <!-- Select Source Project -->
        <div style='width: 50%'>
          <div class="sl-flex-column sl-flex-align-items-center sl-mt-20px">
            <p>Select Source Project</p>
            <div id='sl-origin-project-select'>
              <select v-model="selected_origin_project">
                <option disabled value="">Please select one</option>
                <option v-for="project in origin_projects" :key="project.id" :value="project.id">
                  {{ project.number}} {{ project.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="sl-w-100per sl-mt-20px sl-border-t-2px-primary-blue"></div>

      <!-- Search for Destination Project(s) -->
      <div class="sl-flex-justify-content-center">
        <div style='width: 50%'>
          <div class="sl-flex-column sl-flex-align-items-center sl-mt-20px">
            <p>Search for Destination Project(s)</p>
            <textarea v-model="destination_project_number"
                      class="sl-border-1px-medium-charcoal"
                      placeholder="Project Number"></textarea>
            <div class="sl-flex-justify-content-end sl-mt-10px">
              <button @click="search_destination" class="sl-simple-outline-btn sl-w-100px sl-h-28px">Search</button>
            </div>
          </div>
        </div>

        <!-- Select Destination Project(s) -->
        <div style='width: 50%'>
          <div class="sl-flex-column sl-flex-align-items-center sl-mt-20px">
            <p>Select Destination Project(s)</p>
            <div id='sl-destination-project-select'>
              <select v-model="destination_selected_projects" multiple>
                <option disabled value="">Please select one</option>
                <option v-for="project in destination_projects" :key="project.id" :value="project.id">
                  {{ project.number}} {{ project.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="sl-w-100per sl-mt-20px sl-border-t-2px-primary-blue"></div>

      <!-- Hide/show all children checkboxes -->
      <div class='sl-mlr-20px sl-mt-20px sl-text-align-center'>
        <button @click="toggle_children_checkboxes" class="sl-simple-outline-btn sl-w-200px sl-h-28px">Show Log Level Information</button>
      </div>

      <!-- Select all data points for transfer -->
      <div class="sl-flex-only sl-mt-20px sl-ml-10px sl-mb-20px">
        <div class="data-transfer-checkbox-parent">
          <div class="sl-progress-checkbox">
            <label for="communication_logs_data_all">
              <input type="checkbox"
                   id="communication_logs_data_all"
                   v-model="select_all_documents">
              <span class="sl-progress-checkmark sl-progress-checkmark-charcoal"></span>
            </label>
          </div>
        </div>
        <div class="sl-mt-3px sl-ml-10px">Select All Documents</div>
      </div>

      <!-- Document records and their attributes -->
      <div v-for="record in communication_log_records" class="sl-mb-10px">
        <div class="sl-flex-only sl-ml-10px">
          <div class="data-transfer-checkbox-parent">
            <div class="sl-progress-checkbox">
              <label :for="'communication_log_header_box_' + format_document_name(record.relating_to)">
                <input type="checkbox"
                       :id="'communication_log_header_box_' + format_document_name(record.relating_to)"
                       v-model="selected_documents_header[record.id]"
                       @change="toggle_document_children_checkboxes(record.id)">
                <span class="sl-progress-checkmark sl-progress-checkmark-charcoal"></span>
              </label>
            </div>
          </div>
          <div class="sl-mt-3px sl-ml-10px">{{ record.relating_to }}</div>
        </div>

        <div v-for="(attribute_value, attribute_name) in hash_communication_logs[record.id]" v-show="show_children_checkboxes">
          <div class="sl-flex-only sl-ml-20px">
            <div class="data-transfer-checkbox-parent">
              <div class="sl-progress-checkbox">
                <label :for="'communication_log_box_' + format_document_name(record.relating_to) + '_' + attribute_name">
                  <input type="checkbox"
                      :id="'communication_log_box_' + format_document_name(record.relating_to) + '_' + attribute_name"
                      v-model="selected_attributes[record.id][attribute_name]"
                      @change="toggle_document_parent_checkbox(record.id)">
                  <span class="sl-progress-checkmark sl-progress-checkmark-charcoal"></span>
                </label>
              </div>
            </div>
            <div class="sl-mt-3px sl-ml-10px">
              {{ attribute_names[attribute_name] || attribute_name }}
              {{ attribute_value }}
            </div>
          </div>
        </div>

        <!-- Previous Note Versions -->
        <div class="sl-flex-only sl-ml-20px">
          <div class="data-transfer-checkbox-parent">
            <div class="sl-progress-checkbox">
              <label :for="'communication_log_note_versions_header_box_' + format_document_name(record.relating_to)">
                <input type="checkbox"
                    :id="'communication_log_note_versions_header_box_' + format_document_name(record.relating_to)"
                    v-model="selected_note_versions_header[record.id]"
                    @change="toggle_note_version_children_checkboxes(record.id)">
                <span class="sl-progress-checkmark sl-progress-checkmark-charcoal"></span>
              </label>
            </div>
          </div>
          <div class="sl-mt-3px sl-ml-10px">Previous Note Versions</div>
        </div>

        <div v-for="(note, note_id) in hash_communication_log_note_versions[record.relating_to]" v-show="show_children_checkboxes">
          <div class="sl-flex-only sl-ml-30px">
            <div class="data-transfer-checkbox-parent">
              <div class="sl-progress-checkbox">
                <label :for="'communication_log_box_' + format_document_name(record.relating_to) + '_' + note_id">
                  <input type="checkbox"
                      :id="'communication_log_box_' + format_document_name(record.relating_to) + '_' + note_id"
                      v-model="selected_note_versions[record.relating_to][note_id]"
                      @change="toggle_note_version_parent_checkbox(record.id, record.relating_to)">
                  <span class="sl-progress-checkmark sl-progress-checkmark-charcoal"></span>
                </label>
              </div>
            </div>
            <div class="sl-mt-3px sl-ml-10px">
              {{ note[0] }}
              {{ note[1] }}
            </div>
          </div>
        </div>
      </div>

      <div class="sl-w-100per sl-mt-20px sl-border-t-2px-primary-blue"></div><br>

      <div class='sl-mlr-20px sl-text-align-center'>
        <button @click="transfer_communication_log_data" class="sl-simple-outline-btn sl-w-200px sl-h-28px">Transfer Communication Logs</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import CloseIcon from '../../../assets/images/icons/Icon-Close_Charcoal-Dark-100_24.svg';
import SpinningModal from './SpinningModal.vue';

export default {
  components: {
    CloseIcon,
    SpinningModal,
  },
  data() {
    return {
      show_main_modal: false,
      show_loading_modal: false,
      success_modal_message: '',
      error_modal_message: '',
      show_children_checkboxes: true,
      originating_project_number: '',
      selected_origin_project: '',
      origin_projects: [],
      destination_project_number: '',
      destination_selected_projects: [],
      destination_projects: [],
      communication_log_records: [],
      hash_communication_logs: {},
      attribute_names: [],
      hash_communication_log_note_versions: {},
      select_all_documents: false,
      selected_documents_header: {},
      selected_attributes: {},
      selected_note_versions_header: {},
      selected_note_versions: {},
      communication_log_item_ids_to_copy: {},
      previous_note_version_ids_to_copy: {},
    };
  },
  async mounted() {
    document.addEventListener('click', this.handle_communication_log_data_transfer_click);
  },
  methods: {
    handle_communication_log_data_transfer_click(event) {
      if (event.target.matches('.comm-log-data-transfer')) {
        this.show_loading_modal = true;
        const project_id = event.target.getAttribute('project_id');
        this.fetch_origin_projects(project_id);
        this.fetch_communication_logs(project_id);
      }
    },
    async fetch_origin_projects(project_id) {
      await axios.get('/communication_logs/vue_communication_log_data_transfer_modal', {
        params: {
          id: project_id,
        },
      })
        .then((response) => {
          this.show_loading_modal = false;
          this.show_main_modal = true;
          this.originating_project_number = response.data.originating_project_number;
          this.selected_origin_project = response.data.selected_origin_project;
          this.origin_projects = response.data.origin_projects;
        })
        .catch(() => {
          this.show_loading_modal = false;
          alert('An error occured. Please reach out to admin@sitelynx.net for support.');
        });
    },
    // Fetch communication log records for the project
    async fetch_communication_logs(project_id) {
      await axios.get('/communication_logs/communication_log_transfer_data', {
        params: {
          id: project_id,
        },
      })
        .then((response) => {
          this.communication_log_records = response.data.communication_log_records;
          this.attribute_names = response.data.attribute_names;
          this.hash_communication_logs = response.data.hash_communication_logs;
          this.hash_communication_log_note_versions = response.data.hash_communication_log_note_versions;

          this.communication_log_records.forEach((record) => {
            if (!this.selected_documents_header[record.id]) {
              this.selected_documents_header[record.id] = false;
            }
            if (!this.selected_attributes[record.id]) {
              this.selected_attributes[record.id] = {};
            }
            Object.keys(this.hash_communication_logs[record.id]).forEach((attribute_name) => {
              if (!(attribute_name in this.selected_attributes[record.id])) {
                this.selected_attributes[record.id][attribute_name] = false;
              }
            });

            if (!this.selected_note_versions_header[record.id]) {
              this.selected_note_versions_header[record.id] = false;
            }
            if (!this.selected_note_versions[record.relating_to]) {
              this.selected_note_versions[record.relating_to] = {};
            }
            Object.keys(this.hash_communication_log_note_versions[record.relating_to]).forEach((note_id) => {
              if (!(note_id in this.selected_note_versions[record.relating_to])) {
                this.selected_note_versions[record.relating_to][note_id] = false;
              }
            });
          });
        })
        .catch(() => {
          alert('An error occured. Please reach out to admin@sitelynx.net for support.');
        });
    },
    // Format document name for use in checkbox ids
    format_document_name(relating_to) {
      return relating_to.replace(/ /g, '_');
    },
    // Hide/show children checkboxes for communication log records
    toggle_children_checkboxes() {
      this.show_children_checkboxes = !this.show_children_checkboxes;
    },
    // Toggle checkboxes for all documents and their attributes
    toggle_document_children_checkboxes(parent_id) {
      const is_parent_checked = this.selected_documents_header[parent_id];

      this.communication_log_records.forEach((record) => {
        if (record.id !== parent_id) return;

        Object.keys(this.hash_communication_logs[record.id]).forEach((attribute_name) => {
          this.selected_attributes[record.id][attribute_name] = is_parent_checked;
        });

        this.selected_note_versions_header[record.id] = is_parent_checked;
        Object.keys(this.hash_communication_log_note_versions[record.relating_to]).forEach((note_id) => {
          this.selected_note_versions[record.relating_to][note_id] = is_parent_checked;
        });
      });
    },
    // Uncheck parent checkbox if all children checkboxes are unchecked
    toggle_document_parent_checkbox(record_id) {
      const is_parent_checked = Object.values(this.selected_attributes[record_id]).every((value) => value);
      this.selected_documents_header[record_id] = is_parent_checked;
    },
    // Toggle checkboxes for all note versions of a document
    toggle_note_version_children_checkboxes(parent_id) {
      const is_parent_checked = this.selected_note_versions_header[parent_id];

      this.communication_log_records.forEach((record) => {
        if (record.id !== parent_id) return;

        Object.keys(this.hash_communication_log_note_versions[record.relating_to]).forEach((note_id) => {
          this.selected_note_versions[record.relating_to][note_id] = is_parent_checked;
        });
      });
    },
    // Uncheck parent checkbox if all children checkboxes are unchecked for both documents and note versions
    toggle_note_version_parent_checkbox(record_id, document_name) {
      const is_parent_note_version_checked = Object.values(this.selected_note_versions[document_name]).every((value) => value);
      this.selected_note_versions_header[record_id] = is_parent_note_version_checked;
      this.selected_attributes[record_id]['Previous Note Versions'] = is_parent_note_version_checked;

      const is_parent_document_checked = Object.values(this.selected_attributes[record_id]).every((value) => value);
      this.selected_documents_header[record_id] = is_parent_document_checked;
    },
    // Search for projects based on project number
    async search_origin() {
      await axios.get('/communication_logs/search_zoning_projects_for_communication_log_transfer', {
        params: {
          search: this.originating_project_number,
        },
      })
        .then((response) => {
          this.origin_projects = response.data.projects;
          this.selected_origin_project = '';
          this.show_success_message(`Found ${this.origin_projects.length} projects.`);
        })
        .catch(() => {
          this.show_error_message('An error occurred and your search was unsuccessful.');
        });
    },
    // Search projects based on project number for destination projects
    async search_destination() {
      await axios.get('/communication_logs/search_zoning_projects_for_communication_log_transfer', {
        params: {
          search: this.destination_project_number,
        },
      })
        .then((response) => {
          this.destination_projects = response.data.projects;
          this.destination_selected_projects = [];
          this.show_success_message(`Found ${this.destination_projects.length} projects.`);
        })
        .catch(() => {
          this.show_error_message('An error occurred and your search was unsuccessful.');
        });
    },
    async transfer_communication_log_data() {
      // Loop through selected attributes and build an array of items to copy
      this.communication_log_item_ids_to_copy = {};
      Object.keys(this.selected_attributes).forEach((record_id) => {
        Object.keys(this.selected_attributes[record_id]).forEach((attribute) => {
          if (this.selected_attributes[record_id][attribute]) {
            if (record_id in this.communication_log_item_ids_to_copy) {
              this.communication_log_item_ids_to_copy[record_id].push(attribute);
            } else {
              this.communication_log_item_ids_to_copy[record_id] = [attribute];
            }
          }
        });
      });

      // Loop through selected note versions and build an array of note IDs to copy
      this.previous_note_version_ids_to_copy = {};
      Object.keys(this.selected_note_versions).forEach((document_name) => {
        Object.keys(this.selected_note_versions[document_name]).forEach((note_id) => {
          if (this.selected_note_versions[document_name][note_id]) {
            if (document_name in this.previous_note_version_ids_to_copy) {
              this.previous_note_version_ids_to_copy[document_name].push(note_id);
            } else {
              this.previous_note_version_ids_to_copy[document_name] = [note_id];
            }
          }
        });
      });

      try {
        const response = await axios.get('/communication_logs/transfer_communication_logs', {
          params: {
            originating_project: this.selected_origin_project,
            destination_projects: this.destination_selected_projects,
            communication_log_item_ids_to_copy: this.communication_log_item_ids_to_copy,
            previous_note_version_ids_to_copy: this.previous_note_version_ids_to_copy,
          },
        });
        this.show_success_message(response.data.status_message);
      } catch (error) {
        this.show_error_message('An error occurred and your communication log data transfer was unsuccessful.');
      }
    },
    close_modal() {
      this.show_main_modal = false;
    },
    show_success_message(message) {
      this.success_modal_message = message;
      setTimeout(() => {
        this.success_modal_message = '';
      }, 3000);
    },
    show_error_message(message) {
      this.error_modal_message = message;
      setTimeout(() => {
        this.error_modal_message = '';
      }, 3000);
    },
  },
  watch: {
    // Watch for changes in the select_all_documents checkbox
    select_all_documents(new_val) {
      // Update document headers
      Object.keys(this.selected_documents_header).forEach((key) => {
        this.selected_documents_header[key] = new_val;
      });

      // Update attributes for each communication log record
      Object.keys(this.selected_attributes).forEach((record_id) => {
        Object.keys(this.selected_attributes[record_id]).forEach((attribute) => {
          this.selected_attributes[record_id][attribute] = new_val;
        });
      });

      // Update note versions headers
      Object.keys(this.selected_note_versions_header).forEach((key) => {
        this.selected_note_versions_header[key] = new_val;
      });

      // Update selected note versions for each communication log record
      Object.keys(this.selected_note_versions).forEach((record_id) => {
        Object.keys(this.selected_note_versions[record_id]).forEach((note_id) => {
          this.selected_note_versions[record_id][note_id] = new_val;
        });
      });
    },
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleCommLogDataTransferClick);
  },
};
</script>
