<template>
  <div class="data-transfer-modal">
    <div class="data-transfer-modal-content sl-box-shadow-3 sl-max-w-680px">
      <div class="sl-w-100per sl-flex-justify-content-end">
        <CloseIcon @click="closeForm" class="sl-mr-20px sl-blue-link"/>
      </div>

      <h2 class="sl-text-align-center sl-h2-roboto sl-mb-20px sl-h-28px">
        {{ httpAction.includes("POST") ? `New ${formData.name}` : `Edit ${formData.name}` }}
      </h2>

      <form @submit.prevent="submitForm(formObject)" class="sl-flex-align-items-center sl-flex-column">
        <div v-for="(columnName, index) in formData.columns" class="sl-flex-column sl-flex-align-items-center sl-w-310px sl-mtb-20px">
          <div class="sl-flex-justify-content-start sl-w-100per sl-mb-5px">
            <label class="sl-font-size-14px">{{ formData.labels[index] }}</label>
          </div>

          <!-- Conditional rendering of different input types -->
          <!-- Text Input -->
          <input v-if="formData.inputTypes[index] === 'text'" v-model="formObject[columnName]" type="text" required
                class="sl-border-1px-black sl-ptb-10px sl-w-300px sl-pl-10px">

          <!-- Integer Number Input -->
          <input v-if="formData.inputTypes[index] === 'integer'" v-model="formObject[columnName]" type="number" required
                class="sl-border-1px-black sl-ptb-10px sl-w-300px sl-pl-10px sl-disable-number-input-up-down-arrows">

          <!-- Decimal Number Input -->
          <input v-if="formData.inputTypes[index] === 'decimal'" v-model="formObject[columnName]" type="number" step="any" required
                class="sl-border-1px-black sl-ptb-10px sl-w-300px sl-pl-10px sl-disable-number-input-up-down-arrows">

          <!-- Select Input -->
          <select v-if="formData.inputTypes[index] === 'select'" v-model="formObject[columnName]" required
                  class="sl-border-1px-black sl-ptb-10px sl-w-315px sl-pl-10px">
            <option v-for="selectOption in formData.selectOptions[columnName]['options']"
                    :value="selectOption[formData.selectOptions[columnName]['value']]">
                    {{ selectOption[formData.selectOptions[columnName]['displayValue']] }}
            </option>
          </select>

          <!-- Disabled Input -->
          <div v-if="formData.inputTypes[index] === 'disabled'"
                class="sl-border-2px-light-charcoal sl-ptb-10px sl-w-300px sl-pl-10px">
              {{ formObject[columnName] }}
          </div>
        </div>

        <button class="sl-simple-outline-btn sl-w-115px sl-mtb-20px sl-h-32px">
          {{ httpAction.includes("POST") ? "Save" : "Update" }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import CloseIcon from '/../assets/images/icons/Icon-Close_Charcoal-Dark-100_24.svg';

export default {
  components: {
    CloseIcon,
  },
  props: ['formData', 'formObject', 'httpAction', 'closeForm', 'renderLoadingModal', 'fetchItems', 'showNoticeMessage'],

  methods: {
    async submitForm(formObject) {
      this.closeForm();
      this.renderLoadingModal(true);
      await this.httpRequest(formObject)
        .then(() => {
          this.fetchItems();
          setTimeout(() => {
            this.renderLoadingModal(false);
            this.showNoticeMessage('Table Saved', 1000);
          }, 500);
        })
        .catch((error) => {
          this.renderLoadingModal(false);
          console.log(error);
          this.showNoticeMessage('An error has occurred. Please check your internet connection and refresh your browser.');
        });
    },
    httpRequest(formObject) {
      const url = this.formData.className;
      switch (this.httpAction) {
      case 'PUT':
        return axios.put(`${url}/${formObject.id}`, formObject);
      case 'POST':
        return axios.post(url, formObject);
      }
    },
  },
};
</script>
