<template>
  <SpinningModal v-if="showLoadingModal"/>
  <div v-if="showMainModal" id="sl-data-transfer-modal-container" class="sl-flex-only">
    <div class="sl-data-transfer-modal-content" style="max-width: 1200px;">
      <div class="sl-flex-justify-content-center sl-h-15px" style="color: red">{{ errorModalMessage }}</div>
      <div class="sl-flex-justify-content-center sl-h-15px" style="color: blue">{{ successModalMessage }}</div>
      <div class="sl-flex-justify-content-space-btwn">
        <div class="sl-flex-column sl-w-750px">
          <h2 class="pl-2-column-area"><u>Multi Photolog Photo Move</u></h2>
          <ul>
            <li>Step 1: Select photolog photos to move (Selected Photos)</li>
            <li>Step 2: Determine where to move selected photos by typing in new starting position for the photos (New Starting Position)</li>
            <li>Step 3: Click 'Move Selected Photos' button</li>
            <li>Note: Moved photos will be inserted in front of selected starting position (in their current order)</li>
          </ul>
        </div>
        <div class="sl-w-40px">
          <CloseIcon class='figma-icon' @click="closeModal"/>
        </div>
      </div>
      <div class="sl-w-100per sl-mt-20px sl-border-t-2px-primary-blue"></div>
      <br>
      <div class='sl-mlr-20px sl-text-align-center' style='display: flex; flex-direction: row; align-items: flex-start; justify-content: space-evenly;'>
        <div>
          <h2>New Starting Position:</h2>
          <div class='sl-new-text-area'>
            <input type="number" v-model="new_starting_position" :min="1" :max="max_position" @input="getNewSectionHeader"/>
          </div>
        </div>
        <div>
          <h2>New Section:</h2>
          <p>{{ new_section_header }}</p>
        </div>
      </div>
      <br>
      <div class='sl-mlr-20px sl-text-align-center'>
        <button @click="movePhotos" id="" class="sl-simple-outline-btn sl-w-200px sl-h-28px">Move Selected Photos</button>
      </div>
      <div class="sl-w-100per sl-mt-20px sl-border-t-2px-primary-blue"></div>
      <br>
      <div>
        <h2>Selected Photos</h2>
        <ul v-if="checkedPhotos.length > 0">
          <li v-for="checked_photo in checkedPhotos" :key="checked_photo.id">
            Photo Order: {{ checked_photo.order }}, Description: {{ checked_photo.description }}
          </li>
        </ul>
        <p v-else style='font-size: 11px; color: red;'>NOTE: No photos selected to move (please close and select photolog photos)</p>
      </div>
      <div class="sl-w-100per sl-mt-20px sl-border-t-2px-primary-blue"></div>
      <br>
      <div>
        <!-- Iterate over each section -->
        <div v-for="(section, index) in sections" :key="index">
          <h2>{{ section.name }}</h2>
          <!-- List of photo details within each section -->
          <ul v-if="section.photo_data_list.length > 0">
            <li v-for="photo in section.photo_data_list" :key="photo.id">
              Photo Order: {{ photo.order }}, Description: {{ photo.description }}
            </li>
          </ul>
          <p v-else style='font-size: 11px; color: red;'>NOTE: Empty, photo move will not work for this section</p>
        </div>
      </div>
      <br>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import CloseIcon from '../../../assets/images/icons/Icon-Close_Charcoal-Dark-100_24.svg';
import SpinningModal from './SpinningModal.vue';

export default {
  components: {
    CloseIcon,
    SpinningModal,
  },
  data() {
    return {
      showMainModal: false,
      showLoadingModal: false,
      successModalMessage: '',
      errorModalMessage: '',
      sections: [],
      checkedPhotos: [],
      project_id: null,
      project_type: null,
      new_starting_position: null,
      new_section_header: null,
      new_section_header_group: null,
      max_position: 1,
    };
  },
  async created() {
    $jq(document).on('click', '#sl-multi-photo-move-modal-btn', (event) => {
      this.showLoadingModal = true;
      const projectId = event.target.getAttribute('project_id');
      const projectType = event.target.getAttribute('project_type');
      this.project_id = projectId;
      this.project_type = projectType;
      this.fetchModalData(projectId, projectType);
      this.getSectionNames();
      this.getCheckedPhotos();
    });
  },
  methods: {
    async fetchModalData(projectId, projectType) {
      await axios.get('/locationpics/show_vue_multi_photo_move_modal', {
        params: {
          project_id: projectId,
          project_type: projectType,
        },
      })
        .then(() => {
          this.showLoadingModal = false;
          this.showMainModal = true;
        })
        .catch(() => {
          this.showLoadingModal = false;
          alert('An error occured. Please reach out to admin@sitelynx.net for support.');
        });
    },
    async movePhotos() {
      this.showLoadingModal = true;
      if (this.checkedPhotos.length === 0) {
        this.showLoadingModal = false;
        this.errorModalMessage = 'No photos selected to move';
        return;
      }
      if (this.new_starting_position === null) {
        this.showLoadingModal = false;
        this.errorModalMessage = 'Please enter a new starting position';
        return;
      }
      await axios.post('/locationpics/multi_photo_move', {
        project_id: this.project_id,
        project_type: this.project_type,
        locationpic_ids: this.checkedPhotos,
        new_section_header_group: this.new_section_header_group,
        new_starting_position: this.new_starting_position,
      })
        .then(() => {
          this.showLoadingModal = false;
          this.successModalMessage = 'Photos moved successfully!';
          this.errorModalMessage = '';
          window.location.reload();
        })
        .catch(() => {
          this.showLoadingModal = false;
          this.errorModalMessage = 'An error occured. Please reach out to admin@sitelynx.net for support.';
          this.successModalMessage = '';
        });
    },
    closeModal() {
      this.showMainModal = false;
    },
    getCheckedPhotos() {
      const checkedPhotos = [];
      $jq('.selected-checkbox').each((index, element) => {
        if (element.checked) {
          const firstParent = element.parentElement;
          const secondParent = firstParent ? firstParent.parentElement : null; // Check if firstParent is not null
          const int_order_display = secondParent.querySelector('.int_order_display');
          const pl_img_description = secondParent.querySelector('.pl_img_description');
          const photo_data = {
            id: element.getAttribute('value'),
            order: int_order_display.textContent,
            description: pl_img_description.value,
          };
          checkedPhotos.push(photo_data);
        }
      });
      this.checkedPhotos = checkedPhotos;
    },
    getSectionNames() {
      const sections = [];
      let photo_count = 0;
      $jq('.group_name_header').each((i, section_element) => {
        const sectionName = section_element.getAttribute('data-header-name');
        const groupId = section_element.getAttribute('data-group-id');
        const photo_data_list = [];

        $jq(`#${groupId}`).children().each((j, photo_element) => {
          if (photo_element.classList.contains('sl-tile-placeholder')) {
            return;
          }
          const int_order_display = photo_element.querySelector('.int_order_display');
          const pl_img_description = photo_element.querySelector('.pl_img_description');
          const photo_data = {
            id: photo_element.getAttribute('data-photo-id'),
            order: int_order_display.textContent,
            description: pl_img_description.value,
          };
          photo_count++;
          photo_data_list.push(photo_data);
        });

        sections.push({
          name: sectionName,
          group_id: groupId.replace('sortable_', ''),
          photo_data_list,
        });
      });
      this.sections = sections;
      this.max_position = photo_count;
    },
    getNewSectionHeader(event) {
      const value = parseInt(event.target.value, 10);
      if (value < 1) {
        this.new_starting_position = 1;
      } else if (value > this.max_position) {
        this.new_starting_position = this.max_position;
      }
      this.sections.forEach((section) => {
        section.photo_data_list.forEach((photo) => {
          if (photo.order === this.new_starting_position.toString()) {
            this.new_section_header = section.name;
            this.new_section_header_group = section.group_id;
          }
        });
      });
    },
  },
};
</script>
