<template>
  <div v-if="limits_of_liability > 0" class="sl-mt-20px">
    <span @click="showModal" class="sl-custom-link">Terms & Conditions</span>
  </div>

  <!-- Display Terms and Conditions Modal -->
  <div v-if="showMainModal" id="sl-history-log-modal-container" style="display:flex">
    <div class="sl-terms-cond-modal-container">
      <div class='sl-terms-cond-header'>
        <div id="sl-terms-cond-title">TERMS AND CONDITIONS</div>
        <xIcon @click="closeModal" id="sl-terms-cond-close" />
      </div>
      <div class="sl-terms-cond-content-text sl-terms-cond-modal-content">
        <div v-for="doc in fragment_documents" v-html="doc"></div>
        <h1 class="sl-underline-text sl-text-align-center">TERMS AND CONDITIONS</h1>
        <div v-for="text in terms_and_conditions_text()">
          <p>{{ text }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import xIcon from '../../../../assets/images/icons/Icon-Clear_Charcoal-Dark-100_20.svg';

export default {
  props: {
    service_estimates_and_selections_data: Object,
  },

  components: {
    xIcon,
  },

  data() {
    return {
      showMainModal: false,
      limits_of_liability: null,
      currency: null,
      currency_string: null,
      fragment_documents: null,
    };
  },

  async created() {
    await axios.get('/project_orders/return_terms_and_conditions_data')
      .then((response) => {
        this.limits_of_liability = response.data.limits_of_liability;
        this.currency = response.data.currency;
        this.currency_string = response.data.currency_string;
      }).catch(() => {
        alert('Something went wrong. Contact admin@sitelynx.net for support.');
      });
    this.fetchFragmentDocuments();
  },

  methods: {
    fetchFragmentDocuments() {
      axios.post('/project_orders/update_fragment_documents', {
        service_estimates_and_selections_data: this.service_estimates_and_selections_data,
      })
        .then((response) => {
          this.fragment_documents = response.data.fragment_documents;
        }).catch(() => {
          alert('Something went wrong. Contact admin@sitelynx.net for support.');
        });
    },
    showModal() {
      this.showMainModal = true;
    },
    closeModal() {
      this.showMainModal = false;
    },
    /* eslint-disable max-len */
    terms_and_conditions_text() {
      return [
        'A. ENTIRE AGREEMENT. These Terms and Conditions, in combination with those documents incorporating them by reference, constitute the entire agreement (the “Agreement”) between Partner Assessment Corporation, Inc. (“Partner”) and Client to perform the described services (the “Services”), and all subsequent, mutually agreed change orders. This Agreement supersedes all prior proposals or negotiations between the parties with respect to the subject Services. These terms and conditions will also apply to any contract or purchase order document issued by the Client for future services, whether or not it is expressly incorporated. In the event of any conflict between these terms and conditions and the provisions of any purchase order or other document, these terms and conditions shall control unless the conflicting document expressly supersedes specific provisions hereof. This Agreement may not be modified except in a writing executed by both parties.',
        'B. SERVICES. The Services will be performed in material compliance with the provisions of the Agreement. Client has reviewed the Agreement in detail and agrees that the Services are appropriate to meet Client’s needs and requirements.  Unless specified elsewhere in the Agreement, Partner shall furnish all technical and professional services, including labor, material, supplies, equipment, transportation, accommodation, subsistence and supervision of Partner personnel, to perform the Services. Client acknowledges that Partner may utilize third-party service providers in the performance of its obligations hereunder. Partner shall at all times be an independent contractor and no persons involved in connection herewith shall be considered employees of the Client for any purpose.',
        'C. FEES, INVOICING AND PAYMENT. Charges for all services shall be invoiced and paid in accordance with the Lump Sum Price or the Fee Schedule contained in the Agreement. Unless otherwise specified, invoices will be submitted to Client at time of report delivery. Payment is due upon receipt of the invoice and Client agrees that payments shall be made within thirty (30) days of presentation of the invoice. Should cancellation of the project occur after a verbal report of the findings has been provided, Client agrees to make a payment of 80% of the total cost due at the time of cancellation.  Amounts not paid within thirty (30) days shall be subject to a late payment charge equal to the lesser of one and one-half percent (1½%) per month or the maximum amount allowed by applicable law. Should any invoice be in dispute, only that portion of the invoice in dispute may be held in abeyance until the dispute is resolved. Partner may suspend or terminate further performance under this or other agreements with Client upon reasonable notice for the non-payment of invoices. Partner shall have no responsibility or liability in connection with the Services, and Client shall have no right to rely on any report or other materials delivered, until all invoices have been paid in full.  Partner shall be entitled to all legal fees, including but not limited to attorney’s fees associated with any attempt to collect on unpaid invoices associated with the Services.',
        'D. CONFIDENTIALITY. Information which the Client identifies as confidential upon provision to Partner and which is not publicly available will be treated as confidential in accordance with industry customs or standards. However, Partner shall have no liability to Client or any third party for disclosure of confidential information in notifications or reports which reveal potential issues related to the health, safety or welfare of the general public; subject to court order or other mandate; and/or further subject to public policy considerations. Partner shall have no liability to Client or any third party for notifications or reports made in accordance with such laws or orders and Client shall defend, indemnify and hold harmless Partner from and against any and all claims, demands, liabilities, costs and expenses, including reasonable attorney’s fees, incurred by Partner in connection with such notifications or reports.',
        `E. LIMITATION OF LIABILITY. NEITHER PARTY WILL BE LIABLE UNDER THIS AGREEMENT FOR ANY INDIRECT, INCIDENTAL, EXEMPLARY, PUNITIVE, SPECIAL OR CONSEQUENTIAL DAMAGES INCLUDING, WITHOUT LIMITATION, ANY LOSS OF INCOME, PROFITS OR DATA OR DIMINUTION OF VALUE, EVEN IF THE OTHER PARTY HAS BEEN ADVISED OF, OR HAD REASON TO KNOW OF, THE POSSIBILITY OF SUCH DAMAGES. CLIENT AND PARTNER AGREE THAT PARTNER’S AGGREGATE LIABILITY TO THE CLIENT AND ALL THIRD PARTIES IN CONNECTION WITH THE SERVICES SHALL BE LIMITED TO GENERAL MONEY DAMAGES NOT TO EXCEED ${this.currency_string} DOLLARS (${this.currency}). THIS LIMITATION SHALL APPLY REGARDLESS OF THE CAUSE OF ACTION OR LEGAL THEORY PLED OR ASSERTED. THE PARTIES ACKNOWLEDGE THAT THEY HAVE CONSIDERED THE ALLOCATION OF RISK PRESENTED BY THE PROVISIONS OF THIS PARAGRAPH AND THAT THE ALLOCATION IS REASONABLE UNDER ALL FACTS AND CIRCUMSTANCES SURROUNDING THE AGREEMENT.`,
        'F. WARRANTY. Partner warrants that the Services will be performed in a good and workmanlike manner in accordance with prevailing standards and practices applicable to the Services. PARTNER EXPRESSLY DISCLAIMS ANY AND ALL OTHER WARRANTIES, EXPRESS OR IMPLIED, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.',
        'G. SAMPLING. Client recognizes and agrees that Partner is acting as a bailee and at no time assumes title to samples collected in completion of the work and acknowledges that the act of sampling may affect, alter or damage the property, terrain, vegetation, and/or building, structures and improvements at, in or upon the site. Client accepts such risk. Partner will exercise reasonable efforts to limit such alteration or damage. Unless otherwise specifically agreed, Partner will not be responsible for the cost of any required repair or restoration. Partner may discard any and all samples immediately following analysis. Client may request, in writing, that any such samples be retained beyond such date, and in such case, Partner will ship such samples to the location designated by Client, at Client’s expense. Partner may, upon written request, arrange for storage of samples at mutually agreed storage charges. This is the only notice of intention to discard samples that will be given.',
        'H. RELIANCE. No party shall be allowed to use or rely on any report(s) or information generated in the completion of this project until payment in full is made to Partner for any outstanding invoices related to the Services rendered.  Client understands that Services governed by this Agreement are strictly for their sole use and benefit.  The parties expressly agree that no third party, including, but not limited to, any heirs, devisees, representatives, successors, assigns, affiliates, and subsidiaries of the parties or any partnership, corporation or other entity controlled by the parties or which control the parties, may rely on or raise any claim relating to the Services or this Agreement.',
        'I.  INDEMNIFICATION.  In the event that Client permits a third-party to rely upon this Agreement, the Services, and/or reports provided by Partner in breach of Paragraph H of this Agreement and said third-party files a claim or lawsuit against Partner relating to this Agreement, the Services, and/or reports provided by Partner, Client agrees to indemnify and hold Partner harmless from any damages, losses, liabilities, and costs, including, but not limited to, reasonable attorney fees, costs, and expert witness fees, arising from any such third-party claim or lawsuit.',
        'J.  NON-SOLICITATION.  Client shall not hire nor solicit for employment any of Partner’s employees for a period of one (1) year subsequent to any services rendered by Partner for Client.  In the event of Client’s breach of this covenant, Partner may seek any and all remedies including but not limited to an injunction; attorney fees and costs to enforce this provision; and monetary damages based upon the employee’s previous annual salary and all costs related to training.',
        'K.  TERMINATION. This Agreement may only be terminated prior to substantial completion of the services by Partner, by mutual consent, or by notice by either party in writing. In the event of termination prior to completion of the Services (i) by Client for any reason, or (ii) by Partner due to the breach of this Agreement by Client, Partner reserves the right to complete such analyses and records as are necessary to place its files in order and, where considered by Partner as necessary to protect its professional reputation, to complete a report on Services performed to date. Partner shall have the right to receive a reasonable termination charge to cover such costs and to be compensated for all Services performed prior to and in connection with such termination. In the event of termination, client shall have no rights of use or reliance upon the work.  Paragraphs A, C, D, E, F, I, J, K, L, M, N, O, P, Q and R shall survive termination.',
        'L. RESOLUTION OF DISPUTES. This Agreement shall be governed by, subject to, and construed in accordance with the laws of the state of California, without regard to its conflict of law provisions or the conflict of law provisions of any other jurisdiction.   With the exception of disputes arising from failure to pay any invoices or fees for services rendered, any claim, controversy, or action arising out of, or related to, this Agreement or the alleged breach thereof, shall be submitted to mandatory non-binding mediation through a third-party mediator to be agreed upon by the Partner and the moving party.  A “Notice of Mediation” shall be served by any party to commence the mediation process.  The service of the Notice of Mediation shall stay the running of the limitations period set forth in Paragraph M herein for a period of 60 days unless a longer or shorter period of time is agreed to by the parties.  In the event that the parties cannot reach a resolution through mediation, the parties may proceed to litigate their claims in Court.  Any litigation so instituted shall be filed and litigated in the State Court of California, County of Los Angeles in accordance with the laws of the State of California, and subject to Paragraph L herein.  With the exception of disputes arising from failure to pay invoices or fees for services rendered, each party shall pay the fees of its own attorneys, and the expenses of its witnesses and all other expenses connected with the presentation of its case.',
        'M.  LIMITATIONS PERIOD.  Neither party shall initiate any claim or action against the other more than eighteen (18) months after the date from which all final reports for services performed by Partner are submitted by Partner to Client.  The parties further expressly agree that this Limitations Period is reasonable, unambiguous and shall not be subject to modification or the delayed discovery rule unless expressly agreed to by the parties in writing.',
        'N. CORPORATE PROTECTION. It is intended by the parties to this Agreement that Partner’s services in connection with the project shall not subject Partner’s employees, officers, or directors to any personal legal exposure for risks associated with this project.  Therefore, the Client agrees that as Client’s sole and exclusive remedy, any claim, demand or action shall be directed and/or asserted only against Partner, a California corporation, and not against any of Partner’s employees, officers, or directors.',
        'O. NO WAIVER. The failure of either party to exercise any right or remedy hereunder or to take any action permitted on a breach by the other party shall not be deemed a waiver of such right or remedy or of any other rights or subsequent breach of a like or different nature.',
        'P.  SEVERABILITY.  The provisions of this Agreement are severable. The invalidity of any part of this Agreement shall not invalidate the remainder of the Agreement or the remainder of any portion hereof.',
        'Q.  RETENTION OF PARTNER’S DOCUMENTS.  With the exception of Partner submittals to Client, all documents, including, without limitation, historical research, field data, field notes, laboratory test data, calculations and analyses prepared as instruments of service shall remain the property of Partner. Partner will retain all pertinent records relating to the services performed for a period of eighteen months following the completion of Partner’s services. After this period, they may be discarded.',
        'R.  NO ASSIGNMENT.  Neither party shall assign their rights under this Agreement to any third party, including, but not limited to, any heirs, devisees, representatives, successors, assigns, affiliates, and subsidiaries of the parties or any partnership, corporation or other entity controlled by the parties or which control the parties without the prior written consent of the other, which consent shall not be unreasonably withheld or delayed. Each party, and the person executing on behalf of such party, represent and warrant that such person has the full power and authority to bind the party represented.',
      ];
    },
  },
};
</script>

<style scoped>
.sl-custom-link {
  text-decoration: underline;
  cursor: pointer;
}
</style>
