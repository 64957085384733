<template>
    <span @click="fetchTableData" class="sl-simple-outline-btn">
       Reorder
    </span>
    <div v-if='showModal' id="sl-history-log-modal-container">
     <div class="sl-history-log-modal-content" >
        <div class='sl-close-div'>
            <CloseIcon @click="showModal = false" id="sl-regenerate-close" class='figma-icon sl-mb-10px' style="float:right"/>
        </div>
        <div style="color:red">{{ this.errorMessage }}</div>
        <table class="sl-simple-table-1">
            <thead>
                <tr>
                    <th></th>
                    <th>Order</th>
                    <th>Data</th>
                </tr>
            </thead>
            <tbody id='pl_table_body'>
                <tr v-for='row in pcaDefaults' valign='top' :id=row.id>
                  <td class='sl-cursor-pointer sortable-handle'>
                    <Handle/>
                  </td>
                  <td class='sl-order-number'>{{ row.order_number }}</td>
                  <td> {{ row.the_data }}</td>
                </tr>
            </tbody>
        </table>
        <button @click="saveNewSortOrder" class="sl-simple-outline-btn" style="float:right">Save</button>
     </div>
    </div>
</template>

<script>
import axios from 'axios';
import Sortable from 'sortablejs';
import CloseIcon from '../../../assets/images/icons/Icon-Close_Charcoal-Dark-100_24.svg';
import Handle from '../../../assets/images/icons/handle.svg';

export default {
  components: {
    CloseIcon,
    Handle,
  },
  data() {
    return {
      showModal: false,
      pcaDefaults: [],
      errorMessage: '',
    };
  },
  methods: {
    async fetchTableData() {
      // get field id from url
      const url = window.location.href.replace('/edit', '');
      const field_id = url.substring(url.lastIndexOf('/') + 1);
      await axios.get('/pca_defaults/pca_defaults_by_root_field', {
        params: {
          field_id,
        },
      })
        .then((response) => {
          this.pcaDefaults = response.data.pca_defaults;
          this.showModal = true;
          setTimeout(() => {
            this.setSortable();
          }, 100);
        })
        .catch(() => {
          this.errorMessage = 'An error has occurred';
        });
    },
    async saveNewSortOrder() {
      await axios.post('/pca_defaults/update_order_number', {
        pca_defaults: this.pcaDefaults,
      })
        .then(() => {
          this.showModal = false;
          // refresh the page
          window.location.reload();
        })
        .catch(() => {
          this.errorMessage = 'An error has occurred';
        });
    },
    setSortable() {
      const el = document.getElementById('pl_table_body');
      Sortable.create(el, {
        handle: '.sortable-handle',
        animation: 150,
        ghostClass: 'sortable-ghost',
        onEnd: () => {
          this.updateSortOrder();
        },
      });
    },
    // Loop through elements in the table and update the order_number field
    updateSortOrder() {
      const el = document.getElementById('pl_table_body');
      let index = 1;
      for (let row of el.children) { // eslint-disable-line no-restricted-syntax, prefer-const
        const htmlRow = this.pcaDefaults.find((r) => r.id == row.id); // eslint-disable-line eqeqeq
        if (htmlRow) {
          htmlRow.order_number = index;
          index++;
          // add red color to the order number if it has changed
          const order_number_rows = document.getElementsByClassName('sl-order-number');
          for (let i = 0; i < order_number_rows.length; i++) {
            order_number_rows[i].classList.add('order-changed');
          }
        }
      }
    },
  },
};
</script>

<style>
.sortable-ghost {
    opacity: 0.4;
  }
  .order-changed {
    color: red;
  }
</style>
