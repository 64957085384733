<template>
  <div class='sl-mb-10px sl-flex-justify-content-space-btwn' style='color: #005199'>
    <span class='sl-font-size-16px sl-bold-text'>PCA Equity Cost Import</span>
  </div>
  <br>
  <div v-if='dropzoneView' class='sl-flex-justify-content-center sl-mtb-20px'>
    <div>
      <p class='sl-font-size-14px'>
        Upload your CSV or input your data directly below.
      </p>
      <p  class='sl-font-size-14px'>
        CSVs must have been created using the Partner template file.
      </p>
      <p  class='sl-font-size-14px'>
        Click <a href='/pca_equity_costs/excel_template_download'>here</a> to download the template.
      </p>
      <p  class='sl-font-size-14px sl-bold-text'>
        * Caution: This will overwrite all existing repair components.
      </p>
    </div>
  </div>
  <ExcelDropzone v-if='dropzoneView' @upload-complete='uploadComplete'
    :formUrl="'/pca_equity_costs/import_excel'"
    :title="'Upload your PCA Equity Costs'"
  />
  <div v-else>
    <br>
    <br>
    <div class='sl-bold-text sl-mt-20px sl-w-100per sl-text-align-center' style='color:#005199;'>
      <p class='pl-2-column-area'>Upload Successful!</p>
      <p class='sl-font-size-16px'>Redirecting...</p>
    </div>
    <br>
    <br>
  </div>
</template>
<script>
import ExcelDropzone from '../../components/ExcelDropzone.vue';

export default {
  components: {
    ExcelDropzone,
  },

  data() {
    return {
      dropzoneView: true,
    };
  },

  methods: {
    showDropzone() {
      this.dropzoneView = true;
    },

    uploadComplete() {
      this.dropzoneView = false;
      setTimeout(() => {
        window.location.href = '/pca_cost_factors';
      }, 2000);
    },
  },
};
</script>
