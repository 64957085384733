<template>
  <SpinningModal v-if="showLoadingModal"/>
  <div class='sl-pt-10px'>
    <div class='sl-flex-justify-content-center'>
      <form :action=formUrl data-remote='true'
            @mouseover="changeHoverState(true)"
            @mouseleave="changeHoverState(false)"
            id='pl_dropzone_excel_file'
            class='dropzone dz-container'
            enctype='multipart/form-data'>
        <div style='padding-right: 140px'>
          <div class='nd_dz_message_container dz-message'>
            <div class='nd_dz_top sl-ml-80px'>
              <excelIcon :class="iconClass"/>
              <div class='nd_dz_upload_message'>
                <span class='sl-white-space-no-wrap'>{{ title }}</span>
                <p class='nd_dropzone_paragraph sl-w-300px'>Click here to browse or drag and drop files here.</p>
              </div>
            </div>
            <div class='sl-w-375px sl-ml-80px'>
              <div class='nd_photos_horizontal_line'></div>
              <p class='nd_dropzone_paragraph nd_dz_bottom'>
                Files must have been created using the Partner template file.
              </p>
            </div>
          </div>
        </div>
      </form>
    </div>
    <button @click="submit" id='pl_dropzone_submit_btn' class='sl-simple-outline-btn sl-h-28px'
            :class="{'submit_btn_not_ready sl-simple-outline-btn-disabled':!uploadActive}">
            Upload File
    </button>
    <div id='dropzone-container'>
      <div class='dropzone-template'>
        <div class='dz-remove sl-flex-justify-content-and-align-items-center' data-dz-remove>
          <xIcon transform='scale(0.8)' class='figma-icon'/>
        </div>
        <div class='dropzone-text'>
            <span class='dropzone-filename' data-dz-name></span>
            &nbsp;&nbsp;
            |
            &nbsp;&nbsp;
            <span class='otherstuff' data-dz-size></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import excelIcon from '../../../assets/images/icons/Icon-Xcel-Doc_Charcoal-Dark-100_24.svg';
import xIcon from '../../../assets/images/icons/Icon-Clear_Charcoal-Dark-100_20.svg';
import SpinningModal from './SpinningModal.vue';

export default {
  props: ['formUrl', 'title', 'maxFiles', 'parallelUploads'],

  components: {
    SpinningModal,
    excelIcon,
    xIcon,
  },

  data() {
    return {
      uploadActive: false,
      showLoadingModal: false,
      excelDropzone: null,
      newDropzone: null,
      isHovering: false,
    };
  },

  async mounted() {
    this.createDropZone();
  },

  methods: {
    createDropZone() {
      this.excelDropzone = document.getElementById('pl_dropzone_excel_file');
      if (this.excelDropzone) {
        const templateShell = document.getElementsByClassName('dropzone-template')[0];
        const template = templateShell.parentNode.innerHTML;
        templateShell.parentNode.remove();

        this.newDropzone = new Dropzone('#pl_dropzone_excel_file', { // eslint-disable-line no-undef
          autoProcessQueue: false,
          maxFilesize: 100,
          maxFiles: this.maxFiles || 1,
          acceptedFiles: '.xlsx, .csv',
          thumbnailWidth: 100,
          thumbnailHeight: 100,
          thumbnailMethod: 'crop',
          previewTemplate: template,
          parallelUploads: this.parallelUploads || 1,
        });

        // Remove uploaded file from dropzone after successful upload.
        this.newDropzone.on('success', (file) => {
          this.newDropzone.removeFile(file);
        });

        this.newDropzone.on('error', () => {
          this.$emit('uploadError');
        });

        // Make the upload button active once a file is uploaded.
        this.newDropzone.on('addedfile', () => {
          this.uploadActive = true;
        });

        // Make the upload button inactive if a file is removed.
        this.newDropzone.on('removedfile', () => {
          this.uploadActive = false;
        });

        // Alert only one file is allowed to be uploaded and remove the
        // second file.
        this.newDropzone.on('maxfilesexceeded', (file) => {
          alert('Only one file can be imported.');
          this.newDropzone.removeFile(file);
          this.uploadActive = true;
        });

        this.excelDropzone.style.display = 'block';

        // Reload the page after successful upload.
        this.newDropzone.on('queuecomplete', () => {
          this.$emit('uploadComplete');
          this.showLoadingModal = false;
        });
      }
    },

    // Submit action
    submit() {
      this.showLoadingModal = true;
      this.newDropzone.processQueue();
    },

    // Change the hover state of the dropzone
    changeHoverState(hover) {
      this.isHovering = hover;
    },
  },
  computed: {
    iconClass() {
      return this.isHovering ? 'figma-icon-blue nd_dropzone_photo_image' : 'figma-icon-charcoal nd_dropzone_photo_image';
    },
  },
};
</script>
