<template>
  <table class='sl-esg-decarbonization-table sl-border-r-1px-medium-charcoal' id='sl-esg-calculations-fixed-column'>
    <thead>
      <tr class='sl-table__row sl-table__row--esg'>
        <td><span></span></td>
      </tr>
    </thead>
    <tbody>
      <!-- Baseline Energy Use -->
      <tr class='sl-table__row sl-table__row--esg sl-h-42px sl-esg-calculations-table-header-row'
          :class="{ 'no-border':!showBaselineEnergyUse }"
          id='sl-esg-calculations-table-first-header-row'>
        <td>
          <span @click="$emit('toggleBaselineEnergyUse')" class='links-center'>
            <span v-if='showBaselineEnergyUse' style='font-size: 30px;'>-</span>
            <span v-else style='font-size: 20px;'>+</span>
          </span>
          <span class="sl-ml-10px">BASELINE ENERGY USE</span>
        </td>
      </tr>
      <div :class="{ 'sl-hide-content':!showBaselineEnergyUse }">
        <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
          <td>Baseline Electric (kWh)</td>
        </tr>
        <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
          <td>Baseline {{ secondaryFuelType.display_name }} ({{ secondaryFuelType.unit_of_measure }})</td>
        </tr>
        <tr class='sl-table__row sl-table__row--esg sl-h-42px sl-border-t-2px-medium-charcoal'>
          <td>Baseline Electricity Cost</td>
        </tr>
        <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
          <td>Baseline {{ secondaryFuelType.display_name }} Cost</td>
        </tr>
        <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
          <td>Baseline Total Cost</td>
        </tr>
        <tr class='sl-table__row sl-table__row--esg sl-h-42px sl-border-t-2px-medium-charcoal'>
          <td>Baseline Electric (kBtu)</td>
        </tr>
        <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
          <td>Baseline {{ secondaryFuelType.display_name }} (kBtu)</td>
        </tr>
        <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
          <td>Baseline Total Energy (kBtu)</td>
        </tr>
        <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
          <td>Baseline Site EUI</td>
        </tr>
      </div>

      <!-- Energy Efficiency Measures -->
      <tr class='sl-table__row sl-table__row--esg sl-h-42px sl-esg-calculations-table-header-row'
          :class="{ 'no-border':!showEnergyEfficiency }"
          v-if="!isEmpty(nonRenewableMeasures)">
        <td>
          <span @click="$emit('toggleEnergyEfficiency')" class='links-center'>
            <span v-if='showEnergyEfficiency' style='font-size: 30px;'>-</span>
            <span v-else style='font-size: 20px;'>+</span>
          </span>
          <span class="sl-ml-10px">ENERGY EFFICIENCY (EE)</span>
        </td>
      </tr>
      <div :class="{ 'sl-hide-content':!showEnergyEfficiency }">
        <div v-for="measure in nonRenewableMeasures">
          <tr class='sl-table__row sl-table__row--esg sl-h-37px sl-esg-table-sub-header-row'>
            <td class='sl-esg-measure-description'
                @mouseenter='showFullDescription(measure.id)'
                @mouseleave='hideFullDescription(measure.id)'>
              <span @click='toggleDisplay(measure.id)' class='sl-mr-5px links-center'>
                <span v-if='measure.display' style='font-size: 30px;'>-</span>
                <span v-else style='font-size: 20px;'>+</span>
              </span>
              <span>EE {{ measure.index_number }} - {{ measure.description }}</span>
              <div v-if='measure.showFullDescription' class='sl-esg-full-description'>
                {{ measure.description }}
              </div>
            </td>
          </tr>
          <div v-if='measure.display'>
            <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
              <td>Electric (kWh)</td>
            </tr>
            <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
              <td>{{ secondaryFuelType.display_name }} ({{ secondaryFuelType.unit_of_measure }})</td>
            </tr>
            <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
              <td>Cost</td>
            </tr>
            <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
              <td>Cost Savings</td>
            </tr>
          </div>
        </div>
      </div>

      <!-- Building Energy Use After EE Measures -->
      <tr class='sl-table__row sl-table__row--esg sl-h-42px sl-esg-calculations-table-header-row'
          :class="{ 'no-border':!showBuildingEnergyUseAfterEE }"
          v-if="!isEmpty(nonRenewableMeasures)">
        <td>
          <span @click="$emit('toggleBuildingEnergyUseAfterEe')" class='links-center'>
            <span v-if='showBuildingEnergyUseAfterEE' style='font-size: 30px;'>-</span>
            <span v-else style='font-size: 20px;'>+</span>
          </span>
          <span class="sl-ml-10px">BUILDING ENERGY USE AFTER EE</span>
        </td>
      </tr>
      <div v-if="!isEmpty(nonRenewableMeasures)" :class="{ 'sl-hide-content':!showBuildingEnergyUseAfterEE }">
        <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
          <td>After EE Electric (kWh)</td>
        </tr>
        <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
          <td>After EE {{ secondaryFuelType.display_name }} ({{ secondaryFuelType.unit_of_measure }})</td>
        </tr>
      </div>

      <!-- Renewable Measures -->
      <tr class='sl-table__row sl-table__row--esg sl-h-42px sl-esg-calculations-table-header-row'
          :class="{ 'no-border':!showRenewableEnergy }"
          v-if="!isEmpty(renewableMeasures)">
        <td>
          <span @click="$emit('toggleRenewableEnergy')" class='links-center'>
            <span v-if='showRenewableEnergy' style='font-size: 30px;'>-</span>
            <span v-else style='font-size: 20px;'>+</span>
          </span>
          <span class="sl-ml-10px">RENEWABLE ENERGY (RE)</span>
        </td>
      </tr>
      <div :class="{ 'sl-hide-content':!showRenewableEnergy }">
        <div v-for="measure in renewableMeasures">
          <tr class='sl-table__row sl-table__row--esg sl-h-42px sl-esg-table-sub-header-row'>
            <td class='sl-esg-measure-description'
                @mouseenter='showFullDescription(measure.id)'
                @mouseleave='hideFullDescription(measure.id)'>
              <span @click='toggleDisplay(measure.id)' class='sl-bold-text sl-mr-5px links-center'>
                <span v-if='measure.display' style='font-size: 30px;'>-</span>
                <span v-else style='font-size: 20px;'>+</span>
              </span>
              <span>EE {{ measure.index_number }} - {{ measure.description }}</span>
              <div v-if='measure.showFullDescription' class='sl-esg-full-description'>
                {{ measure.description }}
              </div>
            </td>
          </tr>
          <div v-if='measure.display'>
            <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
              <td>Electric (kWh)</td>
            </tr>
            <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
              <td>Cost</td>
            </tr>
            <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
              <td>Cost Savings</td>
            </tr>
          </div>
        </div>
      </div>

      <!-- Building Energy Use after Renewable Energy Measures -->
      <tr class='sl-table__row sl-table__row--esg sl-h-42px sl-esg-calculations-table-header-row'
          :class="{ 'no-border':!showBuildingEnergyUseAfterRE }"
          v-if="!isEmpty(renewableMeasures)">
        <td>
          <span @click="$emit('toggleBuildingEnergyUseAfterRe')" class='links-center'>
            <span v-if='showBuildingEnergyUseAfterRE' style='font-size: 30px;'>-</span>
            <span v-else style='font-size: 20px;'>+</span>
          </span>
          <span class="sl-ml-10px">BUILDING ENERGY USE AFTER RE</span>
        </td>
      </tr>
      <div v-if="!isEmpty(renewableMeasures)" :class="{'sl-hide-content':!showBuildingEnergyUseAfterRE}">
        <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
          <td>After RE Electric (kWh)</td>
        </tr>
        <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
          <td>After RE {{ secondaryFuelType.display_name }} ({{ secondaryFuelType.unit_of_measure }})</td>
        </tr>
      </div>

      <!-- Final Building Energy Use -->
      <tr class='sl-table__row sl-table__row--esg sl-h-42px sl-esg-calculations-table-header-row'>
        <td>
          <span @click="$emit('toggleFinalBuildingEnergy')" class='links-center'>
            <span v-if='showFinalBuildingEnergy' style='font-size: 30px;'>-</span>
            <span v-else style='font-size: 20px;'>+</span>
          </span>
          <span class="sl-ml-10px">FINAL BUILDING ENERGY USE</span>
        </td>
      </tr>
      <div :class="{ 'sl-hide-content':!showFinalBuildingEnergy }">
        <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
          <td>Final Building Electric (kBtu)</td>
        </tr>
        <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
          <td>Final Building {{ secondaryFuelType.display_name }} (kBtu)</td>
        </tr>
        <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
          <td>Final Building Total Energy (kBtu)</td>
        </tr>
        <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
          <td>Final Building Site EUI</td>
        </tr>
        <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
          <td>Final Building Percent Savings</td>
        </tr>
        <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
          <td>Carbon Emissions</td>
        </tr>
        <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
          <td>Carbon Emissions Without Measures</td>
        </tr>
      </div>
    </tbody>
  </table>
</template>
<script>
export default {
  props: ['baselineMetric', 'secondaryFuelType', 'nonRenewableMeasures', 'renewableMeasures', 'allMeasures',
    'showBaselineEnergyUse', 'showEnergyEfficiency', 'showBuildingEnergyUseAfterEE',
    'showRenewableEnergy', 'showBuildingEnergyUseAfterRE', 'showFinalBuildingEnergy'],

  methods: {
    isEmpty(obj) {
      return Object.keys(obj).length === 0;
    },

    // Toggle the display of the measure between true and false. This allows the measure to be expanded or collapsed.
    toggleDisplay(measure_id) {
      Object.keys(this.allMeasures).forEach((key) => {
        const obj = this.allMeasures[key];
        if (obj.id === measure_id) {
          obj.display = !obj.display;
        }
      });
    },

    // When hovering over the measure description, show the full description in a tooltip.
    showFullDescription(measure_id) {
      Object.keys(this.allMeasures).forEach((key) => {
        const obj = this.allMeasures[key];
        if (obj.id === measure_id) {
          obj.hover = true;
          setTimeout(() => {
            if (obj.display) {
              obj.showFullDescription = obj.hover;
            }
          }, 1500);
        }
      });
    },

    // When the mouse leaves the measure description, hide the full description tooltip.
    hideFullDescription(measure_id) {
      Object.keys(this.allMeasures).forEach((key) => {
        const obj = this.allMeasures[key];
        if (obj.id === measure_id) {
          obj.hover = false;
          obj.showFullDescription = false;
        }
      });
    },
  },
};
</script>
<style scoped>
  .no-border {
    border-bottom: none;
  }
</style>
