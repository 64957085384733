<template>
  <div id="sl-history-log-modal-container" style="display: flex">
    <div class="sl-history-log-modal-content">
      <CloseIcon class='figma-icon' @click="$emit('closeModal')" id='sl-history-log-close'/>
      <table class="sl-version-history-table">
        <tr>
          <th></th>
          <th>File Name</th>
          <th>Uploaded By</th>
          <th>Upload Date</th>
          <th>Timestamp</th>
          <th></th>
          <th></th>
        </tr>
        <tr v-for="doc in all_working_docs">
          <td>
          <a v-bind:href="'/projectdocs/' + doc.id" class='file-options'>
            <p>{{ doc.icon_file }}</p>
            <xpdf v-if='doc.is_pdf' class="sl-svg-icon-size-4 sl-charcoal-filter figma-icon" />
            <document v-else class="sl-svg-icon-size-4 sl-charcoal-filter figma-icon" />
          </a>
          </td>
            <td :title = "doc.manual_upload ? 'Manually Uploaded' : 'Bluelynx Generated'">
              {{ doc.doc_file_name }}
            </td>
            <td> {{doc.user_name}}</td>
            <td> {{ doc.upload_date }}</td>
            <td>{{doc.timestamp}}</td>
            <td title="Download">
              <a v-bind:href="'/projectdocs/' + doc.id" class='file-options'>
                View File
              </a>
            </td>
            <td>
              <span v-if="!doc.deprecated">
                Current Version
              </span>
              <a href="#" v-else @click="make_current_version(doc.project_id, doc.name, doc.id, doc.description)">Make Current Version</a>
            </td>
          </tr>
      </table>
    </div>
  </div>
  </template>

<script>
import axios from 'axios';
import document from '../../../assets/images/icons/Icon-Single-Doc_Charcoal-Dark-100_24.svg';
import xpdf from '../../../assets/images/icons/Icon-PDF-Doc_Charcoal-Dark-100_24.svg';
import CloseIcon from '../../../assets/images/icons/Icon-Close_Charcoal-Dark-100_24.svg';

export default {
  components: {
    document,
    xpdf,
    CloseIcon,
  },
  props: {
    all_working_docs: Array,
    project_type: String,
  },

  data() {
    return {
    };
  },
  methods: {
    make_current_version(project_id, name, id, description) {
      axios
        .get('/report_datas/update_current_version', {
          params: {
            project_id,
            name,
            doc_id: id,
            description,
            project_type: this.project_type,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            window.location.reload();
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
  },
};
</script>
