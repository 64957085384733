<template>
  <!-- There are conditionals to disable some of the drawing settings depending on the selected tool -->
  <div v-if="['POINTER', 'MARKER'].includes(drawingMode)" class='sl-w-245px'>
    <div class='disabled-setting'>
      <div class='color-label'>Stroke Color</div>
      <div class='sl-flex-only sl-mb-10px sl-ml-10px'>
        <SiteMapColorButton v-for='color in colors'
          :color='color'
          :current_color='strokeColor'
          :disabled='true'
        />
      </div>
    </div>
    <div class='disabled-setting'>
      <div class='color-label'>Fill Color</div>
      <div class='sl-flex-only sl-mb-20px sl-ml-10px'>
        <SiteMapColorButton v-for='color in colors'
          :color='color'
          :current_color='fillColor'
          :disabled='true'
        />
      </div>
    </div>
    <div class='sl-border-t-1px sl-h-150px sl-flex-align-items-center sl-w-100per'>
      <div class='disabled-setting sl-flex-column sl-flex-align-items-center sl-w-100per'>
        <label class='slider-label'>Stroke Weight</label>
        <input v-model='strokeWeight' disabled
               type='range' step='0.1' min='0' max='20' class='slider' id='stroke_weight'/>

        <label class='slider-label'>Stroke Opacity</label>
        <input v-model='strokeOpacity' disabled
                type='range' step='0.01' min='0' max='1' class='slider' id='stroke_opacity'/>

        <label class='slider-label'>Fill Opacity</label>
        <input v-model='fillOpacity' disabled
                type='range' step='0.01' min='0' max='1' class='slider' id='fill_opacity'/>
      </div>
    </div>
  </div>
  <div v-else-if="drawingMode==='POLYLINE'" class='sl-w-245px'>
    <div>
      <div class='color-label'>Stroke Color</div>
      <div class='sl-flex-only sl-mb-10px sl-ml-10px'>
        <SiteMapColorButton v-for='color in colors'
          :color='color'
          :current_color='strokeColor'
          @set-color='setStrokeColor'
        />
      </div>
    </div>
    <div class='disabled-setting'>
      <div class='color-label'>Fill Color</div>
      <div class='sl-flex-only sl-mb-20px sl-ml-10px'>
        <SiteMapColorButton v-for='color in colors'
          :color='color'
          :current_color='fillColor'
          :disabled='true'
        />
      </div>
    </div>
    <div class='sl-border-t-1px sl-h-150px sl-flex-align-items-center sl-w-100per'>
      <div class='sl-flex-column sl-flex-align-items-center sl-w-100per'>
        <label class='slider-label'>Stroke Weight</label>
        <input v-model='strokeWeight' @change='setStrokeWeight'
               type='range' step='0.1' min='0' max='20' class='slider' id='stroke_weight'/>

        <label class='slider-label'>Stroke Opacity</label>
        <input v-model='strokeOpacity' @change='setStrokeOpacity'
               type='range' step='0.01' min='0' max='1' class='slider' id='stroke_opacity'/>

        <label class='slider-label'>Fill Opacity</label>
        <input v-model='fillOpacity' disabled
               type='range' step='0.01' min='0' max='1' class='disabled-setting slider' id='fill_opacity'/>
      </div>
    </div>
  </div>
  <div v-else-if="['RECTANGLE', 'CIRCLE'].includes(drawingMode)" class='sl-w-245px'>
    <div>
      <div class='color-label'>Stroke Color</div>
      <div class='sl-flex-only sl-mb-10px sl-ml-10px'>
        <SiteMapColorButton v-for='color in colors'
          :color='color'
          :current_color='strokeColor'
          @set-color='setStrokeColor(color)'
        />
      </div>
    </div>
    <div>
      <div class='color-label'>Fill Color</div>
      <div class='sl-flex-only sl-mb-20px sl-ml-10px'>
        <SiteMapColorButton v-for='color in colors'
          :color='color'
          :current_color='fillColor'
          @set-color='setFillColor(color)'
        />
      </div>
    </div>
    <div class='sl-border-t-1px sl-h-150px sl-flex-align-items-center sl-w-100per'>
      <div class='sl-flex-column sl-flex-align-items-center sl-w-100per'>
        <label class='slider-label'>Stroke Weight</label>
        <input v-model='strokeWeight' @change='setStrokeWeight'
               type='range' step='0.1' min='0' max='20' class='slider' id='stroke_weight'/>

        <label class='slider-label'>Stroke Opacity</label>
        <input v-model='strokeOpacity' @change='setStrokeOpacity'
               type='range' step='0.01' min='0' max='1' class='slider' id='stroke_opacity'/>

        <label class='slider-label'>Fill Opacity</label>
        <input v-model='fillOpacity' @change='setFillOpacity'
               type='range' step='0.01' min='0' max='1' class='slider' id='fill_opacity'
        />
      </div>
    </div>
  </div>
  <div v-else-if="drawingMode==='TEXT'" class='sl-w-245px'>
    <div class='disabled-setting'>
      <div class='color-label'>Stroke Color</div>
      <div class='sl-flex-only sl-mb-10px sl-ml-10px'>
        <SiteMapColorButton v-for='color in colors'
          :color='color'
          :current_color='strokeColor'
          :disabled='true'
        />
      </div>
    </div>
    <div class='disabled-setting'>
      <div class='color-label'>Fill Color</div>
      <div class='sl-flex-only sl-mb-20px sl-ml-10px'>
        <SiteMapColorButton v-for='color in colors'
          :color='color'
          :current_color='fillColor'
          :disabled='true'
        />
      </div>
    </div>
    <div class='sl-border-t-1px sl-h-150px sl-flex-align-items-center sl-w-100per'>
      <div class='sl-mt-20px'>
        <textarea @input='addTextToActiveObject()'
                  v-model='textInput'
                  id='text_input'
                  style='resize: none'
                  placeholder='Add text here...'
                  class='map-texarea sl-paragraph-med'>
        </textarea>
      </div>
    </div>
  </div>
</template>
<script>
import SiteMapColorButton from './SiteMapColorButton.vue';

export default {
  components: {
    SiteMapColorButton,
  },

  props: ['drawingMode', 'activeText'],

  data() {
    return {
      colors: ['#005199', '#569900', '#9F9EA4', '#1F7A8C', '#FF3333', '#F04C25', '#000000'],
      strokeColor: '#005199',
      fillColor: '#005199',
      strokeWeight: 10,
      strokeOpacity: 1.0,
      fillOpacity: 0.1,
      textInput: '',
    };
  },

  created() {
    // When a user clicks on a map object, set the text input to the map object's text if it is a comment box
    // One second delay to ensure the map object container is created
    setTimeout(() => {
      const container = document.getElementById('map-object-container');
      if (container) {
        container.addEventListener('click', () => {
          this.textInput = this.activeText;
        });
      }
    }, 1000);
  },

  methods: {
    setStrokeColor(color) {
      this.strokeColor = color;
      this.$emit('set-stroke-color', color);
    },

    setFillColor(color) {
      this.fillColor = color;
      this.$emit('set-fill-color', color);
    },

    setStrokeWeight() {
      this.$emit('set-stroke-weight', this.strokeWeight);
    },

    setStrokeOpacity() {
      this.$emit('set-stroke-opacity', this.strokeOpacity);
    },

    setFillOpacity() {
      this.$emit('set-fill-opacity', this.fillOpacity);
    },

    addTextToActiveObject() {
      this.$emit('add-text-to-object', this.textInput);
    },
  },
};
</script>
<style scoped>
  .disabled-setting {
    opacity: 0.3 !important;
    pointer-events: none;
  }

  .color-label {
    color: #9F9EA4;
    margin: 0 0 5px 10px;
  }

  .slider-label {
    color: #9F9EA4;
    width: 225px;
    margin: 10px 0 5px 0px;
  }

  .slider {
    -webkit-appearance: none;
    width: 225px;
    height: 8px;
    margin-bottom: 5px;
    border-radius: 5px;
    background: #c1bfbf;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #014581;
    cursor: pointer;
  }

  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #005199;
    cursor: pointer;
  }

  .map-texarea {
    width: 205px;
    margin: 0 0 5px 10px;
    padding: 0 5px;
    height: 110px;
    border: 2px solid var(--medium-charcoal);
  }
</style>
