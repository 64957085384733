<template>
  <div id='sl-history-log-modal-container' class='sl-flex-only'>
    <div class='sl-history-log-modal-content' style='max-width: 600px;'>
      <div class='sl-flex-justify-content-space-btwn'>
        <div class='sl-w-750px sl-font-size-16px sl-mt-10px'>{{ `${project.project_number_string} - ${project.name}` }}</div>
        <div class='sl-w-100px sl-mb-20px sl-flex-justify-content-end'>
          <xIcon @click="$emit('closeModal')" id='sl-history-log-close'/>
        </div>
      </div>
      <div class='sl-border-t-2px-primary-blue sl-ptb-20px'>
        <div class='sl-bold-text sl-font-size-14px'>Project Data Import</div>
        <div class='sl-font-size-14px sl-mt-10px'>Choose an existing project to import data from:</div>
        <div class='sl-flex-justify-content-space-btwn sl-flex-align-items-center'>
          <div class='sl-border-1px-medium-charcoal sl-w-300px sl-h-32px sl-mt-10px sl-plr-10px
                      sl-flex-align-items-center sl-flex-justify-content-space-btwn'>
            <a @click='toggleshowSearchInput' class='sl-cursor-pointer'>
              {{ selectedProject?.name || 'Search for a project' }}
            </a>
            <xIcon v-if='selectedProject' @click='clear' transform="scale(0.5)" class='sl-cursor-pointer'/>
          </div>
          <a v-if='scheduledImport' @click.prevent='removeScheduldedImport' class='sl-bold-text' href='/'>Remove Scheduled Import</a>
        </div>
        <ul v-if='showSearchInput' class='sl-border-1px-medium-charcoal'
            style='background-color: white;
                   width: 320px;'>
          <input type='text'
                 id='search-input'
                 class='sl-w-275px sl-h-28px sl-pl-10px sl-ml-10px sl-mtb-5px'
                 style='display: block; border: 2px solid black; border-radius: 4px;;'
                 @input='search($event)'/>
          <li v-if='projects.length' v-for='project in projects' @click='selectProject(project)' class='search-option'>{{ project.name }}</li>
          <div v-else style='color: #9F9EA4' class='sl-mb-5px'>
            <span class='sl-ml-10px'>{{ searchboxText }}</span>
          </div>
        </ul>
      </div>
      <div class='sl-font-size-14px sl-mb-20px' :class="{'disabled-font':!selectedProject}">Choose data to import:</div>
      <label for='import_baseline_metric' :class="{'disabled-font':!selectedProject}" class='sl-custom-checkbox'>
        <sapn class='sl-font-size-14px'>Baseline Metrics</sapn>
        <input v-model='importBaselineData'
               id='import_baseline_metric' type='checkbox'
               :disabled='!selectedProject'>
        <span class='checkmark'></span>
      </label>
      <br>
      <label for='import_ewems' :class="{'disabled-font':!selectedProject}" class='sl-custom-checkbox'>
        <sapn class='sl-font-size-14px'>Energy and Water Conservative Measures</sapn>
        <input v-model='importEwemData'
               id='import_ewems' type='checkbox'
               :disabled='!selectedProject'>
        <span class='checkmark'></span>
      </label>
      <br>
      <label for='import_pathways' :class="{'disabled-font':!selectedProject}" class='sl-custom-checkbox'>
        <sapn class='sl-font-size-14px'>Custom Pathways</sapn>
        <input v-model='importPathwayData'
               id='import_pathways' type='checkbox'
               :disabled='!selectedProject'>
        <span class='checkmark'></span>
      </label>
      <br>
      <label for='scheduled_import' :class="{'disabled-font':!selectedProject}" class='sl-custom-checkbox'>
        <sapn class='sl-font-size-14px'>Scheduled Import</sapn>
        <input v-model='scheduledImport'
               id='scheduled_import' type='checkbox'
               :disabled='!selectedProject'>
        <span class='checkmark'></span>
      </label>
      <br>
      <div class='sl-flex-justify-content-center'>
        <button @click='importData()'
                :class="{'sl-simple-outline-btn-disabled':disableImportBtn()}"
                class='sl-simple-outline-btn sl-w-100px'>
                Import
        </button>
      </div>
      <div class='sl-border-b-2px sl-w-100per sl-mt-20px sl-mb-40px'></div>
      <span class='sl-bold-text sl-font-size-14px'>Excel Import</span>
      <div class='sl-font-size-14px sl-mt-10px'>Choose an Excel file formated for BlueLynx import:</div>
      <div class='sl-mt-10px sl-mb-20px'>
        <form @submit.prevent='importExcelData'>
          <div class='sl-border-1px-medium-charcoal sl-flex-align-items-center
                      sl-w-300px sl-h-32px sl-plr-10px sl-mb-40px'>
            <input type='file' name='file' accept='.xlsx'
                   id='uploadDataFile'
                   ref='uploadDataFile'
                   @change='setFile'/>
          </div>
          <div class='sl-flex-justify-content-center'>
            <button :class="{'sl-simple-outline-btn-disabled':!file}"
                    class='sl-simple-outline-btn sl-w-100px'>
                    Import
            </button>
          </div>
        </form>
      </div>
      <div class='sl-border-b-2px sl-w-100per sl-mt-20px sl-mb-20px'></div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import xIcon from '../../../../../assets/images/icons/Icon-Close_Charcoal-Dark-100_24.svg';

export default {
  props: ['project', 'baselineMetric', 'scheduledImportProject'],
  components: {
    xIcon,
  },

  data() {
    return {
      projects: [],
      selectedProject: null,
      importBaselineData: false,
      importEwemData: false,
      importPathwayData: false,
      scheduledImport: false,
      showSearchInput: false,
      searchboxText: 'Please enter 1 or more characters',
      file: null,
    };
  },

  mounted() {
    if (this.scheduledImportProject) {
      this.selectedProject = this.scheduledImportProject;
      this.scheduledImport = true;
    }
  },

  methods: {
    importData() {
      let message = '';
      if (this.scheduledImport) {
        message = `Scheduled imports will reoccur every 24 hours and import baseline metrics, energy measures, and custom pathways from ${this.selectedProject.name || 'the selected project'} into ${this.project.name || 'the current project'}. Are you sure you want to proceed?`; // eslint-disable-line max-len
      } else {
        message = 'Caution: This will overwrite existing data for this project. Are you sure you want to continue?';
      }
      if (window.confirm(message)) {
        const importParams = {
          id: this.selectedProject.id,
          baseline_metric: this.importBaselineData,
          ewems: this.importEwemData,
          pathways: this.importPathwayData,
          scheduled_import: this.scheduledImport,
        };
        this.$emit('importData', importParams);
      }
    },

    importExcelData() {
      const message = 'Caution: This will overwrite existing data for this project. Are you sure you want to continue?';
      if (!window.confirm(message)) { return; }
      this.$emit('importExcelData', this.file);
    },

    // If a project is not selected and one of the checkboxes is not selected, disable import button
    disableImportBtn() {
      if (this.selectedProject && (this.importBaselineData || this.importEwemData || this.importPathwayData || this.scheduledImport)) {
        return false;
      }
      return true;
    },

    async search(event) {
      const search = event.target.value;
      if (search.length) {
        this.searchboxText = 'Loading...';
      } else {
        this.searchboxText = 'Please enter 1 or more characters';
      }
      if (!search.length) {
        this.projects = [];
        return;
      }
      await axios.get('/baseline_metrics/search', {
        params: {
          search: event.target.value,
        },
      })
        .then((response) => {
          this.projects = response.data.projects;
          if (!this.projects.length) {
            this.searchboxText = 'No projects found';
          }
        });
    },

    clear() {
      this.showSearchInput = false;
      this.selectedProject = null;
      this.importBaselineData = false;
      this.importEwemData = false;
      this.importPathwayData = false;
      this.scheduledImport = false;
      this.projects = [];
      this.searchboxText = 'Please enter 1 or more characters';
    },

    selectProject(project) {
      this.showSearchInput = false;
      this.selectedProject = project;
      this.projects = [];
      this.searchboxText = 'Please enter 1 or more characters';
    },

    toggleshowSearchInput() {
      this.showSearchInput = !this.showSearchInput;
      setTimeout(() => {
        document.getElementById('search-input').focus();
      }, 0);
    },

    async removeScheduldedImport() {
      await axios.post('/baseline_metrics/remove_scheduled_import', {
        id: this.baselineMetric.id,
      })
        .then(() => {
          this.scheduledImport = false;
          this.selectedProject = null;
          this.$emit('removeScheduledImport');
        });
    },

    setFile() {
      const file = this.$refs.uploadDataFile.files[0];
      this.file = file;
    },
  },
};
</script>
<style scoped>
  .search-option {
    padding: 5px 10px;
  }

  .search-option:hover {
    cursor: pointer;
    background-color: #005199;
    color: white;
  }
</style>
