<template>
  <h2>Order Form Services</h2>

  <p>
    These are the selectable services on the order form for all clients.
  </p>

  <p>
    To add or update Category names, go here: <a href="/category_types" target="_blank">Categories</a>
  </p>

  <p>
    To add or update Department names, go here: <a href="/department_types" target="_blank">Departments</a>
  </p>

  <p>
    To add or update Sitelynx/PLINK2 service names, go here: <a href="/workscopes" target="_blank">Services</a>
  </p>

  <br>
  <div>
    <button class="sl-simple-outline-btn sl-mr-10px"
            @click="set_master_or_client_view('client')"
            :disabled="currently_viewing == 'client'">View Current Client Service List</button>

    <button v-if="userIsDbAdmin"
            class="sl-simple-outline-btn"
            @click="set_master_or_client_view('master')"
            :disabled="currently_viewing == 'master'">View Master Service List</button>
  </div>

  <hr>
  <br>

  <h2>
    Currently Viewing:
    <span v-if="currently_viewing == 'master'">Master Order Form Service List</span>
    <span v-else>Client Specific Order Form Service List - "{{ current_client_name }}"</span>
  </h2>

  <div class="sl-p-all-10px" style="border: 1px solid grey">
    <strong>
      Create new order form service for
      <span v-if="currently_viewing == 'master'">"Master"</span>
      <span v-else>Client: "{{ current_client_name }}"</span>
    </strong>

    <form @submit="create_new_record" class="sl-mt-20px">
      <div class="sl-flex-only sl-mtb-20px">
        <div class="sl-mr-10px">
          <div>Category</div>
          <select v-model="this.order_form_service.category_type_id" :required="true">
            <option disabled value="">Choose a Selection</option>
            <option v-for="item in this.category_types" :key="item.id" :value="item.id">{{ item.name }}</option>
          </select>
        </div>

        <div class="sl-mr-10px">
          <div>Department</div>
          <select v-model="this.order_form_service.department_type_id" :required="true">
            <option disabled value="">Choose a Selection</option>
            <option v-for="item in this.department_types" :key="item.id" :value="item.id">{{ item.name }}</option>
          </select>
        </div>

        <div class="sl-mr-10px">
          <div>PLINK2 Service Name</div>
          <select v-model="this.order_form_service.workscope_id" :required="true" @change="set_sitelynx_name">
            <option disabled value="">Choose a Selection</option>
            <option v-for="item in this.workscopes" :key="item.id" :value="item.id">{{ item.plink_name }}</option>
          </select>
          <span class="sl-ml-10px">
            Sitelynx Name: {{ sitelynx_name }}
          </span>
        </div>
      </div>

      <div class="sl-mt-10px">
        <button class='sl-simple-outline-btn' type="submit">create</button>
      </div>
    </form>
  </div>

  <div class="sl-mt-20px" v-if="currently_viewing === 'client' && currently_viewing_services.length === 0">
    <p class='sl-paragraph-med'>
      No order form services have been added for this client: "{{ current_client_name }}"".
      So the order form currently defaults to using the master service list.
    </p>
    <p class='sl-paragraph-med'>Once at least 1 order form service is added, this client will start using the client specific service list.</p>
    <p class='sl-paragraph-med'>Click the button below to create a copy from the Master List</p>
    <button class='sl-simple-outline-btn' @click="copy_order_form_services">Copy From The Master List</button>
  </div>

  <div v-if='currently_viewing_services.length > 0'>
    <table class='sl-simple-table-1'>
      <thead>
        <tr>
          <th></th>
          <th>Unqiue ID</th>
          <th></th>
          <th>Category</th>
          <th>Department</th>
          <th>PLINK2 Service Name</th>
          <th>Sitelynx Service Name</th>
          <th>Custom Display Name <br>(If left blank, the PLINK2 name will appear <br> on the order form services list)</th>
          <th>Display By Default <br>(If left blank, the service will only appear <br> in the additional services pop-ip)</th>
          <th>Flat Fee <br>(If Selected, only the "Other" 4th Column <br>will appear on Step 4 of Order Form)</th>
          <th>Base Fee</th>
          <th></th>
        </tr>
      </thead>

      <tbody>
        <tr v-for='orderFormService in currently_viewing_services' :key='orderFormService.id'>
          <td>
            <a :href="`order_form_services/${orderFormService.id}/edit`">
              <editIcon class='figma-icon sl-mt-10px'/>
            </a>
          </td>
          <td>
            <span>{{ orderFormService.id }}</span>
          </td>
          <td>
            <span v-if='orderFormService.duplicate'>Possible duplicate<br>found</span>
          </td>
          <td>
            <select v-model='orderFormService.category_type_id'
                    @change="update_record(orderFormService.id, {category_type_id: orderFormService.category_type_id})">
              <option v-for='category in category_types' :key='category.id' :value='category.id'>
                {{ category.name }}
              </option>
            </select>
          </td>
          <td>
            <select v-model='orderFormService.department_type_id'
                    @change="update_record(orderFormService.id, {department_type_id: orderFormService.department_type_id})">
              <option v-for='department in department_types' :key='department.id' :value='department.id'>
                {{ department.name }}
              </option>
            </select>
          </td>

          <td style="text-align: initial;">
            <select v-model='orderFormService.workscope_id'
                    @change="update_record(orderFormService.id, {workscope_id: orderFormService.workscope_id})">
              <option v-for='workscope in workscopes' :key='workscope.id' :value='workscope.id'>
                {{ workscope.plink_name }}
              </option>
            </select>
          </td>

          <td style="text-align: initial;">{{ orderFormService.workscope_name }}</td>

          <td>
            <textarea style="width: 300px; height: 40px;"
                      v-model='orderFormService.name'
                      @change="update_record(orderFormService.id, {name: orderFormService.name})">
            </textarea>
          </td>
          <td>
            <input type='checkbox'
                   v-model='orderFormService.default'
                   @change="update_record(orderFormService.id, {default: orderFormService.default})">
          </td>
          <td>
            <input type='checkbox'
                   v-model='orderFormService.flat_fee'
                   @change="update_record(orderFormService.id, {flat_fee: orderFormService.flat_fee})">
          </td>

          <td>
            <input v-model='orderFormService.base_price'
                   @change="update_record(orderFormService.id, {base_price: orderFormService.base_price})"/>
          </td>

          <td>
            <button class='sl-simple-outline-btn-warning' @click="delete_record(orderFormService)">Delete</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';
import editIcon from '../../../../assets/images/icons/Icon-Edit_Charcoal-Dark-100_20.svg';

export default {
  components: {
    editIcon,
  },

  data() {
    return {
      master_order_form_services: [],
      client_order_form_services: [],
      department_types: [],
      category_types: [],
      workscopes: [],
      client_scopes: [],
      order_form_service: {},
      sitelynx_name: '',
      currently_viewing_services: [],
      currently_viewing: 'client',
      current_client_name: '',
      current_client_id: '',
      userIsDbAdmin: false,
    };
  },

  async created() {
    await axios.get('/order_form_services/return_order_form_services')
      .then((response) => {
        this.master_order_form_services = response.data.master_order_form_services;
        this.client_order_form_services = response.data.client_order_form_services;
        this.current_client_id = response.data.current_client_id;
        this.current_client_name = response.data.current_client_name;
        this.department_types = response.data.department_types;
        this.category_types = response.data.category_types;
        this.workscopes = response.data.workscopes;
        this.client_scopes = response.data.client_scopes;
        this.userIsDbAdmin = response.data.user_is_db_admin;
        this.set_service_list();
      })
      .catch((error) => {
        alert(`Error: ${error}`);
      });
  },

  methods: {
    copy_order_form_services() {
      // show prompt to confirm
      if (!window.confirm('Are you sure you want to copy the master list to the current client? This will reset the current client list.')) {
        return;
      }

      axios.post('/order_form_services/copy_order_form_services')
        .then((response) => {
          this.master_order_form_services = response.data.master_order_form_services;
          this.client_order_form_services = response.data.client_order_form_services;
          this.set_service_list();
        })
        .catch((error) => {
          alert(`Error: ${error}`);
        });
    },

    // Set view to master list or client specific list
    set_master_or_client_view(view) {
      if (view === 'master') {
        this.currently_viewing = 'master';
      } else {
        this.currently_viewing = 'client';
      }

      this.set_service_list();
    },

    set_service_list() {
      if (this.currently_viewing === 'master') {
        this.currently_viewing_services = this.master_order_form_services;
      } else {
        this.currently_viewing_services = this.client_order_form_services;
      }
    },

    create_new_record(e) {
      e.preventDefault();
      const currentObj = this;
      let client_specific = false;

      // If user is viewing client specific list,
      // set client_specific to true so the new record is created under the client, instead of master
      if (currentObj.currently_viewing === 'client') {
        client_specific = true;
      }

      axios.post('/order_form_services', {
        order_form_service: currentObj.order_form_service,
        client_specific,
      })
        .then((response) => {
          this.master_order_form_services = response.data.master_order_form_services;
          this.client_order_form_services = response.data.client_order_form_services;
          this.order_form_service = {};
          this.sitelynx_name = '';

          this.set_service_list();

          // update element id sc_alert with response message
          const alert = document.getElementById('sc_alert');
          alert.textContent = response.data.status;
        })
        .catch((error) => {
          alert(`Error: ${error}`);
        });
    },

    update_record(id, params) {
      axios.put(`/order_form_services/${id}`, {
        order_form_service: params,
      })
        .then((response) => {
          // update element id sc_alert with response message
          const alert = document.getElementById('sc_alert');
          alert.textContent = response.data.status;
        })
        .catch((error) => {
          alert(`Error: ${error}`);
        });
    },

    delete_record(record) {
      const costs = record.client_scope_records_count;

      if (window.confirm(
        `
          Are you sure you want to delete this record?
          There are ${costs} connections to client scope records.
          Deleting this record will remove all connections.
          `,
      )) {
        axios.delete(`/order_form_services/${record.id}`)
          .then((response) => {
            this.master_order_form_services = response.data.master_order_form_services;
            this.client_order_form_services = response.data.client_order_form_services;

            this.set_service_list();

            // update element id sc_alert with response message
            const alert = document.getElementById('sc_alert');
            alert.textContent = response.data.status;
          })
          .catch((error) => {
            alert(`Error: ${error}`);
          });
      }
    },

    // For new records, set sitelynx name based on workscope on change
    set_sitelynx_name(event) {
      // get sitelynx name from workscopes
      this.sitelynx_name = (this.workscopes.find((workscope) => workscope.id === Number(event.target.value))).name;
    },

    // Get and set the default fee for a client scope
    get_client_scope_default_fee(id) {
      let default_fee = null;
      this.client_scopes.forEach((scope) => {
        if (scope[0].order_form_service_id === id) {
          default_fee = scope[0].default_fee;
        }
      });
      return default_fee;
    },

    // Update the base price from the client scope default fee
    set_base_price(id, base_price) {
      const default_fee = this.get_client_scope_default_fee(id);
      if (default_fee !== null) {
        const price = base_price === null ? default_fee : null;
        this.update_record(id, { base_price: price });
      } else {
        alert('No client scopes found for this service');
      }
    },
  },
};
</script>
