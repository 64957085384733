<template>
  <div class="sl-mb-20px sl-flex-justify-content-space-btwn" style="color: #005199">
    <span v-if="this.version" class="sl-font-size-16px sl-bold-text">Version: {{ version.name }}</span>
  </div>
  <br>
  <br>
  <div v-if="dropzoneView" class="sl-flex-justify-content-center sl-mt-20px">
    <div>
      <p class="sl-font-size-14px">
        Upload your CSV or input your data directly below.
      </p>
      <p  class="sl-font-size-14px">
        CSVs must have been created using the Partner template file.
      </p>
      <p  class="sl-font-size-14px">
        Click <a href="/pca_cost_factor_versions/excel_template_download">here</a> to download the template.
      </p>
    </div>
  </div>
  <ExcelDropzone v-if="dropzoneView"
    @upload-complete="uploadComplete"
    :formUrl="`/pca_cost_factor_versions/import_excel/?id=${id}`"
    :title="'Upload your PCA Cost Factors'"
  />
  <div v-else>
    <br>
    <br>
    <div class="sl-bold-text sl-mt-20px sl-w-100per sl-text-align-center" style="color:#005199;">
      <p class="pl-2-column-area">Upload Successful!</p>
      <p class="sl-font-size-16px">Redirecting back to all versions...</p>
    </div>
    <br>
    <br>
  </div>
</template>
<script>
import axios from 'axios';
import ExcelDropzone from '../../components/ExcelDropzone.vue';

export default {
  components: {
    ExcelDropzone,
  },

  data() {
    const url = window.location.pathname;
    return {
      // Get the id of the record from the url
      id: url.substring(url.lastIndexOf('/') + 1),
      version: null,
      dropzoneView: true,
    };
  },

  async created() {
    this.fetchCostFactorVersion();
  },

  methods: {
    async fetchCostFactorVersion() {
      await axios.get('/pca_cost_factor_versions/vue_show', {
        params: {
          id: this.id,
        },
      })
        .then((response) => {
          this.version = response.data.version;
        })
        .catch((error) => {
          console.log(error); // eslint-disable-line no-console
        });
    },

    // After upload, redirect to pca cost factor versions index page
    uploadComplete() {
      this.dropzoneView = false;
      setTimeout(() => {
        window.location.href = '/pca_cost_factor_versions';
      }, 2000);
    },
  },
};
</script>
