<template>
  <a @click="getPlinkProjectNotes" class="sl-blue-link pl-circle-edit-btn sl-tooltip">
      <editIcon class="figma-icon"/>
      <span class="sl-tooltiptext">Edit</span>
  </a>
  <div v-if='showEditModal' id='sl-history-log-modal-container' class='sl-flex-only'>
    <div class='sl-history-log-modal-content' style='max-width: 600px;'>
      <div class='sl-close-div sl-mb-10px'>
        <CloseIcon @click="toggleModal(false)" id="sl-regenerate-close" class='figma-icon'/>
      </div>
      <textarea v-model="plinkProjectNotes" id='sl-edit-notes-textarea' placeholder='Add notes here...'></textarea>
      <div class='sl-flex-justify-content-center'>
        <button class='sl-simple-outline-btn sl-w-100px' @click="saveNotes">Save</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import editIcon from '../../../assets/images/icons/Icon-Edit_Charcoal-Dark-100_20.svg';
import CloseIcon from '../../../assets/images/icons/Icon-Close_Charcoal-Dark-100_24.svg';

export default {
  components: {
    editIcon,
    CloseIcon,
  },
  data() {
    return {
      showEditModal: false,
      plinkProjectNotes: '',
      projectId: null,
    };
  },
  methods: {
    toggleModal(open) {
      this.showEditModal = open;
    },
    async getPlinkProjectNotes() {
      // get project id from url
      const url = window.location.href;
      const project_id = url.substring(url.lastIndexOf('/') + 1);
      this.projectId = project_id;
      await axios.get('/projects/plink_project_notes', {
        params: {
          project_id,
        },
      })
        .then((response) => {
          this.plinkProjectNotes = response.data.plink_project_notes;
          this.toggleModal(true);
        })
        .catch(() => {
          this.showErrorMessage('An error has occurred');
        });
    },
    async saveNotes() {
      await axios.post('/projects/save_plink_project_notes', {
        project_id: this.projectId,
        plink_project_notes: this.plinkProjectNotes,
      })
        .then(() => {
          this.toggleModal(false);
          // show alert
          window.alert('Notes saved');
          // refresh window
          window.location.reload();
        })
        .catch(() => {
          this.showErrorMessage('An error has occurred');
        });
    },
  },
};
</script>

<style scoped lang="css">
#sl-edit-notes-textarea {
  width: 100%;
  height:70%;
  resize: none;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid var(--medium-charcoal);
  box-sizing: border-box;
}
#sl-regenerate-close {
  font-size: 30px !important;
  float: right;
  cursor: pointer;
}
.sl-close-div {
  display: flex;
  justify-content: flex-end;
}
</style>
