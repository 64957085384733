<template>
  <div class='clr-border' :class="{'clr-btn-active':color===current_color}">
    <button @click='setColor' :style="{backgroundColor: color}" class='clr-btn'></button>
  </div>
</template>

<script>
export default {
  props: ['color', 'current_color', 'disabled'],
  methods: {
    setColor() {
      if (this.disabled) return;
      this.$emit('setColor', this.color);
    },
  },
};
</script>
<style scoped>
  .clr-btn {
    border: 0;
    width: 21px;
    height: 21px;
    cursor: pointer;
  }
  .clr-btn-active {
    border: 2px solid #CCDCEB !important;
  }
  .clr-border {
    border: 2px solid white;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
