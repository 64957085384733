<template>
  <SpinningModal v-if='showLoadingModal'/>
  <div id='sl-history-log-modal-container' class='sl-flex-only'>
    <div class='sl-history-log-modal-content' style='max-width: 600px;'>
      <div class='sl-flex-justify-content-center' style='color: red'></div>
      <div class='sl-flex-justify-content-space-btwn'>
        <div class='sl-flex-column sl-w-750px'>
          <span class='pl-2-column-area sl-mb-10px'></span>
          <span class='sl-font-size-14px'></span>
        </div>
        <div class='sl-w-100px sl-mb-20px sl-flex-justify-content-end'>
          <CloseIcon class='figma-icon' @click='closeEditModal' id='sl-history-log-close'/>
        </div>
      </div>
      <form @submit.prevent='save()'>
        <table class='sl-w-100per sl-border-tb-2px-primary-blue'>
          <tr class='sl-table__row--striped sl-h-42px'>
            <td class='sl-bold-text sl-pl-10px sl-w-150px'>Repair Component</td>
            <td class='sl-plr-10px'>
              <input v-model='editCost.repair_component' required
                    type='text' id='repair_component'
                    class='sl-w-400px sl-border-1px-medium-charcoal sl-h-28px sl-pl-5px'/>
            </td>
          </tr>
          <tr class='sl-table__row--striped sl-h-42px'>
            <td class='sl-bold-text sl-pl-10px'>Section</td>
            <td class='sl-plr-10px'>
              <select v-model='editCost.section_reference' required
                      id='section_reference'
                      class='sl-border-1px-medium-charcoal sl-h-32px' style='width: 408px'>
                <option v-for='subsection in subsections' :value='subsection.section_reference'>
                  {{ `${subsection.section_reference} ${subsection.header_string}` }}
                </option>
              </select>
            </td>
          </tr>
          <tr class='sl-table__row--striped sl-h-42px'>
            <td class='sl-bold-text sl-pl-10px'>Notes</td>
            <td class='sl-plr-10px'>
              <textarea v-model='editCost.notes'
                        id='notes'
                        class='sl-w-400px sl-border-1px-medium-charcoal sl-pl-5px'
                        style='font-family: Roboto;'>
              </textarea>
            </td>
          </tr>
          <tr class='sl-table__row--striped sl-h-42px'>
            <td class='sl-bold-text sl-pl-10px'>EUL</td>
            <td class='sl-plr-10px'>
              <input v-model='editCost.eul' required
                     type='text' id='eul'
                     class='sl-w-400px sl-border-1px-medium-charcoal sl-h-28px sl-pl-5px'/>
            </td>
          </tr>
          <tr class='sl-table__row--striped sl-h-42px'>
            <td class='sl-bold-text sl-pl-10px'>Cost Low</td>
            <td class='sl-plr-10px'>
              <input v-model='editCost.cost_low' required
                     type='text' step='0.01' id='cost_low'
                     class='sl-w-400px sl-border-1px-medium-charcoal sl-h-28px sl-pl-5px'
                     @change='formatCurrency($event)'/>
            </td>
          </tr>
          <tr class='sl-table__row--striped sl-h-42px'>
            <td class='sl-bold-text sl-pl-10px'>Cost High</td>
            <td class='sl-plr-10px'>
              <input v-model='editCost.cost_high' required
                     type='text' step='0.01' id='cost_high'
                     class='sl-w-400px sl-border-1px-medium-charcoal sl-h-28px sl-pl-5px'
                     @change='formatCurrency($event)'/>
            </td>
          </tr>
          <tr class='sl-table__row--striped sl-h-42px'>
            <td class='sl-bold-text sl-pl-10px'>Adjustment Category</td>
            <td class='sl-plr-10px'>
              <select v-model='editCost.adjustment_category' required
                      id='adjustment_category'
                      class='sl-border-1px-medium-charcoal sl-h-32px' style='width: 408px'>
                <option v-for='category in categories' :value='category'>{{ category }}</option>
              </select>
            </td>
          </tr>
          <tr class='sl-table__row--striped sl-h-42px'>
            <td class='sl-bold-text sl-pl-10px'>Comments</td>
            <td class='sl-plr-10px'>
              <textarea v-model='editCost.comments'
                        id='comments'
                        class='sl-w-400px sl-border-1px-medium-charcoal sl-pl-5px'
                        style='font-family: Roboto;'>
              </textarea>
            </td>
          </tr>
        </table>
        <div class='sl-flex-justify-content-center sl-mt-20px'>
          <button type='submit' class='sl-w-85px sl-simple-outline-btn sl-mr-10px'>Save</button>
          <button @click='destroy' class='sl-w-85px sl-simple-outline-btn-warning sl-ml-10px'>Delete</button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import CloseIcon from '../../../../assets/images/icons/Icon-Close_Charcoal-Dark-100_24.svg';
import SpinningModal from '../../components/SpinningModal.vue';

export default {
  props: ['closeEditModal', 'editCost', 'categories', 'subsections'],
  components: {
    SpinningModal,
    CloseIcon,
  },

  data() {
    return {
      showLoadingModal: false,
    };
  },

  methods: {
    // Save updates to a cost component
    async save() {
      this.showLoadingModal = true;
      this.formatNumbers();
      await axios.put(`/pca_equity_costs/${this.editCost.id}`, null, {
        params: {
          pca_equity_cost: this.editCost,
        },
      })
        .then(() => {
          this.showLoadingModal = false;
          this.$emit('editComplete');
        })
        .catch(() => {
          this.showLoadingModal = false;
          alert('Something went wrong, unable to save this record. Contact admin@sitelynx.net for support.');
        });
    },

    // Delete a single cost component
    async destroy() {
      if (window.confirm(`Are you sure you want to delete the ${this.editCost.repair_component} cost?`)) {
        this.showLoadingModal = true;
        await axios.delete(`/pca_equity_costs/${this.editCost.id}`)
          .then(() => {
            this.showLoadingModal = false;
            this.$emit('editComplete');
          })
          .catch(() => {
            this.showLoadingModal = false;
            alert('Something went wrong, unable to delete this record. Contact admin@sitelynx.net for support.');
          });
      }
    },

    // Format number as currency when user enters input
    formatCurrency(event) {
      const column = event.target.id;
      let { value } = event.target;
      value = value.replace(/[^\d.-]/g, ''); // Replace non-numerical characters
      this.editCost[column] = `$${Intl.NumberFormat().format(value)}`;
    },

    // Format currency string into number before sending to server
    formatNumbers() {
      // Replace non-numerical characters
      this.editCost.cost_low = this.editCost.cost_low.replace(/[^\d.-]/g, '');
      this.editCost.cost_high = this.editCost.cost_high.replace(/[^\d.-]/g, '');
    },
  },
};
</script>
