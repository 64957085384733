<template>
  <svg width="10" height="6" viewBox="0 0 30 17" fill="none" transform="rotate(180)" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1L15 15L29 1" stroke="#333E47" stroke-width="2" />
  </svg>
</template>

<script>
export default {
  name: 'UpArrowSmall',
};
</script>
