<template>
  <div class='sl-flex-justify-content-center sl-pb-30px'>
    <div class='sl-w-500px'>
      <strong class='report-table-instructions'>
        Unit Status Key:
      </strong>
      <ul class='instructions-list'>
        <div class='report-table-instructions sl-mb-5px'>
          <strong>V</strong>: Vacant
        </div>
        <div class='report-table-instructions sl-mb-5px'>
          <strong>O</strong>: Occupied
        </div>
        <div class='report-table-instructions sl-mb-5px'>
          <strong>M</strong>: Model
        </div>
        <div class='report-table-instructions sl-mb-5px'>
          <strong>D</strong>: Down
        </div>
      </ul>
    </div>
  </div>
</template>
