<template>
  <!-- Line 0 if there are report addressee contacts for this client -->
  <div v-if="this.report_addressee_contacts_dropdown.length > 0" class="sl-flex-justify-content-space-btwn sl-mb-14px">
    <div style="width: 100%">
      <div class="sl-para-small">Address Book</div>
      <select class="sl-input-type-1"
              name="purpose_of_order"
              v-model="selected_contact_id"
              @change="fill_in_contact_data">
        <option hidden disabled value="">Choose a Selection</option>
        <option v-for="contact in this.report_addressee_contacts_dropdown" :key="contact.id" :value="contact.id">
          {{ formatted_contact_name(contact) }}
        </option>
      </select>
    </div>
  </div>

  <!-- Line 1 -->
  <div class="sl-flex-justify-content-space-btwn sl-mb-14px">
    <div style="width: 33.33%" class="sl-mr-10px">
      <div class="sl-para-small">First name</div>
      <div>
        <input type="text" maxlength="50" class="sl-input-type-1" v-model="this.passed_in_order_form_data.report_addressee_data.first_name" />
      </div>
    </div>

    <div style="width: 33.33%" class="sl-mr-10px">
      <div class="sl-para-small" >Last Name</div>
      <div>
        <input type="text" maxlength="50" class="sl-input-type-1" v-model="this.passed_in_order_form_data.report_addressee_data.last_name" />
      </div>
    </div>

    <div style="width: 33.33%">
      <div class="sl-para-small">Title</div>
      <div>
        <input type="text" maxlength="50" class="sl-input-type-1" v-model="this.passed_in_order_form_data.report_addressee_data.title" />
      </div>
    </div>
  </div>

  <!-- Line 2 -->
  <div class="sl-flex-justify-content-space-btwn sl-mb-14px">
    <div style="width: 50%" class="sl-mr-10px">
      <div class="sl-para-small">Email</div>
      <div>
        <input type="text" maxlength="50" class="sl-input-type-1" v-model="this.passed_in_order_form_data.report_addressee_data.email" />
      </div>
    </div>

    <div style="width: 50%">
      <div class="sl-para-small">Company Name</div>
      <div>
        <input type="text" maxlength="50" class="sl-input-type-1" v-model="this.passed_in_order_form_data.report_addressee_data.company_name" />
      </div>
    </div>
  </div>

  <!-- Line 3 -->
  <div class="sl-flex-justify-content-space-btwn sl-mb-14px">
    <div style="width: 33.33%" class="sl-mr-10px">
      <div class="sl-para-small">Country</div>
      <div>
        <input type="text" maxlength="50" class="sl-input-type-1" v-model="this.passed_in_order_form_data.report_addressee_data.country" />
      </div>
    </div>

    <div style="width: 33.33%" class="sl-mr-10px">
      <div class="sl-para-small">Address 1</div>
      <div>
        <input type="text" maxlength="50" class="sl-input-type-1" v-model="this.passed_in_order_form_data.report_addressee_data.address" />
      </div>
    </div>

    <div style="width: 33.33%">
      <div class="sl-para-small">Address 2</div>
      <div>
        <input type="text" maxlength="50" class="sl-input-type-1" v-model="this.passed_in_order_form_data.report_addressee_data.address_2" />
      </div>
    </div>
  </div>

  <!-- Line 4 -->
  <div class="sl-flex-justify-content-space-btwn sl-mb-14px">
    <div style="width: 40%" class="sl-mr-10px">
      <div class="sl-para-small">City</div>
      <div>
        <input type="text" maxlength="50" class="sl-input-type-1" v-model="this.passed_in_order_form_data.report_addressee_data.city" />
      </div>
    </div>

    <div style="width: 40%" class="sl-mr-10px">
      <div class="sl-para-small">State</div>
      <div>
        <input type="text" maxlength="50" class="sl-input-type-1" v-model="this.passed_in_order_form_data.report_addressee_data.state" />
      </div>
    </div>

    <div style="width: 20%">
      <div class="sl-para-small">Zip</div>
      <div>
        <input type="text"
               maxlength="10"
               oninput="this.value = this.value.replace(/[^0-9-]/g, '')"
               class="sl-input-type-1"
               v-model="this.passed_in_order_form_data.report_addressee_data.zip" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    passed_in_order_form_data: {
      type: Object,
    },
    dropdown_selections: {
      type: Object,
    },
  },

  data() {
    return {
      report_addressee_contacts_dropdown: [],
      selected_contact_id: '',
    };
  },

  mounted() {
    this.report_addressee_contacts_dropdown = this.dropdown_selections.report_addressee_contacts;
  },

  methods: {
    formatted_contact_name(contact) {
      return `${contact.first_name} ${contact.last_name} ${contact.title ? `– ${contact.title}` : ''}`;
    },

    fill_in_contact_data() {
      const selected_contact = this.report_addressee_contacts_dropdown.find((contact) => contact.id === this.selected_contact_id);

      this.passed_in_order_form_data.report_addressee_data.first_name = selected_contact.first_name;
      this.passed_in_order_form_data.report_addressee_data.last_name = selected_contact.last_name;
      this.passed_in_order_form_data.report_addressee_data.title = selected_contact.title;
      this.passed_in_order_form_data.report_addressee_data.email = selected_contact.email;
      this.passed_in_order_form_data.report_addressee_data.company_name = selected_contact.company;
      this.passed_in_order_form_data.report_addressee_data.country = selected_contact.country;
      this.passed_in_order_form_data.report_addressee_data.address = selected_contact.address;
      this.passed_in_order_form_data.report_addressee_data.address_2 = selected_contact.address2;
      this.passed_in_order_form_data.report_addressee_data.city = selected_contact.city;
      this.passed_in_order_form_data.report_addressee_data.state = selected_contact.state;
      this.passed_in_order_form_data.report_addressee_data.zip = selected_contact.zip;
    },
  },
};
</script>
