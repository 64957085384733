<template>
  <SpinningModal v-if='showLoadingModal' :modalLoadingText="'Initiating portfolio import...'"/>
  <div class='sl-mt-10px sl-mb-20px sl-flex-align-items-center'>
    <h1 class='nd_photos_h1_style'>Energy Portfolio Import</h1>
  </div>
  <div class='sl-font-size-14px sl-text-align-center'>
    <span class='sl-mr-5px' style="font-size: 20px; color: #F04C25">*</span>
    <span>A maximum of 1,000 projects can be created in an import.</span>
  </div>
  <div class='sl-font-size-14px sl-mb-20px sl-text-align-center'>
    Click <a class='sl-link-text sl-bold-text sl-cursor-pointer'
          href='/portfolios/download_energy_portfolio_import_template'>here</a>to download the template.
  </div>
  <div class='sl-flex-justify-content-center sl-h-15px sl-mb-10px sl-bold-text' style='color: #F04C25'>
    {{ alertMessage }}
  </div>
  <div class='sl-flex-column sl-flex-align-items-center sl-mb-80px'>
    <form @submit.prevent='importPortfolio' @change='checkFromSubmitStatus' id='portfolio-import-form'>
      <!---------------- Client Search ------------------->
      <div class='sl-flex-justify-content-space-btwn sl-flex-align-items-center'>
        <div class='psuedo-input'>
          <a @click='toggleClientInput' class='sl-cursor-pointer'>
            <span v-if='selectedClient' class='sl-bold-text sl-partner-blue-text'>{{ selectedClient.name }}</span>
            <span v-else>Select a client</span>
          </a>
          <xIcon v-if='selectedClient' @click='removeClient' class='sl-cursor-pointer' transform='scale(0.4)'/>
          <searchIcon v-else transform='scale(0.5)'/>
        </div>
      </div>

      <ul v-if='showClientInput' class='search-dropdown'>
        <input type='text' id='client-input' class='search-input' v-model='clientSearch' @input='searchClients'/>
        <li v-if='clients.length' v-for='client in clients' class='search-option' @click='selectClient(client)'>{{ client.name }}</li>
        <div v-else style='color: #9F9EA4' class='sl-mb-5px'>
          <span class='sl-ml-10px'>{{ searchboxText }}</span>
        </div>
      </ul>
      <!-------------------------------------------------->

      <!---------------- Portfolio Search ------------------->
      <div class='sl-flex-justify-content-space-btwn sl-flex-align-items-center sl-mt-20px'>
        <div v-if='selectedClient' class='psuedo-input'>
          <a @click='togglePortfolioInput' class='sl-cursor-pointer'>
            <span v-if='selectedPortfolio' class='sl-bold-text sl-partner-blue-text'>{{ selectedPortfolio.name }}</span>
            <span v-else>Select a portfolio</span>
          </a>
          <xIcon v-if='selectedPortfolio' @click='removePortfolio' class='sl-cursor-pointer' transform='scale(0.4)'/>
          <searchIcon v-else transform='scale(0.5)'/>
        </div>
        <div v-else class='psuedo-input psuedo-input-disabled'>
          <span class='sl-cursor-pointer' @click='portfolioAlert'>Select a portfolio</span>
          <searchIcon transform='scale(0.5)'/>
        </div>
      </div>

      <ul v-if='showPortfolioInput' class='search-dropdown'>
        <input type='text' id='portfolio-input' class='search-input' v-model='portfolioSearch' @input='searchPortfolios'/>
        <li v-if='portfolios.length' v-for='portfolio in portfolios' class='search-option' @click='selectPortfolio(portfolio)'>{{ portfolio.name }}</li>
        <div v-else style='color: #9F9EA4' class='sl-mb-5px'>
          <span class='sl-ml-10px'>{{ searchboxText }}</span>
        </div>
      </ul>
      <!-------------------------------------------------->

      <!---------------- Contact Search ------------------->
      <div class='sl-flex-justify-content-space-btwn sl-flex-align-items-center sl-mt-20px'>
        <div v-if='selectedClient && selectedPortfolio' class='psuedo-input'>
          <a @click='toggleContactInput' class='sl-cursor-pointer'>
            <span v-if='selectedContact' class='sl-bold-text sl-partner-blue-text'>{{ selectedContact.name }}</span>
            <span v-else>Select a contact</span>
          </a>
          <xIcon v-if='selectedContact' @click='removeContact' class='sl-cursor-pointer' transform='scale(0.4)'/>
          <searchIcon v-else transform='scale(0.5)'/>
        </div>
        <div v-else class='psuedo-input psuedo-input-disabled'>
          <span class='sl-cursor-pointer' @click='contactAlert'>Select a contact</span>
          <searchIcon transform='scale(0.5)'/>
        </div>
      </div>

      <ul v-if='showContactInput' class='search-dropdown'>
        <input type='text' id='contact-input' class='search-input' v-model='contactSearch' @input='searchContacts'/>
        <li v-if='contacts.length' v-for='contact in contacts' class='search-option' @click='selectContact(contact)'>{{ contact.name }}</li>
        <div v-else style='color: #9F9EA4' class='sl-mb-5px'>
          <span class='sl-ml-10px'>{{ searchboxText }}</span>
        </div>
      </ul>
      <!-------------------------------------------------->

      <!---------------- File Input ----------------------->
      <div class='sl-border-1px-medium-charcoal sl-flex-align-items-center sl-mt-20px sl-w-300px sl-h-32px sl-plr-10px sl-mb-20px'>
        <input type='file' name='file' accept='.xlsx' @change='setFile' id='uploadDataFile' ref='uploadDataFile'/>
      </div>

      <!---------------- Due Date ------------------------->
      <div class='sl-flex-column sl-mb-20px'>
        <label>Due Date</label>
        <input type='date' v-model='dueDate'
               class='sl-border-1px-medium-charcoal sl-h-32px sl-w-300px sl-plr-10px sl-mt-5px sl-mb-10px'/>
      </div>

      <!---------------- Project Search ------------------->
      <div class='sl-mb-30px'>
        <div class='sl-font-size-14px sl-mb-10px'>Do you want to search for existing projects?</div>
        <label class='sl-toggle-switch' id='project_search'>
                <input type='checkbox' id='project_search_checkbox' v-model='projectSearch' @change="changeProjectSearch">
                <span class='sl-toggle-switch-slider round'>
                  <span class='sl-toggle-switch-on'>Yes</span>
                  <span class='sl-toggle-switch-off'>No</span>
                </span>
              </label>
      </div>

      <!---------------- Add to Portfolio ------------------->
      <div class='sl-mb-30px' :class="{'slider-disabled':!projectSearch}">
        <div class='sl-font-size-14px sl-mb-10px'>Do you want to add the existing projects found to<br>this portfolio?</div>
        <label class='sl-toggle-switch' id='add_to_portfolio'>
                <input type='checkbox' id='add_to_portfolio_checkbox' v-model='addToPortfolio' :disabled='!projectSearch'>
                <span class='sl-toggle-switch-slider round'>
                  <span class='sl-toggle-switch-on'>Yes</span>
                  <span class='sl-toggle-switch-off'>No</span>
                </span>
              </label>
      </div>

      <!---------------- Services Checkboxes -------------->
      <div class='sl-font-size-14px'>
        <div class='sl-font-size-14px sl-mb-20px'>Select the services to add to each project:</div>

        <label for='energy_compliance' class='sl-custom-checkbox sl-mb-20px'>
          <sapn>Energy Compliance</sapn>
          <input v-model='energyCompliance' id='energy_compliance' type='checkbox'>
          <span class='checkmark'></span>
        </label>

        <label for='esg_benchmarking' class='sl-custom-checkbox sl-mb-20px'>
          <sapn>ESG Benchmarking</sapn>
          <input v-model='esgBenchmarking' id='esg_benchmarking' type='checkbox'>
          <span class='checkmark'></span>
        </label>

        <label for='utility_benchmarking' class='sl-custom-checkbox sl-mb-20px'>
          <sapn>Utility Benchmarking</sapn>
          <input v-model='utilityBenchmarking' id='utility_benchmarking' type='checkbox'>
          <span class='checkmark'></span>
        </label>

        <label for='carbon_proforma' class='sl-custom-checkbox sl-mb-20px'>
          <sapn>Carbon Proforma</sapn>
          <input v-model='carbonProforma' id='carbon_proforma' type='checkbox'>
          <span class='checkmark'></span>
        </label>
      </div>

      <div class='sl-flex-justify-content-center sl-mt-40px'>
        <button class='sl-simple-outline-btn sl-w-100px'
                :class="{'sl-simple-outline-btn-disabled':!enableImport}">
                Import
        </button>
      </div>
    </form>
  </div>
</template>
<script>
/* eslint max-lines: off */
import axios from 'axios';
import searchIcon from '../../../../../assets/images/icons/search_icon.svg';
import xIcon from '../../../../../assets/images/icons/Icon-Close_Charcoal-Dark-100_24.svg';
import SpinningModal from '../../../components/SpinningModal.vue';

export default {
  components: {
    searchIcon,
    xIcon,
    SpinningModal,
  },

  mounted() {
    // Close dropdowns when clicking outside of them
    window.document.addEventListener('click', (e) => {
      if (!document.getElementById('portfolio-import-form').contains(e.target)) {
        this.showClientInput = false;
        this.showPortfolioInput = false;
        this.showContactInput = false;
      }
    });
  },

  data() {
    return {
      showClientInput: false,
      selectedClient: null,
      clientSearch: '',
      clients: [],
      showPortfolioInput: false,
      selectedPortfolio: null,
      portfolioSearch: '',
      portfolios: [],
      showContactInput: false,
      selectedContact: null,
      contactSearch: '',
      contacts: [],
      clientSearchText: 'Client not found',
      portfolioSearchText: 'Portfolio not found',
      contactSearchText: 'Contact not found',
      energyCompliance: false,
      esgBenchmarking: false,
      utilityBenchmarking: false,
      carbonProforma: false,
      enableImport: false,
      file: null,
      dueDate: null,
      projectSearch: false,
      addToPortfolio: false,
      showLoadingModal: false,
      searchProjectMessageShown: false,
      alertMessage: '',
    };
  },

  methods: {
    // When a client is selected or removed, clear the portfolio and contact
    toggleClientInput() {
      this.showClientInput = !this.showClientInput;
      this.showPortfolioInput = false;
      this.showContactInput = false;
      if (this.showClientInput) {
        setTimeout(() => {
          document.getElementById('client-input').focus();
        }, 100);
      }
    },

    // When a portfolio is selected or removed, clear the contact
    togglePortfolioInput() {
      if (!this.selectedClient) { return; }
      this.showPortfolioInput = !this.showPortfolioInput;
      this.showClientInput = false;
      this.showContactInput = false;
      if (this.showPortfolioInput) {
        setTimeout(() => {
          document.getElementById('portfolio-input').focus();
        }, 100);
      }
    },

    // When a contact is selected or removed
    toggleContactInput() {
      if (!this.selectedClient && !this.selectedPortfolio) { return; }
      this.showContactInput = !this.showContactInput;
      this.showClientInput = false;
      this.showPortfolioInput = false;
      if (this.showContactInput) {
        setTimeout(() => {
          document.getElementById('contact-input').focus();
        }, 100);
      }
    },

    async searchClients() {
      await axios.get('/clients/search_client_name_return_json', {
        params: {
          search: this.clientSearch,
        },
      })
        .then((response) => {
          this.clients = response.data.clients;
          if (!this.clients.length) {
            this.searchboxText = 'No clients found';
          }
        });
    },

    async searchPortfolios() {
      await axios.get('/portfolios/search_portfolio_name_return_json', {
        params: {
          search: this.portfolioSearch,
          client_id: this.selectedClient.id,
        },
      })
        .then((response) => {
          this.portfolios = response.data.portfolios;
          if (!this.portfolios.length) {
            this.searchboxText = 'No portfolios found';
          }
        });
    },

    async searchContacts() {
      await axios.get('/contacts/search_contact_name_return_json', {
        params: {
          search: this.contactSearch,
          client_id: this.selectedClient.id,
        },
      })
        .then((response) => {
          this.contacts = response.data.contacts;
          if (!this.contacts.length) {
            this.searchboxText = 'No contacts found';
          }
        });
    },

    selectClient(client) {
      this.selectedClient = client;
      this.showClientInput = false;
      this.clientSearch = '';
      this.clients = [];
    },

    selectPortfolio(portfolio) {
      this.selectedPortfolio = portfolio;
      this.showPortfolioInput = false;
      this.portfolioSearch = '';
      this.portfolios = [];
    },

    selectContact(contact) {
      this.selectedContact = contact;
      this.showContactInput = false;
      this.contactSearch = '';
      this.contacts = [];
    },

    removeClient() {
      this.selectedClient = null;
      this.selectedPortfolio = null;
      this.selectedContact = null;
    },

    removePortfolio() {
      this.selectedPortfolio = null;
      this.selectedContact = null;
    },

    removeContact() {
      this.selectedContact = null;
    },

    // Import method to send the form data to the backend
    async importPortfolio() {
      if (!this.enableImport) {
        alert('Please fill out all required fields');
        return;
      }
      this.showLoadingModal = true;
      const formData = new FormData();
      formData.append('client_id', this.selectedClient.id);
      formData.append('portfolio_id', this.selectedPortfolio.id);
      formData.append('contact_id', this.selectedContact.id);
      formData.append('due_date', this.dueDate);
      formData.append('project_search', this.projectSearch);
      formData.append('add_to_portfolio', this.addToPortfolio);
      formData.append('energy_compliance', this.energyCompliance);
      formData.append('esg_benchmarking', this.esgBenchmarking);
      formData.append('utility_benchmarking', this.utilityBenchmarking);
      formData.append('carbon_proforma', this.carbonProforma);
      formData.append('file', this.$refs.uploadDataFile.files[0]);

      await axios.post('/portfolios/energy_portfolio_import', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(() => {
          this.alertMessage = 'Import is in progress. Status can be found in the job queue.';
          this.resetForm();
        })
        .catch((error) => {
          if (error.data.error_message) {
            alert(error.data.error_message);
          } else {
            alert('There was an error importing the portfolio. Please contact support.');
          }
        })
        .finally(() => {
          this.showLoadingModal = false;
        });
    },

    // Check if all required fields are filled out
    checkFromSubmitStatus() {
      if (this.selectedClient && this.selectedPortfolio && this.selectedContact && this.file && this.dueDate) {
        this.enableImport = true;
      } else {
        this.enableImport = false;
      }
    },

    setFile() {
      const file = this.$refs.uploadDataFile.files[0];
      this.file = file;
    },

    portfolioAlert() {
      alert('Please select a client first');
    },

    contactAlert() {
      alert('Please select a client and portfolio first');
    },

    // Alert message the first time the project search is changed
    changeProjectSearch() {
      if (this.projectSearch && !this.searchProjectMessageShown) {
        this.searchProjectMessageShown = true;
        const message1 = "This will search the client's existing projects by name. ";
        const message2 = 'If a project is found, the services selected will be added to the project. ';
        const mesage3 = 'If no project is found, a new project will be created with the selected services.';
        alert(message1 + message2 + mesage3);
      } else if (!this.projectSearch) {
        this.addToPortfolio = false;
      }
    },

    // Reset the form parameters
    resetForm() {
      this.selectedClient = null;
      this.selectedPortfolio = null;
      this.selectedContact = null;
      this.energyCompliance = false;
      this.esgBenchmarking = false;
      this.utilityBenchmarking = false;
      this.carbonProforma = false;
      this.file = null;
      this.dueDate = null;
      this.projectSearch = false;
      this.addToPortfolio = false;
      this.enableImport = false;
      this.showLoadingModal = false;
      document.getElementById('portfolio-import-form').reset();
    },
  },
};
</script>
<style scoped>
  .psuedo-input {
    border: 1px solid #9F9EA4;
    width: 300px;
    height: 32px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .psuedo-input-disabled {
    color: #9F9EA4
  }

  .search-input {
    width: 290px;
    height: 28px;
    padding-left: 5px;
    margin: 10px 0 5px 10px;
    display: block;
    border: 2px solid black;
    border-radius: 4px;
  }

  .search-dropdown {
    z-index: 2;
    position: absolute;
    border: 1px solid #9F9EA4;
    background-color: white;
    width: 320px;
  }

  .slider-disabled {
    color: #9F9EA4
  }

   input[type="date"], input[type="time"] {
     font-family: Roboto;
  }

  .search-option {
    padding: 5px 10px;
  }

  .search-option:hover {
    cursor: pointer;
    background-color: #005199;
    color: white;
  }
</style>
