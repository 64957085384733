<template>
  <SpinningModal v-if='showLoadingModal'/>
  <div class='sl-mb-20px sl-flex-justify-content-space-btwn'>
    <span class='sl-font-size-16px sl-bold-text' style='color: #005199'>Adjustment Factor by City or Region</span>
    <div class='sl-flex-align-items-center'>
      <select v-model='selectedVersionId'
              class='sl-h-28px sl-border-1px-medium-charcoal sl-mr-10px sl-w-200px'
              id='selectedVersion'
              style='color: #005199'>
        <option v-for='version in versions' :value='version.id'>Version: {{ version.name }}</option>
      </select>
      <a href='/pca_equity_costs/import_costs'
         class='sl-simple-outline-btn sl-h-15px sl-flex-align-items-center sl-mr-10px'>
         Import Costs
      </a>
      <a href='/pca_cost_factor_versions'
         class='sl-simple-outline-btn sl-h-15px sl-flex-align-items-center sl-mr-10px'>
         Versions
      </a>
    </div>
  </div>
  <br>

  <form @submit.prevent='fetchCostFactors'>
    <div class='sl-flex-only'>
      <!-- Country Select -->
      <div class='sl-flex-column sl-mr-20px'>
        <label class='sl-mb-5px'>Country&nbsp*</label>
        <select @change='filterStates' v-model='selectedCountry' id='country'
                class='sl-w-250px sl-h-32px sl-border-1px-medium-charcoal'
                required>
          <option :value=null selected style='color: #9F9EA4;'>Choose a Selection</option>
          <option v-for='country in countryOptions'>{{ country }}</option>
        </select>
      </div>

      <!-- State Select -->
      <div class='sl-flex-column sl-mr-20px'>
        <label class='sl-mb-5px' :class="{'disabled-font':!this.selectedCountry}">State&nbsp*</label>
        <select @change='filterCities' v-model='selectedState' id='state'
                class='sl-w-250px sl-h-32px sl-border-1px-medium-charcoal'
                :disabled='!this.selectedCountry'
                required>
          <option :value=null selected style='color: #9F9EA4;'>Choose a Selection</option>
          <option v-for='state in stateOptions'>{{ state }}</option>
        </select>
      </div>

      <!-- City Select -->
      <div class='sl-flex-column sl-mr-20px'>
        <label class='sl-mb-5px' :class="{'disabled-font':!this.selectedState}">City</label>
        <select v-model='selectedCity' id='city'
                class='sl-w-250px sl-h-32px sl-border-1px-medium-charcoal'
                :disabled='!this.selectedState'>
          <option :value=null selected style='color: #9F9EA4;'>Choose a Selection</option>
          <option v-for='city in cityOptions'>{{ city }}</option>
        </select>
      </div>

      <!-- Category Select -->
      <div class='sl-flex-column sl-mr-20px'>
        <label class='sl-mb-5px'>Adjustment Category</label>
        <select v-model='selectedCategory' id='category'
                class='sl-w-250px sl-h-32px sl-border-1px-medium-charcoal'>
          <option :value=null selected style='color: #9F9EA4;'>Choose a Selection</option>
          <option v-for='category in categoryOptions'>{{ category }}</option>
        </select>
      </div>

      <button class='sl-simple-outline-btn sl-h-28px sl-mt-20px sl-w-85px'
              :class="{'sl-simple-outline-btn-disabled':enableSearchBtn()}">
              Search
      </button>
    </div>
  </form>

  <!-- Main Body -->
  <div class='sl-flex-only'>
    <!-- Left Side Categories -->
    <table class='sl-mt-20px sl-border-b-1px' style='border-spacing: 0'>
      <thead>
        <tr class='sl-h-28px'>
          <td class='sl-border-tb-2px-primary-blue sl-bold-text sl-w-250px sl-pl-10px sl-border-r-1px-medium-charcoal'>
            Adjustment Category
          </td>
        </tr>
      </thead>
      <tbody v-if='showselectedCategory'>
        <tr class='sl-table__row--striped sl-h-28px'>
          <td class='sl-bold-text sl-w-200px sl-pl-10px sl-border-r-1px-medium-charcoal'>{{ selectedCategoryName }}</td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr v-for='(category, index) in categoryOptions' class='sl-table__row--striped'>
          <td v-if='islastRow(index)' class='sl-w-200px sl-pl-10px sl-border-r-1px-medium-charcoal sl-h-42px'>
            <div class='sl-bold-text'>{{ category }}</div>
            <div>(Current Year)</div>
          </td>
          <td v-else class='sl-bold-text sl-w-200px sl-pl-10px sl-border-r-1px-medium-charcoal sl-h-28px'>{{ category }}</td>
        </tr>
      </tbody>
    </table>

    <!-- Search Results -->
    <div v-if='costFactors.length' class='sl-flex-only'>
      <table v-for='costFactor in costFactors' class='sl-mt-20px sl-border-b-1px' style='border-spacing: 0'>
        <thead>
          <tr class='sl-h-28px'>
            <td class='sl-w-200px sl-border-tb-2px-primary-blue sl-bold-text sl-text-align-center'>
            <a :href='`/pca_cost_factor_cities/${costFactor.id}`'>{{ costFactor.city }}</a>
            </td>
          </tr>
        </thead>
        <tbody v-if='showselectedCategory'>
          <tr class='sl-table__row--striped sl-h-28px'>
            <td class='sl-w-200px sl-text-align-center'>{{ costFactor[selectedCategoryName] }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr v-for='(category, index) in categoryOptions' class='sl-table__row--striped'>
            <td v-if='islastRow(index)' class='sl-w-200px sl-text-align-center sl-h-42px'>{{ costFactor[category] }}</td>
            <td v-else class='sl-w-200px sl-text-align-center sl-h-28px'>{{ costFactor[category] }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else class='sl-mt-20px sl-w-450px sl-text-align-center sl-w-60per'>
      <br>
      <span class='sl-bold-text sl-font-size-16px' style='color: #005199'>Select a state and city to get started.</span>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import SpinningModal from '../../components/SpinningModal.vue';

export default {
  components: {
    SpinningModal,
  },

  data() {
    const urlParams = new URLSearchParams(window.location.search);
    return {
      countryOptions: [],
      stateOptions: [],
      cityOptions: [],
      categoryOptions: [],
      costFactors: [],
      versions: [],
      selectedCountry: urlParams.get('country'),
      selectedState: urlParams.get('state'),
      selectedCity: urlParams.get('city'),
      selectedCategory: urlParams.get('category'),
      showselectedCategory: null,
      selectedCategoryName: null,
      selectedVersionId: null,
      showLoadingModal: false,
    };
  },

  async created() {
    this.fetchCostFactors();
    this.updateUrl();
  },

  methods: {
    async fetchCostFactors() {
      this.showLoadingModal = true;
      this.updateUrl();
      await axios.get('pca_cost_factors/vue_index', {
        params: {
          country: this.selectedCountry,
          state: this.selectedState,
          city: this.selectedCity,
          category: this.selectedCategory,
          version_id: this.selectedVersionId,
        },
      })
        .then((response) => {
          this.countryOptions = response.data.countryOptions;
          this.stateOptions = response.data.stateOptions;
          this.cityOptions = response.data.cityOptions;
          this.categoryOptions = response.data.categoryOptions;
          this.costFactors = response.data.costFactors;
          this.versions = response.data.versions;
          this.selectedVersionId = response.data.selectedVersionId;
          setTimeout(() => { this.showLoadingModal = false; }, 500);
          this.categorySearch();
        })
        .catch((error) => {
          console.log(error); // eslint-disable-line no-console
          this.showLoadingModal = false;
        });
    },

    // Add search params to the url
    updateUrl() {
      const params = new URLSearchParams();
      if (this.selectedCountry) {
        params.set('country', this.selectedCountry);
      }
      if (this.selectedCountry === null) {
        params.set('country', 'United States');
        this.selectedCountry = 'United States';
      }
      if (this.selectedState) {
        params.set('state', this.selectedState);
      }
      if (this.selectedCity) {
        params.set('city', this.selectedCity);
      }
      if (this.selectedCategory) {
        params.set('category', this.selectedCategory);
      }
      history.replaceState(null, null, `?${params.toString()}`); // eslint-disable-line no-restricted-globals
    },

    // Pass in the row index, return true if its the last row
    islastRow(index) {
      if (index === this.categoryOptions.length - 1) {
        return true;
      }
      return false;
    },

    // When a country is selected, make a request to the server and adjust select options to only show states and cities in that country
    async filterStates() {
      this.selectedState = null;
      this.selectedCity = null;
      await axios.get('pca_cost_factors/filter_states', {
        params: {
          country: this.selectedCountry,
        },
      })
        .then((response) => {
          this.stateOptions = response.data.states;
          this.cityOptions = response.data.cities;
          this.enableSearchBtn();
        })
        .catch((error) => {
          console.log(error); // eslint-disable-line no-console
        });
    },

    // When a country or state is selected, make a request to the server and adjust select options to only show cities in that state
    async filterCities() {
      this.selectedCity = null;
      await axios.get('pca_cost_factors/filter_cities', {
        params: {
          country: this.selectedCountry,
          state: this.selectedState,
        },
      })
        .then((response) => {
          this.cityOptions = response.data.cities;
          this.enableSearchBtn();
        })
        .catch((error) => {
          console.log(error); // eslint-disable-line no-console
        });
    },

    // When a country and state is selected, enable the search button
    enableSearchBtn() {
      return (!this.selectedCountry || !this.selectedState);
    },

    // If a category is selected, only show the values for that category
    categorySearch() {
      if (this.selectedCategory) {
        this.showselectedCategory = true;
        this.selectedCategoryName = this.selectedCategory;
      } else {
        this.showselectedCategory = false;
        this.selectedCategoryName = null;
      }
    },
  },
};
</script>
<style scoped>
  .disabled-font {
    color: #9F9EA4;
  }
</style>
