<template>
  <h2 class='sl-static-h2-style'>JSON Import (Admins)</h2>

  <h1>For Testing Purposes Only - Projects need to be created on "PCA Test" Client</h1>

  <form style="min-height: initial;" class="dropzone dz-clickable" id="file-dropzone_importdocs_json_doc" controller="importdocs" action="/importdocs/ajax_create" enctype="multipart/form-data" accept-charset="UTF-8" data-remote="true" method="post">
    <input name="utf8" type="hidden" value="✓" autocomplete="off">
    <input autocomplete="off" type="hidden" value="json_data" name="importdoc[name]" id="importdoc_name">
    <div class="dz-message sl-working-doc-drag-and-drop-zone" data-dz-message="">
      <upload class="sl-svg-icon-size-3" />
      <div class="sl-drag-and-drop-text-container">
        <div class="sl-drag-and-drop-main-text">Upload</div>
        <div class="sl-drag-and-drop-subtext">(Drag and drop files here to upload)</div>
      </div>
    </div>
  </form>

  <div class="sl-mt-20px" style='width: 1000px;'>
    <table class='sl-simple-table-1'>
      <thead>
        <tr>
          <td>ID</td>
          <td>FILE NAME</td>
          <td>UPLOADED AT</td>
          <td>UPLOADED BY</td>
          <td>IMPORTED AT</td>
          <td>IMPORTED BY</td>
          <td>SITELYNX PROJECT ID</td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="importdoc, index in importdocs" :key="importdoc.id">
          <td>{{ importdoc.id }}</td>
          <td>
            <a :href="'/importdocs/' + importdoc.id" target="_blank" download>
              {{ importdoc.doc_file_name }}
            </a>
          </td>
          <td>{{ importdoc.created_at }}</td>
          <td>{{ importdoc.user_id }}</td>
          <td>{{ importdoc.imported_at }}</td>
          <td>{{ importdoc.imported_by }}</td>
          <td>
            <span v-if="importdoc.project_id">
              <a :href="'/projects/' + importdoc.project_id" target="_blank">{{ importdoc.project_id }}</a>
            </span>
          </td>
          <td>
            <div v-if="import_func_called" class="sl-spinning-wheel-20px"></div>
            <span v-else>
              <button v-if="!importdoc.imported_at" v-on:click="ImportJson(importdoc.id, index)">Import</button>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      importdocs: [],
      import_func_called: false,
    };
  },

  // Load all project data variables
  async created() {
    await axios.get('/importdocs/return_bluelynx_json_data')
      .then((response) => {
        console.log(response.data);
        this.importdocs = response.data.importdocs;
      }).catch((error) => {
        console.log(error);
      });
  },

  methods: {
    ImportJson(id, index) {
      const currentObj = this;

      // prevent double clicking
      if (currentObj.import_func_called) {
        return;
      }

      currentObj.import_func_called = true;

      axios.post('/importdocs/json_import', {
        id,
      })
        .then((response) => {
          console.log(response.data);
          if (response.data.import_status === 'error') {
            alert(`Error: ${response.data.msg}`);
          } else {
            alert(`Success: ${response.data.msg}`);
            // update the imported_at and imported_by fields
            this.importdocs[index].imported_at = response.data.importdoc.imported_at;
            this.importdocs[index].imported_by = response.data.importdoc.imported_by;
            this.importdocs[index].project_id = response.data.importdoc.project_id;
          }
        })
        .catch((error) => {
          console.log(error);
          alert(`Error: ${error}`);
        })
        .finally(() => {
          currentObj.import_func_called = false;
        });
    },
  },
};
</script>

<style>
</style>