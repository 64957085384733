<template>
    <div class='sl-mr-5px'>
        <div @click="openModal" class='figma-icon sl-search-field'><Search style='height:14px' /><span>Search Field</span></div>
    </div>
    <div v-if='showMainModal' class = 'sl-search-field-modal-container'>
        <div class="sl-search-field-modal-content">
            <CloseIcon @click="closeModal" style='position: absolute; right: 10px; top: 10px;' class="figma-icon"/>
            <h2 class="sl-text-align-center" >Search for an esg checklist field to see which templates have this field assigned</h2>
            <div class="sl-search-input-button-div">
              <input class='sl-search-input' type="text" placeholder="ex: esg_1_1a" v-model="field_search">
              <button class="sl-simple-outline-btn" @click="searchField">Search</button>
            </div>
            <br>
            <h2 v-if="showErrorMessage" style="color: red; text-align: center">{{ errorMessage }}</h2>
            <div v-if="searchResults.length == 0 && this.searchSubmitted" class="sl-text-align-center" style='margin-top: 20px;'>No results found</div>
            <div v-if="searchResults.length > 0">
              <button  class="sl-simple-outline-btn" @click="downloadCSV">Download CSV</button>
              <table class="sl-field-results-table" border='1' bordercolor='#E0E0E0' cellpadding='7' cellspacing='0'>
                <thead style='background-color:#E0E0E0'>
                  <tr>
                    <th>Template ID</th>
                    <th>Client Name</th>
                    <th>Template Name</th>
                    <th>Category Name</th>
                    <th>Edit</th>
                  </tr>
                </thead>
                <tr v-for="item in searchResults">
                  <td>{{ item.template.id }}</td>
                  <td>{{ item.client_name }}</td>
                  <td>{{ item.template.title }}</td>
                  <td>{{ item.category_name }}</td>
                  <td>
                    <a v-if="item.template.is_master != true" v-bind:href="'/esg_checklist_templates/'+ item.template.id +'/edit'" target="_blank">Edit</a>
                  </td>
                </tr>
              </table>
            </div>
            </div>
    </div>
  </template>

<script>
import axios from 'axios';
import Search from '../../../assets/images/icons/Icon-Search_Charcoal-Dark-100_20.svg';
import CloseIcon from '../../../assets/images/icons/Icon-Close_Charcoal-Dark-100_24.svg';

export default {
  components: {
    Search,
    CloseIcon,
  },
  data() {
    return {
      showMainModal: false,
      fieldSearch: '',
      searchResults: [],
      searchSubmitted: false,
      showErrorMessage: false,
      errorMessage: '',
    };
  },
  methods: {
    openModal() {
      this.showMainModal = true;
    },
    closeModal() {
      this.showMainModal = false;
    },
    async searchField() {
      await axios.get('/esg_checklist_templates/search_checklist_field_in_templates', {
        params: {
          field_search: this.field_search,
        },
      })
        .then((response) => {
          this.searchResults = response.data.search_results;
          this.searchSubmitted = true;
        })
        .catch(() => {
          this.showErrorMessage = true;
          this.errorMessage = 'An error has occurred';
        });
    },
    async downloadCSV() {
      await axios.post('/excel_generators/esg_checklist_fields_csv', {
        params: {
          search_results: this.searchResults,
          field_search: this.field_search,
        },
      })
        .then((response) => {
          // create a temporary link element
          // set the href to the url of the file
          // click it to download the file
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.setAttribute('href', url);
          link.setAttribute('download', `${this.field_search}_ESG_Templates.csv`);
          link.click();
          link.remove();
        })
        .catch(() => {
          this.showErrorMessage = true;
          this.errorMessage = 'An error has occurred';
        });
    },
  },
};
</script>

  <style scoped lang="css">
    .sl-search-field {
        color: var(--dark-charcoal);
        cursor: pointer;
        display: flex;
        align-items: center;
        border: 2px solid var(--dark-charcoal);
        padding: 4px 6px;
        width: 125px;
        justify-content: center;
        font-size: 14px
    }
    .sl-search-field-modal-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1000;
    }
    .sl-search-field-modal-content {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 5px;
        width: 50%;
        height: 50%;
        overflow: auto;
    }
    .sl-search-input-button-div {
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
    }
    .sl-search-input {
        width: 85%;
        padding: 5px;
        border: 1px solid var(--dark-charcoal);

    }
    .sl-field-results-table {
        width: 100%;
        margin-top: 20px;
    }
  </style>
