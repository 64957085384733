<template>
  <div v-if='dataReady' class='sl-mt-10px sl-flex-align-items-center'>
    <h1 class='nd_photos_h1_style' style='margin-right: 0;'>Energy Efficiency Calculations</h1>
  </div>
  <div v-if='dataReady' class='pl-flex-space-between pl-section-header-bar'>
    <div class='sl-font-size-14px'>&nbsp</div>
  </div>
  <div v-if='dataReady' class='sl-flex-only'>
    <FixedColumn
      :baselineMetric='baselineMetric'
      :secondaryFuelType='secondaryFuelType'
      :nonRenewableMeasures='nonRenewableMeasures'
      :renewableMeasures='renewableMeasures'
      :allMeasures='allMeasures'
      :showBaselineEnergyUse='showBaselineEnergyUse'
      :showEnergyEfficiency='showEnergyEfficiency'
      :showBuildingEnergyUseAfterEE='showBuildingEnergyUseAfterEE'
      :showRenewableEnergy='showRenewableEnergy'
      :showBuildingEnergyUseAfterRE='showBuildingEnergyUseAfterRE'
      :showFinalBuildingEnergy='showFinalBuildingEnergy'
      @toggle-baseline-energy-use='toggleBaselineEnergyUse()'
      @toggle-energy-efficiency='toggleEnergyEfficiency()'
      @toggle-building-energy-use-after-ee='toggleBuildingEnergyUseAfterEE()'
      @toggle-renewable-energy='toggleRenewableEnergy()'
      @toggle-building-energy-use-after-re='toggleBuildingEnergyUseAfterRE()'
      @toggle-final-building-energy='toggleFinalBuildingEnergy()'/>

    <div class='sl-flex-column' id='sl-calculations-table-overflow-container'>
      <table class='sl-esg-decarbonization-table' id='sl-esg-calculations-table'>
        <thead>
          <tr class='sl-table__row sl-table__row--esg'>
            <td v-for='year in years' class='sl-w-100px '>{{ year }}</td>
          </tr>
        </thead>
        <tbody>
          <!-- Baseline Energy Use -->
          <tr class='sl-table__row sl-table__row--esg sl-h-42px sl-esg-calculations-table-header-row'
              :class="{ 'no-border':!showBaselineEnergyUse }"
              id='sl-esg-calculations-table-first-header-row'>
          </tr>
          <div :class="{ 'sl-hide-content':!showBaselineEnergyUse }">
            <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
              <td v-for='year in years'>{{ numberWithDelimiter(baselineMetric.baseline_electric_usage) }}</td>
            </tr>
            <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
              <td v-for='year in years'>{{ numberWithDelimiter(baselineMetric.baseline_usage_therms) }}</td>
            </tr>
            <tr class='sl-table__row sl-table__row--esg sl-h-42px sl-border-t-2px-medium-charcoal'>
              <td v-for='cost in baselineElectricCost'>{{ numberToCurrency(cost) }}</td>
            </tr>
            <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
              <td v-for='cost in baselineGasCost'>{{ numberToCurrency(cost) }}</td>
            </tr>
            <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
              <td v-for='cost in baselineTotalCost'>{{ numberToCurrency(cost) }}</td>
            </tr>
            <tr class='sl-table__row sl-table__row--esg sl-h-42px sl-border-t-2px-medium-charcoal'>
              <td v-for='year in years'>{{ numberWithDelimiter(baselineMetric.electric_kbtu) }}</td>
            </tr>
            <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
              <td v-for='year in years'>{{ numberWithDelimiter(baselineMetric.gas_kbtu) }}</td>
            </tr>
            <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
              <td v-for='year in years'>{{ numberWithDelimiter(baselineMetric.total_kbtu) }}</td>
            </tr>
            <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
              <td v-for='year in years'>{{ numberWithDelimiter(baselineMetric.site_eui) }}</td>
            </tr>
          </div>

          <!-- Energy Efficiency Measures -->
          <tr class='sl-table__row sl-table__row--esg sl-h-42px sl-esg-calculations-table-header-row'
              :class="{ 'no-border':!showEnergyEfficiency }"
              v-if="!isEmpty(nonRenewableMeasures)">
          </tr>
          <div :class="{ 'sl-hide-content':!showEnergyEfficiency }">
            <div v-for='measure in nonRenewableMeasures'>
              <tr class='sl-table__row sl-table__row--esg sl-h-37px sl-esg-table-sub-header-row'>
                <td></td>
                <td v-if='measure.display' nowrap>Spread Years:</td>
                <td v-if='measure.display'>{{ measure.spread_year_range }}</td>
              </tr>
              <div v-if='measure.display'>
                <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
                  <td v-for="electric in measure.electric">{{ numberWithDelimiter(electric) }}</td>
                </tr>
                <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
                  <td v-for="gas in measure.gas">{{ numberWithDelimiter(gas) }}</td>
                </tr>
                <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
                  <td v-for="cost in measure.cost">{{ numberToCurrency(cost) }}</td>
                </tr>
                <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
                  <td v-for="savings in measure.cost_savings">{{ numberToCurrency(savings) }}</td>
                </tr>
              </div>
            </div>
          </div>

          <!-- Building Energy Use After EE -->
          <tr class='sl-table__row sl-table__row--esg sl-h-42px sl-esg-calculations-table-header-row'
              :class="{ 'no-border':!showBuildingEnergyUseAfterEE }"
              v-if="!isEmpty(nonRenewableMeasures)">
          </tr>
          <div v-if="!isEmpty(nonRenewableMeasures)" :class="{ 'sl-hide-content':!showBuildingEnergyUseAfterEE }">
            <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
              <td v-for='electricUseAfterEE in electricUseAfterEEArray'>{{ numberWithDelimiter(electricUseAfterEE) }}</td>
            </tr>
            <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
              <td v-for='gasUseAfterEE in gasUseAfterEEArray'>{{ numberWithDelimiter(gasUseAfterEE) }}</td>
            </tr>
          </div>

          <!-- Renewable Measures -->
          <tr class='sl-table__row sl-table__row--esg sl-h-42px sl-esg-calculations-table-header-row'
              :class="{ 'no-border':!showRenewableEnergy }"
              v-if="!isEmpty(renewableMeasures)">
          </tr>
          <div :class="{ 'sl-hide-content':!showRenewableEnergy }">
            <div v-for='measure in renewableMeasures'>
              <tr class='sl-table__row sl-table__row--esg sl-h-42px sl-esg-table-sub-header-row'>
                <td></td>
                <td v-if='measure.display' nowrap>Spread Years:</td>
                <td v-if='measure.display'>{{ measure.spread_year_range }}</td>
              </tr>
              <div v-if='measure.display'>
                <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
                  <td v-for="electric in measure.electric">{{ numberWithDelimiter(electric) }}</td>
                </tr>
                <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
                  <td v-for="cost in measure.cost">{{ numberToCurrency(cost) }}</td>
                </tr>
                <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
                  <td v-for="savings in measure.cost_savings">{{ numberToCurrency(savings) }}</td>
                </tr>
              </div>
            </div>
          </div>

          <!-- Building Energy Use After RE -->
          <tr class='sl-table__row sl-table__row--esg sl-h-42px sl-esg-calculations-table-header-row'
              :class="{ 'no-border':!showBuildingEnergyUseAfterRE }"
              v-if="!isEmpty(renewableMeasures)">
          </tr>
          <div v-if="!isEmpty(renewableMeasures)" :class="{'sl-hide-content':!showBuildingEnergyUseAfterRE}">
            <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
              <td v-for='electricUseAfterRE in electricUseAfterREArray'>{{ numberWithDelimiter(electricUseAfterRE) }}</td>
            </tr>
            <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
              <td v-for='gasUseAfterRE in gasUseAfterEEArray'>{{ numberWithDelimiter(gasUseAfterRE) }}</td>
            </tr>
          </div>

          <!-- Final Building Energy Use -->
          <tr class='sl-table__row sl-table__row--esg sl-h-42px sl-esg-calculations-table-header-row'>
          </tr>
          <div :class="{ 'sl-hide-content':!showFinalBuildingEnergy }">
            <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
              <td v-for='finalElectricUse in finalElectricUseArray'>{{ numberWithDelimiter(finalElectricUse) }}</td>
            </tr>
            <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
              <td v-for='finalGasUse in finalGasUseArray'>{{ numberWithDelimiter(finalGasUse) }}</td>
            </tr>
            <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
              <td v-for='finalTotalEnergyUse in finalTotalEnergyUseArray'>{{ numberWithDelimiter(finalTotalEnergyUse) }}</td>
            </tr>
            <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
              <td v-for='finalSiteEUI in finalSiteEUIArray'>{{ numberWithDelimiter(finalSiteEUI) }}</td>
            </tr>
            <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
              <td v-for='finalPercentSavings in finalPercentSavingsArray'>{{ numberWithDelimiter(finalPercentSavings) }}%</td>
            </tr>
            <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
              <td v-for='emissions in carbonEmissions'>{{ numberWithDelimiter(emissions) }}</td>
            </tr>
            <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
              <td v-for='emissions in carbonEmissionsWithoutMeasures'>{{ numberWithDelimiter(emissions) }}</td>
            </tr>
          </div>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import FixedColumn from './CalculationsFixedColumn.vue';

export default {
  components: {
    FixedColumn,
  },

  data() {
    const urlParams = new URLSearchParams(window.location.search);
    return {
      baselineMetricId: urlParams.get('baseline_metric_id'),
      baselineMetric: {},
      secondaryFuelType: {},
      years: [],
      nonRenewableMeasures: {},
      renewableMeasures: {},
      allMeasures: {},
      baselineElectricCost: [],
      baselineGasCost: [],
      baselineTotalCost: [],
      electricUseAfterEEArray: [],
      gasUseAfterEEArray: [],
      electricUseAfterREArray: [],
      finalElectricUseArray: [],
      finalGasUseArray: [],
      finalTotalEnergyUseArray: [],
      finalSiteEUIArray: [],
      finalPercentSavingsArray: [],
      carbonEmissions: [],
      carbonEmissionsWithoutMeasures: [],
      showBaselineEnergyUse: false,
      showEnergyEfficiency: false,
      showBuildingEnergyUseAfterEE: false,
      showRenewableEnergy: false,
      showBuildingEnergyUseAfterRE: false,
      showFinalBuildingEnergy: true,
      dataReady: false,
    };
  },

  mounted() {
    this.fetchCalculations();
  },

  methods: {
    fetchCalculations() {
      axios.get('/decarbonization_goal_results/fetch_calculations', {
        params: {
          baseline_metric_id: this.baselineMetricId,
        },
      })
        .then((response) => {
          this.baselineMetric = response.data.baseline_metric;
          this.secondaryFuelType = response.data.secondary_fuel_type;
          this.years = response.data.years;
          this.nonRenewableMeasures = response.data.calculations['nonrenewable energy measures'];
          this.renewableMeasures = response.data.calculations['renewable energy measures'];
          this.allMeasures = { ...this.nonRenewableMeasures, ...this.renewableMeasures };
          this.baselineElectricCost = this.baselineMetric.electric_cost;
          this.baselineGasCost = this.baselineMetric.gas_cost;
          this.baselineTotalCost = this.baselineMetric.total_cost;
          this.electricUseAfterEEArray = response.data.calculations['building electric use after ee'];
          this.gasUseAfterEEArray = response.data.calculations['building gas use after ee'];
          this.electricUseAfterREArray = response.data.calculations['building electric use after re'];
          this.finalElectricUseArray = response.data.calculations['final building electric use kbtu'];
          this.finalGasUseArray = response.data.calculations['final building gas use kbtu'];
          this.finalTotalEnergyUseArray = response.data.calculations['final building total energy use'];
          this.finalSiteEUIArray = response.data.calculations['final building site eui'];
          this.finalPercentSavingsArray = response.data.calculations['final building percent savings'];
          this.carbonEmissions = response.data.carbon_emissions;
          this.carbonEmissionsWithoutMeasures = response.data.carbon_emissions_without_measures;
          this.setDisplayVariables(this.nonRenewableMeasures);
          this.setDisplayVariables(this.renewableMeasures);
          this.dataReady = true;
        });
    },

    toggleBaselineEnergyUse() {
      this.showBaselineEnergyUse = !this.showBaselineEnergyUse;
    },

    toggleEnergyEfficiency() {
      this.showEnergyEfficiency = !this.showEnergyEfficiency;
    },

    toggleBuildingEnergyUseAfterEE() {
      this.showBuildingEnergyUseAfterEE = !this.showBuildingEnergyUseAfterEE;
    },

    toggleRenewableEnergy() {
      this.showRenewableEnergy = !this.showRenewableEnergy;
    },

    toggleBuildingEnergyUseAfterRE() {
      this.showBuildingEnergyUseAfterRE = !this.showBuildingEnergyUseAfterRE;
    },

    toggleFinalBuildingEnergy() {
      this.showFinalBuildingEnergy = !this.showFinalBuildingEnergy;
    },

    // Check if object is empty
    isEmpty(obj) {
      return Object.keys(obj).length === 0;
    },

    // Add these variables to each measure object. These are used to show hide measures and their full descriptions
    setDisplayVariables() {
      Object.keys(this.allMeasures).forEach((key) => {
        this.allMeasures[key].display = true;
        this.allMeasures[key].hover = false;
        this.allMeasures[key].showFullDescription = false;
      });
    },

    numberToCurrency(number) {
      return `$${Intl.NumberFormat().format(Math.round(number))}`;
    },

    numberWithDelimiter(number) {
      if (Number(number) === 0) {
        return 0;
      }
      return Intl.NumberFormat().format(Math.round(number));
    },
  },
};
</script>
<style scoped>
  .no-border {
    border-bottom: none;
  }
</style>
