<template>
  <!-- modal -->
  <div v-if="instructionsExist && modalVisible" class='sl-instruction-modal'>
    <div class='sl-p-all-20px sl-instruction-modal-content' style="padding-bottom: 0;">
      <div class='sl-mb-10px'>
        <div class='sl-title-style'>
          <p style="white-space: pre-wrap;">
            {{ current_instructions.title }}
          </p>
        </div>

        <div class='sl-body-style'>
          <p style="white-space: pre-wrap;">
            {{ current_instructions.body }}
          </p>
        </div>

        <div v-if="doc_url">
          <embed :src="doc_url" width="100%" height="800" title="Instructions" type="application/pdf">
        </div>
      </div>

      <div class="sl-ptb-20px" style="position: sticky; bottom: 0; background-color: #FFFFFF;">
        <hr>

        <div v-if="user_already_accepted" class='sl-mt-10px sl-text-align-center' >
          <p><strong>You have already accepted these instructions</strong></p>
          <button @click="closeModal" class='sl-simple-outline-btn' id='sl-close-instructions'>Close</button>
        </div>

        <div v-else class='sl-mt-10px sl-text-align-center'>
          <p><strong>You must accept before proceeding</strong></p>
          <button @click="acceptInstructions" class='sl-simple-outline-btn' id='sl-accept-instructions'>Accept</button>
        </div>
      </div>
    </div>
  </div>

  <!-- button to show pop-up -->
  <div v-if="instructionsExist">
    <button class='sl-simple-outline-btn' @click="showModal">View Portfolio Instructions (Updated {{ instruction_created_date }})</button>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      instructionsExist: false,
      current_instructions: null,
      user_already_accepted: false,
      modalVisible: false,
      instructions_recently_updated: false,
      doc_url: null,
      filename: null,
      instruction_created_date: null,
    };
  },

  mounted() {
    const parentDiv = document.getElementById('sl_vue_portfolio_bluelynx_instructions_pop_up');
    const project_id = parentDiv.getAttribute('project_id');
    const report_name = parentDiv.getAttribute('report_name');

    axios.get('/portfolio_bluelynx_instructions/return_instructions_by_project_and_report_name', {
      params: {
        project_id,
        report_name,
      },
    })
      .then((response) => {
        if (response.data.portfolio_bluelynx_instruction !== null) {
          this.instructionsExist = true;
          this.current_instructions = response.data.portfolio_bluelynx_instruction;
          this.user_already_accepted = response.data.user_already_accepted;
          this.instructions_recently_updated = response.data.instructions_recently_updated;
          this.doc_url = response.data.doc_url;
          this.filename = response.data.filename;
          this.instruction_created_date = response.data.instruction_created_date;
        }

        // Show pop-up if user has not accepted instructions yet
        if (this.user_already_accepted === false) {
          this.modalVisible = true;
        }

        // Show alert if instructions have been updated
        if (this.instructions_recently_updated) {
          alert('Portfolio instructions have been updated. Please review the new instructions.');
        }
      })
      .catch(() => {
        // silence error for now
      });
  },

  methods: {
    closeModal() {
      this.modalVisible = false;
    },

    showModal() {
      this.modalVisible = true;
    },

    acceptInstructions() {
      this.modalVisible = false;

      axios.post('/portfolio_bluelynx_instructions/accept_instructions', {
        id: this.current_instructions.id,
      })
        .then(() => {
          this.user_already_accepted = true;
        })
        .catch((error) => {
          alert(error);
        });
    },
  },
};
</script>

<style>

</style>
