<template>
  <SpinningModal v-if='showLoadingModal'/>
  <CustomPathwayModal
    v-if='showCustomPathwayModal'
    @close-modal='showCustomPathwayModal = false;'
    @create='create'
    :years='years'
  />
  <CustomPathwayImportModal v-if='showImportModal'
    :baselineMetricId='baselineMetricId'
    @close-modal='showImportModal = false;'
    @import-complete='uploadComplete'
  />
  <div v-if='dataReady' class='sl-mt-10px sl-mb-40px sl-flex-align-items-center'>
    <h1 class='nd_photos_h1_style'>Custom Pathways</h1>
  </div>
  <div v-if='dataReady' class='sl-flex-align-items-center'>
    <span @click='showImportModal=true;' class='sl-cursor-pointer figma-icon-link sl-bold-text sl-ml-5px' style='font-size: 26px;' title='Import'>+</span>
    <button @click='showCustomPathwayModal=true' class='sl-simple-outline-btn sl-w-100px sl-mlr-10px'>Add Pathway</button>
    <span class='sl-bold-text sl-font-size-14px' style='color: #F04C25'>{{ alertMessage }}</span>
  </div>
  <div v-if='dataReady' @input='tableChanged'>
    <div class='sl-flex-only sl-mt-10px'>
      <table class='sl-esg-decarbonization-table sl-border-r-1px-medium-charcoal'>
        <thead>
          <tr class='sl-table__row sl-table__row--esg'>
            <td class='sl-w-200px'>Name</td>
          </tr>
        </thead>
        <tbody>
          <!-- Carbon Emission Pathways -->
          <tr class='sl-h-28px sl-esg-table-sub-header-row sl-flex-align-items-center'>
            <td class='sl-w-30px'></td>
            <td class='sl-w-200px sl-flex-justify-content-start sl-h-28px'>
              <div class='sl-ml-10px'>Carbon Emissions (Metric Tons)</div>
            </td>
          </tr>
          <tr v-if='!carbonEmissions.length' class='sl-table__row sl-table__row--esg sl-h-42px'>
            <td class='sl-w-30px'></td>
            <td class='sl-w-200px sl-flex-justify-content-start'>
              <div class='sl-ml-10px' style='color: #C5C5C8'>Create a new pathway</div>
            </td>
          </tr>
          <div v-else v-for='(pathway, index) in carbonEmissions'>
            <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
              <td>
                <div class='sl-w-30px sl-h-42px sl-flex-justify-align-center'>
                  <deleteIcon @click='deletePathway(pathway, index)' transform='scale(0.7)' class='figma-icon-delete links-center'/>
                </div>
              </td>
              <td class='sl-w-200px sl-flex-justify-content-start'>
                <input v-model='pathway.name' placeholder='Add name here...' type='text' class='custom-pathway-name-input' style='text-align: left;'/>
              </td>
            </tr>
            <tr class='sl-table__row sl-table__row--esg sl-h-42px' :class="{'sl-border-b-2px': !isLastRow(index, carbonEmissions)}">
              <td class='sl-w-30px'></td>
              <td>
                <select v-model='pathway.cost_units' class='custom-pathway-cost-units-select'>
                  <option value=$/tCO2e>Fee Schedule ($/tCO2e)</option>
                  <option value=$/year>Fee Schedule ($/year)</option>
                </select>
              </td>
            </tr>
          </div>
          <!-- Emission Factor Pathways -->
          <tr class='sl-h-28px sl-esg-table-sub-header-row sl-flex-align-items-center'>
            <td class='sl-w-30px'></td>
            <td class='sl-w-200px sl-flex-justify-content-start sl-h-28px'>
              <div class='sl-ml-10px'>Emission Factor (mt/MWh)</div>
            </td>
          </tr>
          <tr v-if='!emissionFactor.length' class='sl-table__row sl-table__row--esg sl-h-42px'>
            <td class='sl-w-30px'></td>
            <td class='sl-w-200px sl-flex-justify-content-start'>
              <div class='sl-ml-10px' style='color: #C5C5C8'>Create a new pathway</div>
            </td>
          </tr>
          <tr v-else v-for='pathway in emissionFactor' class='sl-table__row sl-table__row--esg sl-h-42px'>
            <td>
              <div class='sl-w-30px sl-h-42px sl-flex-justify-align-center'>
                <deleteIcon @click='deletePathway(pathway, index)' transform='scale(0.7)' class='figma-icon-delete links-center'/>
              </div>
            </td>
            <td class='sl-w-200px sl-flex-justify-content-start'>
              <input v-model='pathway.name' placeholder='Add name here...' type='text' class='custom-pathway-name-input' style='text-align: left;'/>
            </td>
          </tr>
          <!-- Energy Use Intensity Pathways -->
          <tr class='sl-h-28px sl-esg-table-sub-header-row sl-flex-align-items-center'>
            <td class='sl-w-30px'></td>
            <td class='sl-w-200px sl-flex-justify-content-start sl-h-28px'>
              <div class='sl-ml-10px'>Energy Use Intensity (kBtu/sf)</div>
            </td>
          </tr>
          <tr v-if='!energyUseIntensity.length' class='sl-table__row sl-table__row--esg sl-h-42px sl-border-b-1px'>
            <td class='sl-w-30px'></td>
            <td class='sl-w-200px sl-flex-justify-content-start'>
              <div class='sl-ml-10px' style='color: #C5C5C8'>Create a new pathway</div>
            </td>
          </tr>
          <div v-else v-for='(pathway, index) in energyUseIntensity'>
            <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
              <td>
                <div class='sl-w-30px sl-h-42px sl-flex-justify-align-center'>
                  <deleteIcon @click='deletePathway(pathway, index)' transform='scale(0.7)' class='figma-icon-delete links-center'/>
                </div>
              </td>
              <td class='sl-w-200px sl-flex-justify-content-start'>
                <input v-model='pathway.name' placeholder='Add name here...' type='text' class='custom-pathway-name-input' style='text-align: left;'/>
              </td>
            </tr>
            <tr class='sl-table__row sl-table__row--esg sl-h-42px'
                :class="{'sl-border-b-1px': isLastRow(index, energyUseIntensity),
                         'sl-border-b-2px': !isLastRow(index, energyUseIntensity)}">
              <td class='sl-w-30px'></td>
              <td>
                <select v-model='pathway.cost_units'
                        class='custom-pathway-cost-units-select'>
                  <option value=$/kBtu>Fee Schedule ($/kBtu)</option>
                  <option value=$/year>Fee Schedule ($/year)</option>
                </select>
              </td>
            </tr>
          </div>
        </tbody>
      </table>
      <div class='sl-esg-overflow-container'>
        <table class='sl-esg-decarbonization-table'>
          <thead>
            <tr class='sl-table__row sl-table__row--esg'>
              <td v-for='year in years' class='sl-w-85px sl-font-size-12px'>{{ year }}</td>
            </tr>
          </thead>
          <tbody>
            <!-- Carbon Emission Pathways -->
            <tr class='sl-table__row sl-esg-table-sub-header-row sl-h-28px'></tr>
            <tr v-if='!carbonEmissions.length' class='sl-table__row sl-table__row--esg sl-h-42px'>
              <td v-for='year in years' class='sl-w-85px' style='color: #C5C5C8'>&#8212</td>
            </tr>
            <div v-else v-for='(pathway, index) in carbonEmissions'>
              <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
                <td v-for='year in years' class='sl-w-85px'>
                  <input v-model='pathway.values[year]' @change='formatValue(pathway, year, $event)'
                         :id='`${pathway.id}_${year}_value`' type='text' placeholder='&#8212' class='sl-w-60px'/>
                </td>
              </tr>
              <tr class='sl-table__row sl-table__row--esg sl-h-42px' :class="{'sl-border-b-2px': !isLastRow(index, carbonEmissions)}">
                <td v-for='year in years' class='sl-w-85px'>
                  <input v-model='pathway.fees[year]' @change='formatValue(pathway, year, $event)'
                         :id='`${pathway.id}_${year}_fee`' type='text' placeholder='&#8212' class='sl-w-60px'/>
                </td>
              </tr>
            </div>
            <!-- Emission Factor Pathways -->
            <tr class='sl-table__row sl-esg-table-sub-header-row sl-h-28px'></tr>
            <tr v-if='!emissionFactor.length' class='sl-table__row sl-table__row--esg sl-h-42px'>
              <td v-for='year in years' class='sl-w-85px' style='color: #C5C5C8'>&#8212</td>
            </tr>
            <tr v-else v-for='pathway in emissionFactor' class='sl-table__row sl-table__row--esg sl-h-42px'>
              <td v-for='year in years' class='sl-w-85px'>
                <input v-model='pathway.values[year]' @change='formatValue(pathway, year, $event)'
                       :id='`${pathway.id}_${year}_value`' type='text' placeholder='&#8212' class='sl-w-60px'/>
              </td>
            </tr>
            <!-- Energy Use Intensity Pathways -->
            <tr class='sl-table__row sl-esg-table-sub-header-row sl-h-28px'></tr>
            <tr v-if='!energyUseIntensity.length' class='sl-table__row sl-table__row--esg sl-h-42px'>
              <td v-for='year in years' class='sl-w-85px' style='color: #C5C5C8'>&#8212</td>
            </tr>
            <div v-else v-for='(pathway, index) in energyUseIntensity'>
              <tr class='sl-table__row sl-table__row--esg sl-h-42px'>
                <td v-for='year in years' class='sl-w-85px'>
                  <input v-model='pathway.values[year]' @change='formatValue(pathway, year, $event)'
                         :id='`${pathway.id}_${year}_value`' type='text' placeholder='&#8212' class='sl-w-60px'/>
                </td>
              </tr>
              <tr class='sl-table__row sl-table__row--esg sl-h-42px'
                  :class="{'sl-border-b-1px': isLastRow(index, energyUseIntensity),
                           'sl-border-b-2px': !isLastRow(index, energyUseIntensity)}">
                <td v-for='year in years' class='sl-w-85px'>
                  <input v-model='pathway.fees[year]' @change='formatValue(pathway, year, $event)'
                         :id='`${pathway.id}_${year}_fee`' type='text' placeholder='&#8212' class='sl-w-60px'/>
                </td>
              </tr>
            </div>
          </tbody>
        </table>
      </div>
    </div>
    <div class='sl-mt-20px sl-flex-justify-content-space-btwn sl-w-100per'>
      <div class='sl-flex-align-items-center'>
        <button @click='save' class='sl-simple-outline-btn sl-w-100px'>Save</button>
      </div>
      <div class='sl-flex-align-items-center'>Click on cell to enter data</div>
      <div>
        <button @click='deleteAll' class='sl-simple-outline-btn-warning sl-w-100px'>Delete All</button>
      </div>
    </div>
  </div>
  <div class='sl-mt-200px'></div>
</template>
<script>
import axios from 'axios';
import SpinningModal from '../../../components/SpinningModal.vue';
import deleteIcon from '../../../../../assets/images/icons/Icon-Archive-Delete_Charcoal-Dark-100_24.svg';
import CustomPathwayModal from './CustomPathwayModal.vue';
import CustomPathwayImportModal from './CustomPathwayImportModal.vue';

export default {
  components: {
    SpinningModal,
    CustomPathwayModal,
    CustomPathwayImportModal,
    deleteIcon,
  },

  data() {
    const urlParams = new URLSearchParams(window.location.search);
    return {
      baselineMetricId: urlParams.get('baseline_metric_id'),
      customPathways: [],
      carbonEmissions: [],
      emissionFactor: [],
      energyUseIntensity: [],
      years: [],
      alertMessage: '',
      showLoadingModal: false,
      showCustomPathwayModal: false,
      showImportModal: false,
      dataReady: false,
    };
  },

  created() {
    this.fetchCustomPathways();
  },

  methods: {
    async fetchCustomPathways() {
      await axios.get('/decarb_custom_pathways/vue_index', {
        params: {
          baseline_metric_id: this.baselineMetricId,
        },
      })
        .then((response) => {
          this.customPathways = response.data.custom_pathways;
          this.setPathwayTypes();

          this.years = response.data.years;
          this.dataReady = true;
        })
        .catch(() => {
          this.alertMessage = 'Error fetching data';
        });
    },

    async save() {
      this.showLoadingModal = true;
      await axios.put('/decarb_custom_pathways/vue_update', {
        baseline_metric_id: this.baselineMetricId,
        pathways: this.customPathways,
      })
        .then((response) => {
          this.customPathways = response.data.custom_pathways;
          setTimeout(() => {
            this.showLoadingModal = false;
            this.alertMessage = 'Table Saved';
          }, 500);
        })
        .catch(() => {
          this.alertMessage = 'Error saving table';
          setTimeout(() => {
            this.showLoadingModal = false;
          }, 500);
        });
    },

    async deletePathway(pathway, index) {
      let message = 'Are you sure you want to delete this pathway?';
      if (pathway.name) {
        message = `Are you sure you want to delete the ${pathway.name} pathway?`;
      }
      if (!window.confirm(message)) {
        return;
      }
      this.showLoadingModal = true;
      await axios.post('/decarb_custom_pathways/vue_destroy', {
        id: pathway.id,
      })
        .then(() => {
          this.customPathways.find((pathwayType) => pathwayType.name === pathway.type).pathways.splice(index, 1);
          setTimeout(() => {
            this.showLoadingModal = false;
            this.alertMessage = 'Pathway Deleted';
          }, 500);
          setTimeout(() => {
            this.alertMessage = '';
          }, 3000);
        })
        .catch(() => {
          this.alertMessage = 'Error deleting pathway';
          setTimeout(() => {
            this.showLoadingModal = false;
          }, 500);
        });
    },

    async deleteAll() {
      if (!window.confirm('Are you sure you want to delete all custom pathways for this project?')) {
        return;
      }
      this.showLoadingModal = true;
      await axios.post('/decarb_custom_pathways/vue_destroy_all', {
        baseline_metric_id: this.baselineMetricId,
      })
        .then((response) => {
          this.customPathways = response.data.custom_pathways;
          this.setPathwayTypes();
          setTimeout(() => {
            this.showLoadingModal = false;
            this.alertMessage = 'Pathways Deleted';
          }, 500);
          setTimeout(() => {
            this.alertMessage = '';
          }, 3000);
        })
        .catch(() => {
          setTimeout(() => {
            this.showLoadingModal = false;
            this.alertMessage = 'Error clearing table';
          }, 500);
        });
    },

    async create(pathwayType, customType, pathwayName, feeScheduleUnits, percentages, goalYears) {
      const pathwayCount = this.customPathways.find((pathway_type) => pathway_type.name === pathwayType).pathways.length;
      if (pathwayCount > 3) {
        alert('You can only have 4 pathways per pathway type.');
        return;
      }
      this.showLoadingModal = true;
      this.showCustomPathwayModal = false;
      axios.post('/decarb_custom_pathways/vue_create', {
        baseline_metric_id: this.baselineMetricId,
        pathway_type: pathwayType,
        custom_type: customType,
        pathway_name: pathwayName,
        cost_units: feeScheduleUnits,
        percent_reductions: percentages,
        goal_years: goalYears,
      })
        .then((response) => {
          this.customPathways.find((pathway_type) => pathway_type.name === pathwayType).pathways.push(response.data.pathway);
          setTimeout(() => {
            this.showLoadingModal = false;
            this.alertMessage = 'Pathway Created';
          }, 500);
          setTimeout(() => { this.alertMessage = ''; }, 2000);
        })
        .catch(() => {
          this.showLoadingModal = false;
          this.alertMessage = 'Error creating pathway';
        });
    },

    setPathwayTypes() {
      this.customPathways.forEach((pathwayType) => {
        if (pathwayType.name === 'Carbon Emissions') {
          this.carbonEmissions = pathwayType.pathways;
        } else if (pathwayType.name === 'Emission Factor') {
          this.emissionFactor = pathwayType.pathways;
        } else if (pathwayType.name === 'Energy Use Intensity') {
          this.energyUseIntensity = pathwayType.pathways;
        }
      });
    },

    // This function formats the input values to replace non numerical characters and add thousandth commas
    formatValue(pathway, year) {
      let value = pathway.values[year];
      if (value === '') {
        pathway.values[year] = null; // eslint-disable-line no-param-reassign
        return;
      }
      value = value.replace(/[^\d.-]/g, '');
      value = Intl.NumberFormat().format(value);
      pathway.values[year] = value; // eslint-disable-line no-param-reassign
    },

    uploadComplete() {
      this.fetchCustomPathways();
      this.showImportModal = false;
      this.alertMessage = 'Import Complete';
      setTimeout(() => {
        this.alertMessage = '';
      }, 2000);
    },

    isLastRow(index, array) {
      return index === array.length - 1;
    },

    tableChanged() {
      this.alertMessage = '';
    },
  },
};
</script>
