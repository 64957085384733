<template>
  <div class='collapsable-container'>
    <div :class="['bar', { 'bar-open': isOpen, 'bar-closed': !isOpen }]" @click="toggleContent">
      <p>{{ barText }}</p>
      <p style='font-size: 30px;'>{{ isOpen ? '-' : '+' }}</p>
    </div>
    <div v-if="isOpen" class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    barText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isOpen: true,
    };
  },
  methods: {
    toggleContent() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style scoped>
.bar {
  color: white;
  cursor: pointer;
  text-align: center;
  user-select: none;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px 0px 10px;
}

.bar-open {
  background-color: var(--primary-blue);
}

.bar-closed {
  background-color: var(--medium-charcoal);
}

.content {
  padding-bottom: 10px;
  border-bottom: 2px solid var(--primary-blue);
}

.collapsable-container {
  margin-top: 10px;
}
</style>
