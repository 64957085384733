<template>
  <div class='modal-createdoc-loading-vue'>
    <div class='modal-createdoc-loading-content'>
      <div style='position: relative'>
        <div class='createDocSpinnerAnimate'></div>
        <div style='margin-left: 50px' id='modal-loading-text'>{{ modalLoadingText || 'Please wait...' }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['modalLoadingText'],
};
</script>
