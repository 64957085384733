<template>
  <div class='sl-flex-justify-content-center sl-pb-30px'>
    <div class='sl-w-500px'>
      <ol>
        <li class='report-table-instructions'>
          Mark “Y” in the appropriate column for listings pertaining to the subject property,
          adjoining properties, and sites of concern which can include Up-Gradient Release Sites.
        </li>
        <li class='report-table-instructions'>
          Mark “N” if there are no listings. Each box should have either Y or N.
        </li>
        <li class='report-table-instructions'>
          Each box, excluding “N/A” should have either Y or N.
        </li>
        <li class='report-table-instructions'>
          Add any database on which the subject property is listed. Add applicable state databases,
          SLIC, RWQCB, etc., to this table. Don't add FINDS, TSCA, Drycleaners, etc. if subject property
          or adjoining property are not listed.
        </li>
        <li class='report-table-instructions'>
          For Other Properties in AMSD only indicate if any sites were identified. Concern for subject
          property is marked as “Y” if it represents an environmental concern to the subject property.
        </li>
      </ol>
    </div>
  </div>
</template>
