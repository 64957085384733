<template>
  <SpinningModal v-if="showLoadingModal"/>
  <div v-if="showMainModal" id="sl-data-transfer-modal-container" class="sl-flex-only">
    <div class="sl-data-transfer-modal-content" style="max-width: 1200px;">
      <div class="sl-flex-justify-content-center sl-h-15px" style="color: red">{{ errorModalMessage }}</div>
      <div class="sl-flex-justify-content-center sl-h-15px" style="color: blue">{{ successModalMessage }}</div>
      <div class="sl-flex-justify-content-space-btwn">
        <div class="sl-flex-column sl-w-750px">
          <span class="pl-2-column-area sl-mb-10px">Photos Transfer</span>
        </div>
        <div class="sl-w-40px">
          <CloseIcon class='figma-icon' @click="closeModal" id="sl-history-log-close"/>
        </div>
      </div>
      <div class="sl-w-100per sl-mt-20px sl-border-t-2px-primary-blue"></div>
      <div class="sl-flex-justify-content-center">
        <div style='width: 33%'>
          <div class="sl-flex-column sl-flex-align-items-center sl-mt-20px">
            <p>Search for Source Project</p>
            <textarea v-model="originatingProjectNumber"
                      id="sl_data_item_comment_text"
                      class="sl-border-1px-medium-charcoal"
                      placeholder="Project Number"></textarea>
            <div class="sl-flex-justify-content-end sl-mt-10px">
              <button @click="search" id="sl-comment-save-btn" class="sl-simple-outline-btn sl-w-100px sl-h-28px">Search</button>
            </div>
          </div>
        </div>
        <div style='width: 33%'>
          <div class="sl-flex-column sl-flex-align-items-center sl-mt-20px">
            <p>Select Source Project</p>
            <div id='sl-origin-project-select'>
              <select v-model="selectedItem">
                <option disabled value="">Please select one</option>
                <option v-for="item in items" :key="item.value" :value="item.value">
                  {{ item.text }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div style='width: 33%'>
          <div class="sl-flex-column sl-flex-align-items-center sl-mt-20px">
            <p>Select Source Scope</p>
            <div id='sl-origin-project-select'>
              <select v-model="selectedSourceScope">
                <option disabled value="">Please select one</option>
                <option v-for="scope in sourceScopes" :key="scope.id" :value="scope.id">
                  {{ scope.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="sl-w-100per sl-mt-20px sl-border-t-2px-primary-blue"></div>
      <div class="sl-flex-justify-content-center">
        <div style='width: 50%'>
          <div class="sl-flex-column sl-flex-align-items-center sl-mt-20px">
            <p>Search for Destination Project(s)</p>
            <textarea v-model="destinationProjectNumber"
                      id="sl_data_item_comment_text"
                      class="sl-border-1px-medium-charcoal"
                      placeholder="Project Number"></textarea>
            <div class="sl-flex-justify-content-end sl-mt-10px">
              <button @click="searchDestination" id="sl-comment-save-btn" class="sl-simple-outline-btn sl-w-100px sl-h-28px">Search</button>
            </div>
          </div>
        </div>
        <div style='width: 50%'>
          <div class="sl-flex-column sl-flex-align-items-center sl-mt-20px">
            <p>Select Destination Project</p>
            <div id='sl-destination-project-select'>
              <select v-model="destinationSelectedItem">
                <option disabled value="">Please select one</option>
                <option v-for="item in destinationItems" :key="item.value" :value="item.value">
                  {{ item.text }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div style='width: 50%'>
          <div class="sl-flex-column sl-flex-align-items-center sl-mt-20px">
            <p>Select Destination Scope</p>
            <div id='sl-destination-project-select'>
              <select v-model="destinationScopeSelectedItem">
                <option disabled value="">Please select one</option>
                <option v-for="scope in destinationScopes" :key="scope.id" :value="scope.id">
                  {{ scope.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="sl-w-100per sl-mt-20px sl-border-t-2px-primary-blue"></div><br>
      <div class='sl-mlr-20px sl-text-align-center sl-flex-justify-content-center'>
        <p class='sl-mlr-20px'>Include Captions?</p>
        <input type="checkbox" id="include-caption" name="include-caption" v-model="include_caption">
      </div>
      <br>
      <div class='sl-mlr-20px sl-text-align-center'>
        <button @click="transfer_photos" id="sl-comment-save-btn" class="sl-simple-outline-btn sl-w-200px sl-h-28px">Transfer Photos</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import CloseIcon from '../../../assets/images/icons/Icon-Close_Charcoal-Dark-100_24.svg';
import SpinningModal from './SpinningModal.vue';

export default {
  components: {
    CloseIcon,
    SpinningModal,
  },
  data() {
    return {
      showMainModal: false,
      showLoadingModal: false,
      originatingProjectNumber: '',
      successModalMessage: '',
      errorModalMessage: '',
      selectedItem: '',
      selectedSourceScope: '',
      destinationScopeSelectedItem: '',
      items: [],
      scopes: [],
      sourceScopes: [],
      destinationScopes: [],
      destinationSelectedItem: '',
      destinationItems: [],
      include_caption: false,
    };
  },
  async created() {
    $jq(document).on('click', '#photos-transfer-modal-btn', (event) => {
      this.showLoadingModal = true;
      const projectId = event.target.getAttribute('project_id');
      const projectType = event.target.getAttribute('project_type');
      this.fetchModalData(projectId, projectType);
    });
  },
  methods: {
    async fetchModalData(projectId, projectType) {
      await axios.get('/projects/show_vue_photos_transfer_modal', {
        params: {
          project_id: projectId,
          project_type: projectType,
        },
      })
        .then((response) => {
          this.originatingProjectNumber = response.data.originatingProjectNumber;
          this.destinationProjectNumber = response.data.originatingProjectNumber;
          this.showLoadingModal = false;
          this.showMainModal = true;
          this.items = response.data.items;
          this.destinationItems = response.data.items;
          this.destinationSelectedItem = response.data.selectedItem;
          this.selectedItem = response.data.selectedItem;
          this.selectedSourceScope = response.data.selectedSourceScope;
          this.destinationScopeSelectedItem = response.data.selectedSourceScope;
        })
        .catch(() => {
          this.showLoadingModal = false;
          alert('An error occured. Please reach out to admin@sitelynx.net for support.');
        });
    },
    async search() {
      await axios.get('/projects/search_projects', {
        params: {
          search: this.originatingProjectNumber,
        },
      })
        .then((response) => {
          this.items = response.data.items;
          this.selectedItem = '';
          this.sourceScopes = [];
          this.selectedSourceScope = '';
          this.showSuccessMessage(`Found ${this.items.length} projects.`);
        })
        .catch(() => {
          this.showErrorMessage('An error has occurred and your search was unsuccessful.');
        });
    },

    async searchDestination() {
      await axios.get('/projects/search_projects', {
        params: {
          search: this.destinationProjectNumber,
        },
      })
        .then((response) => {
          this.destinationItems = response.data.items;
          this.destinationSelectedItem = '';
          this.destinationScopes = [];
          this.destinationScopeSelectedItem = '';
          this.showSuccessMessage(`Found ${this.destinationItems.length} projects.`);
        })
        .catch(() => {
          this.showErrorMessage('An error has occurred and your search was unsuccessful.');
        });
    },

    async transfer_photos() {
      this.showLoadingModal = true;
      this.showMainModal = false;
      try {
        const response = await axios.get('/locationpics/copy_project_photos_to_scope', {
          params: {
            current_scope_id: this.selectedSourceScope,
            current_project_id: this.selectedItem,
            destination_scope_id: this.destinationScopeSelectedItem,
            destination_project_ids: [this.destinationSelectedItem],
            include_caption: this.include_caption,
          },
        });

        this.showLoadingModal = false;
        this.showMainModal = true;
        
        if (response.data.success) {
          this.showSuccessMessage(response.data.message);
        } else {
          this.showErrorMessage(response.data.message);
        }
      } catch (error) {
        this.showLoadingModal = false;
        this.showMainModal = true;
        this.showErrorMessage('An error has occurred and your photo transfer was unsuccessful.');
      }
    },

    closeModal() {
      this.showMainModal = false;
    },

    showSuccessMessage(message) {
      this.successModalMessage = message;
      // Use setTimeout to clear the message after 10 seconds
      setTimeout(() => {
        this.successModalMessage = '';
      }, 10000);
    },

    showErrorMessage(message) {
      this.errorModalMessage = message;
      // Use setTimeout to clear the message after 10 seconds
      setTimeout(() => {
        this.errorModalMessage = '';
      }, 10000);
    },

    async handleSourceProjectSelect() {
      console.log('handleSourceProjectSelect');
      if (!this.selectedItem) return;
      
      try {
        const response = await axios.get('/projects/available_scopes', {
          params: { project_id: this.selectedItem }
        });
        this.sourceScopes = response.data;
        this.selectedSourceScope = ''; // Reset scope selection
      } catch (error) {
        this.showErrorMessage('Failed to fetch available scopes');
      }
    },

    async handleDestinationProjectSelect() {
      if (!this.destinationSelectedItem) return;
      
      try {
        const response = await axios.get('/projects/available_scopes', {
          params: { project_id: this.destinationSelectedItem }
        });
        this.destinationScopes = response.data;
        this.destinationScopeSelectedItem = ''; // Reset scope selection
      } catch (error) {
        this.showErrorMessage('Failed to fetch available scopes');
      }
    },
  },
  watch: {
    selectedItem() {
      this.handleSourceProjectSelect();
    },
    destinationSelectedItem() {
      this.handleDestinationProjectSelect();
    }
  },
};
</script>

<style>

</style>
