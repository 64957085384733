<!-- Currently only yes/no fields used for the pca building summary table -->
<template>
  <br>
  <form class='main-form sl-mt-20px'>
    <div class='ws-group-item'>
      <div class='content_report'>
        <table class='centered'>
          <tr v-for='(field, index) in fields'>
            <td style='display: flex; align-items: baseline;'>
              <div class='sl-w-200px'>{{ field.question_text }}</div>
              <div>
                <div class='sl-yes-no-field'>
                  <input type='radio' :name="field.name"/>
                  <label  :for="`${field.name}_yes`" class="sl-mr-5px"
                          :class="{ 'selected':field.data=='yes'}"
                          @click="this.$emit('update-field', index, 'yes')">Yes
                  </label>

                  <input type='radio' :name="field.name"/>
                  <label :for="`${field.name}_no`" class="sl-mr-5px"
                         :class="{ 'selected':field.data=='no'}"
                         @click="this.$emit('update-field', index, 'no')">No
                  </label>

                  <input type='radio' :name="field.name"/>
                  <label :for="`${field.name}_no`" class="sl-mr-5px"
                         :class="{ 'selected':field.data=='none'}"
                         @click="this.$emit('update-field', index, 'none')">N/A
                  </label>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </form>
  <br><hr><br>
</template>
<script>
export default {
  props: ['projectId', 'fields'],
};
</script>
<style scoped>
  .selected {
    background-color: #005199 !important;
    color: white !important;
  }

  .selected:hover {
    background-color: #005199;
    color: white !important;
  }
</style>
