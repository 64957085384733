<template>
  <div class='sl-flex-justify-content-center sl-pb-10px'>
    <div class='sl-w-500px'>
      <div class='sl-flex-justify-content-and-align-items-center sl-mb-20px'>
        <div class='sl-box-shadow-1 sl-p-all-10px'>
          <div class='sl-mb-10px'>
            <strong>Upload JSON Data:</strong>
          </div>
          <form @submit.prevent='submit' class='sl-flex-column'>
            <input type='file' name='file' accept='.json'
                   class='sl-mb-10px file-input'
                   id='upload_datafile'
                   ref='upload_datafile'
                   @change='setJsonFile'/>
            <button class='sl-simple-outline-btn sl-w-100px'
                    :class="{'sl-simple-outline-btn-disabled':!file}">
                    Upload
            </button>
          </form>
        </div>
      </div>
      <span class='report-table-instructions sl-font-size-14px sl-bold-text'>
        Discuss maps starting from OLDEST to NEWEST. For each map reviewed:
      </span>
      <ol class="instructions-list">
        <li class='report-table-instructions'>
          Outline the subject property boundaries with a red line- ensure the boundaries are correct on each map and adjust accordingly.
        </li>
        <li class='report-table-instructions'>
          Discuss observations of the subject property first then discuss adjoining properties as a separate paragraph.
        </li>
        <li class='report-table-instructions'>
          For the subject property: include the number of buildings or other features and locations (northern portion,
          central portion, throughout the subject property, etc.).
        </li>
        <li class='report-table-instructions'>
          For the subject property and adjoining properties: provide a detailed description of site buildings and features as labeled.
          This may include building types (dwelling, store, stable, etc.), date of construction, structural elements, labeled tenants/uses, business
          <a href='http://web.mit.edu/thecity/resources/abbreviations_for_sanborn_maps.pdf' target='_blank'>
            http://web.mit.edu/thecity/resources/abbreviations_for_sanborn_maps.pdf
          </a>
        </li>
        <li class='report-table-instructions'>
          For the subject property and adjoining properties: Discuss all uses, business types, industries, etc.,
          that may have included PFAS use and generation in addition to other hazardous substances.
          See PFAS guidance doc and Red Flag Industries table (Partner PFAS Guidance Doc for ESAs.pdf).
        </li>
        <li class='report-table-instructions'>
          For the subject property and adjoining properties: include all addresses depicted on the map and be sure to submit FOIAs to
          health, fire, air quality, water quality, state environmental dept, building dept, etc.
        </li>
        <li class='report-table-instructions'>
          Put maps oldest to newest in the template.
        </li>
      </ol>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    this.project_id = urlParams.get('project_id');
  },

  data() {
    return {
      project_id: null,
      file: null,
    };
  },

  methods: {
    async submit() {
      const text = 'Caution: This will overwrite existing table data. Are you sure you want to import this file?';
      if (!window.confirm(text)) { return; }
      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('project_id', this.project_id);
      formData.append('the_group', 'sanborn fire');
      this.file = null;
      await axios.post('/esa_report_tables/import_json_data', formData)
        .then(() => {
          this.$emit('importComplete');
        });
    },

    setJsonFile() {
      const file = this.$refs.upload_datafile.files[0];
      if (file && file.type === 'application/json') {
        this.file = file;
      }
    },
  },
};
</script>
