<template>
  <xIcon class="sl-cursor-pointer" @click="show_modal" />

  <div v-if="modal_visibility" class="vue-modal-background">
    <div class="vue-modal-content sl-text-align-center sl-flex-justify-align-center" style="height: 160px;">
      <div>
        <p class="sl-paragraph-med-bold sl-partner-blue-text">Are you sure?</p>

        <p class="sl-paragraph-med">Continuing will cancel this order. This action cannot be undone.</p>

        <p class="sl-mt-30px">
          <button class="sl-simple-outline-btn sl-mr-30px" @click="cancel_close_modal">No, Go Back</button>
          <a class="sl-simple-outline-btn" href="/">Continue & Cancel Order</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import xIcon from '../../../../assets/images/icons/Icon-Clear_Charcoal-Dark-100_24.svg';

export default {
  components: {
    xIcon,
  },

  data() {
    return {
      modal_visibility: false,
    };
  },

  methods: {
    show_modal() {
      this.modal_visibility = true;
    },

    cancel_close_modal() {
      this.modal_visibility = false;
    },
  },
};
</script>

<style scoped>
  .vue-modal-background {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.1);
  }

  .vue-modal-content {
    background-color: #fefefe;
    box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.25);
    padding: 20px;
    width: 100%;
    max-width: 600px;
    max-height: 80%;
    overflow: auto;
  }
</style>
