<template>
  <div class="sl-bluelynx-gen-drag-drop">
    <form class="dropzone dz-clickable" :id="'file-' + projectdoc_type" controller="projectdocs" action="/projectdocs" enctype="multipart/form-data" accept-charset="UTF-8" data-remote="true" method="post">
      <input type="hidden" name="projectdoc[project_id]" id="projectdoc_project_id" :value="project_id" autocomplete="off" >
      <input type="hidden" :name="projectdoc_type" :id="projectdoc_type" value="true" autocomplete="off">
      <input type="hidden" name="report_type" id="report_type" :value=report_type autocomplete="off">
      <div class="dz-message sl-working-doc-drag-and-drop-zone" data-dz-message>
        <upload class="sl-svg-icon-size-3 figma-icon-medium-charcoal" />
        <div class="sl-drag-and-drop-text-container" style="width: 165px;">
          <div class="sl-drag-and-drop-main-text sl-font-size-14px">
            Upload {{ projectdoc_friendly_type }}
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import upload from "../../../assets/images/icons/Icon-Add_Charcoal-Dark-100_24.svg"

export default {
  components: {
    upload,
  },

  props: {
    projectdoc: Object,
    projectdoc_type: String,
    projectdoc_friendly_type: String,
    report_type: String,
    max_files: {
      type: Number,
      default: 1 // Default value
    },
    file_types: {
      type: String,
      default: 'application/pdf' // Default value
    },
  },

  data() {
    return {
      project_id: null,
    };
  },

  async created() {
    const urlParams = new URLSearchParams(window.location.search);
    const project_id = urlParams.get('id');
    this.project_id = project_id;
  },
  mounted() {
    this.$nextTick(() => {
      // load dropzone after the component is mounted
      dropzone_file_auto_upload_area('file-' + this.projectdoc_type, this.max_files, this.file_types);
    });
  },
};
</script>
