<template>
  <SpinningModal v-if="showLoadingModal"/>
  <div v-if="showCommentModal" id="sl-history-log-modal-container" class="sl-flex-only">
    <div class="sl-history-log-modal-content" style="max-width: 1200px;">
      <div class="sl-flex-justify-content-center sl-h-15px" style="color: red">{{ modalMessage }}</div>
      <div class="sl-flex-justify-content-space-btwn">
        <div class="sl-flex-column sl-w-750px">
          <span class="pl-2-column-area sl-mb-10px">Question: {{ fieldQuesiton }}</span>
          <span class="sl-font-size-14px">Answer: {{ fieldData }}</span>
        </div>
        <div class="sl-w-40px">
          <CloseIcon class='figma-icon' @click="closeModal" id="sl-history-log-close"/>
        </div>
      </div>
      <div class="sl-flex-justify-content-center">
        <table v-if="dataItemComments.length" class="sl-mt-20px sl-mb-10px sl-border-tb-2px-primary-blue sl-w-100per">
          <tbody>
            <tr v-for="(dataItemComment, index) in dataItemComments" class="sl-h-32px sl-table__row--striped">
              <td class="sl-w-100px sl-pl-10px">{{ dataItemComment.user }}</td>
              <td class="sl-plr-10px sl-ptb-10px sl-w-600px">
                <textarea v-if="editCommentIndex === index" v-model="editCommentText" class="sl-w-100per sl-h-60px"></textarea>
                <span v-else>{{ dataItemComment.comment }}</span>
              </td>
              <td class="sl-w-100px sl-ptb-10px sl-text-align-center">
                <div>{{ dataItemComment.date }}</div>
                <div>{{ dataItemComment.time }}</div>
                <div v-if="dataItemComment.edited" class="sl-mt-5px" style="color: #696E76">Edited</div>
              </td>
              <td class="sl-w-85px sl-text-align-center">
                <div v-if="editCommentIndex === index">
                  <button @click="update(index, dataItemComment.id)" class="sl-simple-outline-btn sl-w-70px sl-mtb-10px">Save</button>
                  <button @click="deleteComment(index, dataItemComment.id)" class="sl-simple-outline-btn-warning sl-w-70px sl-mb-10px">Delete</button>
                  <button @click="cancel(index)" class="sl-simple-outline-btn sl-w-70px sl-mb-10px">Cancel</button>
                </div>
                <div v-else>
                  <a v-if="dataItemComment.userCanEdit" @click="edit(index)" class="sl-blue-link pl-circle-edit-btn sl-tooltip">
                    <editIcon class="figma-icon"/>
                    <span class="sl-tooltiptext">Edit</span>
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- Top blue border if the table is empty -->
        <div v-else class="sl-w-100per sl-mt-20px sl-border-t-2px-primary-blue"></div>
      </div>

      <!-- Bottom Text Area -->
      <div class="sl-flex-column sl-flex-align-items-center sl-mt-20px">
        <textarea v-model="newCommentText"
                  id="sl_data_item_comment_text"
                  class="sl-w-600px sl-h-84px sl-border-1px-medium-charcoal"
                  placeholder="Add your comment here..."></textarea>
        <div class="sl-flex-justify-content-end sl-w-600px sl-mt-10px">
          <button @click="create" id="sl-comment-save-btn"
                  :class="{ 'sl-simple-outline-btn-disabled': !newCommentText.length }"
                  class="sl-simple-outline-btn sl-w-100px sl-h-28px">Comment</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import CloseIcon from '../../../../assets/images/icons/Icon-Close_Charcoal-Dark-100_24.svg';
import editIcon from '../../../../assets/images/icons/Icon-Edit_Charcoal-Dark-100_20.svg';
import SpinningModal from '../../components/SpinningModal.vue';

export default {
  components: {
    CloseIcon,
    SpinningModal,
    editIcon,
  },
  data() {
    return {
      dataItemId: null,
      dataItemComments: [],
      fieldQuesiton: null,
      fieldData: null,
      showCommentModal: false,
      showLoadingModal: false,
      newCommentText: '',
      modalMessage: '',
      editCommentIndex: null,
      editCommentText: '',
    };
  },

  async created() {
    // When a comment link next to a field is clicked
    $jq(document).on('click', '.sl_show_comment_modal_btn', (event) => {
      this.showLoadingModal = true;
      const projectId = event.target.getAttribute('project_id');
      const fieldName = event.target.getAttribute('field_name');
      this.fetchDataItemComments(projectId, fieldName);
    });
  },

  methods: {
    async fetchDataItemComments(projectId, fieldName) {
      await axios.get('/data_item_comments/vue_show', {
        params: {
          project_id: projectId,
          field_name: fieldName,
        },
      })
        .then((response) => {
          this.dataItemId = response.data.dataItemId;
          this.dataItemComments = response.data.dataItemComments;
          this.fieldQuesiton = response.data.fieldQuesiton;
          this.fieldData = response.data.fieldData;
          this.showLoadingModal = false;
          this.showCommentModal = true;
        })
        .catch((error) => {
          this.showLoadingModal = false;
          console.log(error);
          alert('An error occured loading field comments. Please reach out to admin@sitelynx.net for support.');
        });
    },

    async create() {
      await axios.post('/data_item_comments', null, {
        params: {
          data_item_id: this.dataItemId,
          comment: this.newCommentText,
        },
      })
        .then((response) => {
          this.dataItemComments.push(response.data.dataItemComment);
          this.newCommentText = '';
          this.modalMessage = '';
        })
        .catch((error) => {
          console.log(error);
          this.modalMessage = 'An error has occurred and your changes have not been saved.';
        });
    },

    async update(index, id) {
      this.editCommentIndex = null;
      await axios.put(`/data_item_comments/${id}`, null, {
        params: {
          comment: this.editCommentText,
        },
      })
        .then((response) => {
          this.dataItemComments[index] = response.data.dataItemComment;
          this.editCommentText = '';
        })
        .catch((error) => {
          console.log(error);
          this.modalMessage = 'An error has occurred and your changes have not been saved.';
        });
    },

    async deleteComment(index, id) {
      if (confirm('Are you sure you want to delete this comment?')) {
        await axios.delete(`/data_item_comments/${id}`)
          .then(() => {
            this.dataItemComments.splice(index, 1);
            this.editCommentIndex = null;
            this.editCommentText = '';
          })
          .catch((error) => {
            console.log(error);
            this.modalMessage = 'An error has occurred and your changes have not been saved.';
          });
      }
    },

    edit(index) {
      this.editCommentIndex = index;
      this.editCommentText = this.dataItemComments[index].comment;
    },

    cancel() {
      this.editCommentIndex = null;
    },

    closeModal() {
      this.showCommentModal = false;
      this.modalMessage = '';
    },
  },
};
</script>
