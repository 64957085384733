<template>
  <SpinningModal v-if='showLoadingModal'/>
  <EspmDataTransferModal v-if='showDataModal'
    @closeModal='showDataModal = false'/>
  <!-- Data Transfer Icon -->
  <div class='sl-mb-10px sl-flex-justify-content-end sl-h-28px'>
    <div v-if="user.permission==='DB Admin'" class='sl-tooltip'>
      <dataTransferIcon @click='showDataModal = true' class='figma-icon'/>
      <span class='sl-tooltiptext'>Property Data Transfer (DB Admin Only)</span>
    </div>
  </div>

  <div v-if='dataReady'>
    <div class='sl-mb-20px sl-flex-justify-content-space-btwn sl-flex-align-items-center'>
      <div class='sl-page-title'>Energy Star Portfolio Manager</div>
      <!-- Search Bar -->
      <form @submit.prevent='search'>
        <div class='sl-mr-20px'>
          <div class='sl-search-form-components'>
            <searchIcon class='figma-icon sl-search-icon'/>
            <div class='sl-search-form-vertical-bar '></div>
            <input v-model='searchInput'
                  type='text' class='sl-search-form-input'
                  placeholder='Search by property name or number'/>
          </div>
        </div>
      </form>
    </div>

    <table class='sl-generic-table-2 sl-border-b-2px'>
      <thead class='sl-border-t-2px-primary-blue'>
        <tr class='sl-bold-text'>
          <th class='sl-min-w-100px'>ID</th>
          <th class='sl-w-150px'>Name</th>
          <th>City</th>
          <th>State</th>
          <th>Current Score</th>
          <th>Meters</th>
          <th>Year Built</th>
          <th>Last Updated</th>
          <th></th>
        </tr>
      </thead>
      <tbody v-if='properties.length'>
        <tr v-for='property in properties'>
          <td><a :href="`/espm_properties/${property.id}`">{{ property.espm_api_property_id }}</a></td>
          <td>{{ property.name }}</td>
          <td>{{ property.city }}</td>
          <td>{{ property.state }}</td>
          <td>{{ property.most_recent_score }}</td>
          <td>{{ property.number_of_meters }}</td>
          <td>{{ property.year_built }}</td>
          <td>{{ property.most_recent_update }}</td>
          <td class="sl-w-30px sl-flex-justify-align-center">
            <deleteIcon @click="deleteProperty(property)" transform='scale(0.8)' class="figma-icon-delete links-center"/>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan='9' class='sl-text-align-center sl-font-size-14px'>No properties found</td>
        </tr>
      </tbody>
    </table>
    <Paginate v-if='numberOfPages && currentPage'
      :numberOfPages='numberOfPages'
      :currentPage='currentPage'
      @change-page='changePage'
    />
  </div>
</template>
<script>
import axios from 'axios';
import searchIcon from '../../../../../assets/images/icons/Icon-Search_Charcoal-Dark-100_20.svg';
import dataTransferIcon from '../../../../../assets/images/icons/Icon-Data-Transfer_Charcoal-Dark-100_16.svg';
import Paginate from '../../../components/Paginate.vue';
import SpinningModal from '../../../components/SpinningModal.vue';
import EspmDataTransferModal from './EspmPropertiesDataTransferModal.vue';
import deleteIcon from '../../../../../assets/images/icons/Icon-Archive-Delete_Charcoal-Dark-100_24.svg';

export default {
  components: {
    Paginate,
    SpinningModal,
    EspmDataTransferModal,
    searchIcon,
    dataTransferIcon,
    deleteIcon,
  },

  data() {
    const urlParams = new URLSearchParams(window.location.search);
    return {
      properties: [],
      searchInput: urlParams.get('search'),
      currentPage: urlParams.get('page'),
      numberOfPages: null,
      showLoadingModal: false,
      showDataModal: false,
      user: {},
      dataReady: false,
    };
  },

  async created() {
    this.fetchProperties();
  },

  methods: {
    async fetchProperties() {
      await axios.get('/espm_properties/return_all_properties', {
        params: {
          page: this.currentPage,
          search: this.searchInput,
        },
      })
        .then((response) => {
          this.properties = response.data.properties;
          this.numberOfPages = response.data.number_of_pages;
          this.user = response.data.user;
          this.dataReady = true;
        })
        .catch(() => {
          alert('Something went wrong while fetching properties');
        });
    },

    async search() {
      this.showLoadingModal = true;
      this.currentPage = 1;
      await this.fetchProperties()
        .then(() => {
          const params = new URLSearchParams();
          params.set('page', this.currentPage);
          if (this.searchInput) {
            params.set('search', this.searchInput);
          }
          history.replaceState(null, null, `?${params.toString()}`); // eslint-disable-line no-restricted-globals
          setTimeout(() => {
            this.showLoadingModal = false;
          }, 300);
        })
        .catch(() => {
          this.showLoadingModal = false;
          alert('Something went wrong while searching for properties');
        });
    },

    changePage(pageNumber) {
      window.location.href = `/espm_properties?page=${pageNumber}`;
    },

    async deleteProperty(property) {
      this.showLoadingModal = true;
      const confirmDelete = window.confirm(`Are you sure you want to delete ${property.name}?`);
      if (confirmDelete) {
        await axios.delete(`/espm_properties/${property.id}`)
          .then(() => {
            this.properties = this.properties.filter((prop) => prop.id !== property.id);
          })
          .catch(() => {
            alert('Something went wrong while deleting the property');
          })
          .finally(() => {
            setTimeout(() => {
              this.showLoadingModal = false;
            }, 300);
          });
      }
    },
  },
};
</script>
<style scoped>
  tbody tr:nth-child(odd) {
    background: var(--light-charcoal);
  }
</style>
