<template>
  <SpinningModal v-if="showLoadingModal"/>
  <DataItemEmailModal v-if="showEmailModal"
    @close-modal="closeEmailModal"
    @send-email="sendEmail"
    :contacts="contacts"
    :staff="staff"
    :staffSelected="staffSelected"
  />
  <div class="pl-2-column-area sl-bold-text sl-mb-20px" style="color: #005199">Reviewer Feedback</div>
  <div class="sl-flex-justify-content-center sl-h-15px sl-mb-5px" style="color: red">{{ alertMessage }}</div>
  <table class="sl-border-tb-2px-primary-blue sl-w-100per">
    <tr class="sl-bold-text sl-h-28px">
      <td class="sl-text-align-center sl-w-40px">Section</td>
      <td class="sl-text-align-center sl-w-150px">Question</td>
      <td class="sl-text-align-center sl-w-300px">Answer</td>
      <td class="sl-text-align-center">Comments</td>
    </tr>
  </table>
  <div v-if="dataItems.length">
    <table  class="sl-w-100per sl-border-b-2px">
      <tr v-for="(dataItem, dataItemIndex) in dataItems" class="sl-table__row--striped-odd">
        <td class="sl-w-40px sl-ptb-10px sl-text-align-center">{{ dataItem.section }}</td>
        <td class="sl-w-150px sl-ptb-10px sl-pl-10px">{{ dataItem.question }}</td>
        <td class="sl-w-300px sl-ptb-10px sl-pl-10px">{{ dataItem.answer }}</td>
        <td class="sl-ptb-10px">
          <div v-for="(comment, commentIndex) in dataItem.comments" class="sl-mb-10px sl-flex-only sl-pl-5px">
            <div class="sl-white-space-no-wrap sl-mr-10px sl-w-85px">{{ comment.user }}:</div>
            <div v-if="editIndex === dataItemIndex && comment.userCanEdit" class="sl-flex-align-items-center sl-w-100per">
              <textarea v-model="comment.comment" class="sl-w-100per sl-mr-10px sl-h-60px"></textarea>
              <a class="sl-tooltip">
                <deleteIcon @click="deleteComment(comment.id, dataItemIndex, commentIndex)"
                            class="sl-mr-5px sl-blue-link figma-icon"
                            transform='scale(0.8)'/>
                <span class="sl-tooltiptext">Delete</span>
              </a>
            </div>
            <div v-else>{{ comment.comment }}</div>
          </div>
        </td>
        <td class="sl-w-85px sl-text-align-center">
          <div v-if="editIndex === dataItemIndex">
            <button @click="save(dataItemIndex)" class="sl-simple-outline-btn sl-w-70px sl-mtb-10px">Save</button>
            <button @click="cancel(dataItemIndex)" class="sl-simple-outline-btn sl-w-70px sl-mb-10px">Cancel</button>
          </div>
          <a v-else-if="showEditButton(dataItem)" @click="edit(dataItemIndex)" class="sl-blue-link pl-circle-edit-btn sl-tooltip">
            <editIcon class="figma-icon"/>
            <span class="sl-tooltiptext">Edit</span>
          </a>
        </td>
      </tr>
    </table>
    <br>
    <br>
    <div class='sl-submit-report sl-mtb-20px sl-display-flex-justify-content-start'>
      <button @click="openEmailModal" class="pl-blue-border-and-fill-text-rect-btn">Email Comments</button>
    </div>
  </div>
  <div v-else class="sl-w-100per sl-border-b-2px sl-h-95px sl-flex-justify-content-center sl-flex-align-items-center">
    <span class="sl-font-size-16px">There are no comments for this report.</span>
  </div>
</template>

<script>
import axios from 'axios';
import SpinningModal from '../../components/SpinningModal.vue';
import DataItemEmailModal from './DataItemCommentsEmailModal.vue';
import editIcon from '../../../../assets/images/icons/Icon-Edit_Charcoal-Dark-100_20.svg';
import deleteIcon from '../../../../assets/images/icons/Icon-Archive-Delete_Charcoal-Dark-100_20.svg';

export default {
  components: {
    SpinningModal,
    DataItemEmailModal,
    editIcon,
    deleteIcon,
  },

  data() {
    const urlParams = new URLSearchParams(window.location.search);
    return {
      projectScopeId: urlParams.get('project_scope_id'),
      dataItems: [],
      contacts: [],
      staff: [],
      staffSelected: [],
      editIndex: null,
      showEmailModal: false,
      showLoadingModal: false,
      alertMessage: '',
    };
  },

  async created() {
    this.fetchDataItemComments();
  },

  methods: {
    async fetchDataItemComments() {
      this.showLoadingModal = true;
      await axios.get('/data_item_comments/vue_index', {
        params: {
          project_scope_id: this.projectScopeId,
        },
      })
        .then((response) => {
          this.dataItems = response.data.pcaDataItems;
          this.contacts = response.data.contacts;
          this.staff = response.data.staff;
          this.showLoadingModal = false;
        })
        .catch((error) => {
          this.showLoadingModal = false;
          console.log(error);
        });
    },

    edit(index) {
      this.editIndex = index;
    },

    cancel() {
      this.editIndex = null;
    },

    async save(index) {
      this.showLoadingModal = true;
      this.dataItems[index].comments.forEach((comment) => {
        this.update(comment);
      });
      this.editIndex = null;
    },

    async update(comment) {
      this.editIndex = null;
      await axios.put(`/data_item_comments/${comment.id}`, null, {
        params: {
          comment: comment.comment,
        },
      })
        .then(() => {
          setTimeout(() => {
            this.showLoadingModal = false;
          }, 300);
        })
        .catch((error) => {
          this.showLoadingModal = false;
          console.log(error);
        });
    },

    async deleteComment(id, dataItemIndex, commentIndex) {
      if (confirm('Are you sure you want to delete this comment?')) {
        await axios.delete(`/data_item_comments/${id}`)
          .then(() => {
            const dataItem = this.dataItems[dataItemIndex];
            dataItem.comments.splice([commentIndex], 1);
            // Delete the data item from the page if it does not have any comments left
            if (!dataItem.comments.length) {
              this.dataItems.splice(dataItemIndex, 1);
            }
            // If there are no more comments left that the user can edit, remove the edit button
            if (!this.showEditButton(dataItem)) {
              this.editIndex = null;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    async sendEmail() {
      this.showEmailModal = false;
      this.showLoadingModal = true;
      await axios.post('data_item_comments/send_email', null, {
        params: {
          project_scope_id: this.projectScopeId,
          project_contacts: this.contacts,
          staff_contacts: this.staffSelected,
        },
      })
        .then(() => {
          this.contacts.forEach((contact) => contact.selected = false);
          this.staffSelected = [];
          this.showLoadingModal = false;
          this.alertMessage = 'Email successfully sent';
        })
        .catch((error) => {
          this.contacts.forEach((contact) => contact.selected = false);
          this.showLoadingModal = false;
          console.log(error);
          this.alertMessage = 'Unable to send email, contact admin@sitelynx.net for support';
        });
    },

    // Show the edit button next to comments that the user wrote
    showEditButton(dataItem) {
      let value = false;
      dataItem.comments.forEach((comment) => {
        if (comment.userCanEdit) {
          value = true;
        }
      });
      return value;
    },

    openEmailModal() {
      this.showEmailModal = true;
    },

    closeEmailModal() {
      this.showEmailModal = false;
      this.modalMessage = '';
    },
  },
};
</script>
