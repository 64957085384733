<template>
  <div class='sl-text-align-center sl-h-15px' style='color: red'>{{ notice }}</div>
  <div class='sl-flex-justify-content-center sl-generic-form'>
    <div class='sl-mlr-5px sl-mt-10px sl-w-30px sl-text-align-center'><!--Place Holder--></div>
    <form @submit.prevent="update">
      <div class='sl-mb-40px'>
        <h2 class='sl-static-h2-style sl-text-align-left'>Change Password</h2>

        <ul>
          <li class='sl-flex-align-items-center'>
            <div class='sl-mr-5px sl-w-16px sl-h-12px'>
              <span v-if='req1' class='sl-font-size-16px sl-bold-text' style='color: green'>&#10003</span>
              <span v-else>&#10060</span>
            </div>
            <span class='sl-font-size-14px'>Password must match password confirmation</span>
          </li>

          <li class='sl-flex-align-items-center'>
            <div class='sl-mr-5px sl-w-16px sl-h-12px'>
              <span v-if='req2' class='sl-font-size-16px sl-bold-text' style='color: green'>&#10003</span>
              <span v-else>&#10060</span>
            </div>
            <span class='sl-font-size-14px'>Password must be at least 12 characters long</span>
          </li>

          <li class='sl-flex-align-items-center'>
            <div class='sl-mr-5px sl-w-16px sl-h-12px'>
              <span v-if='req3' class='sl-font-size-16px sl-bold-text' style='color: green'>&#10003</span>
              <span v-else>&#10060</span>
            </div>
            <span class='sl-font-size-14px'>Password must contain at least one number</span>
          </li>

          <li class='sl-flex-align-items-center'>
            <div class='sl-mr-5px sl-w-16px sl-h-12px'>
              <span v-if='req4' class='sl-font-size-16px sl-bold-text' style='color: green'>&#10003</span>
              <span v-else>&#10060</span>
            </div>
            <span class='sl-font-size-14px'>Password must contain at least one lowercase letter</span>
          </li>

          <li class='sl-flex-align-items-center'>
            <div class='sl-mr-5px sl-w-16px sl-h-12px'>
              <span v-if='req5' class='sl-font-size-16px sl-bold-text' style='color: green'>&#10003</span>
              <span v-else>&#10060</span>
            </div>
            <span class='sl-font-size-14px'>Password must contain at least one capital letter</span>
          </li>

          <li class='sl-flex-align-items-center'>
            <div class='sl-mr-5px sl-w-16px sl-h-12px'>
              <span v-if='req6' class='sl-font-size-16px sl-bold-text' style='color: green'>&#10003</span>
              <span v-else>&#10060</span>
            </div>
            <span class='sl-font-size-14px'>Password must contain at least one special character</span>
          </li>
        </ul>

        <div class='sl-pt-20px sl-pb-5px'>New Password</div>
        <div class='pseudo-input'>
          <input @input='checkRequiremnts()' v-model='password' type='password'
               size='38' id='user_password' class='hidden-input' autocomplete='new-password'
               :disabled="disableInputs">
               <showPasswordIcon @click='toggleShowOrHidePassword' class='figma-icon sl-mr-5px'/>
        </div>

        <div class='sl-pt-20px sl-pb-5px'>Re-type New Password</div>
        <div class='pseudo-input'>
          <input @input='checkRequiremnts()' v-model='passwordConfirmation' type='password'
               size='38' id='password_confirmation' class='hidden-input' autocomplete='new-password'
               :disabled="disableInputs">
               <showPasswordIcon @click='toggleShowOrHidePassword' class='figma-icon sl-mr-5px'/>
        </div>
      </div>

      <div class='sl-mtb-20px sl-text-align-center'>
        <button :class="buttonClass" id='bottom-update-button'>Update</button>
      </div>
    </form>

    <div v-if='showSpinningWheel' class='sl-spinning-wheel-20px sl-mlr-5px sl-mt-10px sl-w-30px sl-text-align-center'></div>
    <div v-else class='sl-mlr-5px sl-mt-10px sl-w-30px sl-text-align-center'><!--Place Holder--></div>

  </div>
</template>
<script>
import axios from 'axios';
import showPasswordIcon from '../../../../assets/images/icons/Icon-Password-Eye.svg';

export default {
  components: {
    showPasswordIcon,
  },

  data() {
    return {
      password: '',
      passwordConfirmation: '',
      req1: false,
      req2: false,
      req3: false,
      req4: false,
      req5: false,
      req6: false,
      notice: '',
      showSpinningWheel: false,
      disableInputs: false,
      buttonClass: 'sl-simple-outline-btn-disabled',
      toggleText: false,
    };
  },
  methods: {
    async update() {
      this.showSpinningWheel = true;
      this.disableInputs = true;
      await axios.post('/account/update_security_password', {
        user: {
          password: this.password,
          password_confirmation: this.passwordConfirmation,
        },
      })
        .then((response) => {
          this.notice = response.data.notice;
        })
        .catch(() => {
          this.notice = 'An error occured. Please try again later.';
        })
        .finally(() => {
          this.password = '';
          this.passwordConfirmation = '';
          this.disableInputs = false;
          this.showSpinningWheel = false;
          this.buttonClass = 'sl-simple-outline-btn-disabled';
          this.req1 = false;
          this.req2 = false;
          this.req3 = false;
          this.req4 = false;
          this.req5 = false;
          this.req6 = false;
        });
    },

    checkRequiremnts() {
      this.notice = '';
      this.checkPasswordsMatch();
      this.checkPasswordLength();
      this.checkPasswordHasNumber();
      this.checkPassowrdHasLowerCase();
      this.checkPassowrdHasUpperCase();
      this.checkPasswordHasSpecialCharacter();
      this.updateButtonClass();
    },

    // Check if password and password confirmation match
    checkPasswordsMatch() {
      if (this.password === this.passwordConfirmation && this.password.length > 0) {
        this.req1 = true;
      } else {
        this.req1 = false;
      }
    },

    // Check if password is atleast 12 characters long
    checkPasswordLength() {
      if (this.password.length >= 12) {
        this.req2 = true;
      } else {
        this.req2 = false;
      }
    },

    // Check if password has atleast one number
    checkPasswordHasNumber() {
      if (this.password.match(/[0-9]/)) {
        this.req3 = true;
      } else {
        this.req3 = false;
      }
    },

    // Check if password has atleast one lowercase letter
    checkPassowrdHasLowerCase() {
      if (this.password.match(/[a-z]/)) {
        this.req4 = true;
      } else {
        this.req4 = false;
      }
    },

    // Check if password has atleast one uppercase letter
    checkPassowrdHasUpperCase() {
      if (this.password.match(/[A-Z]/)) {
        this.req5 = true;
      } else {
        this.req5 = false;
      }
    },

    // Check if password has atleast one special character
    checkPasswordHasSpecialCharacter() {
      if (this.password.match(/[^a-zA-Z0-9]/)) {
        this.req6 = true;
      } else {
        this.req6 = false;
      }
    },

    updateButtonClass() {
      if (this.req1 && this.req2 && this.req3 && this.req4 && this.req5 && this.req6) {
        this.buttonClass = 'sl-simple-outline-btn';
      } else {
        this.buttonClass = 'sl-simple-outline-btn-disabled';
      }
    },

    toggleShowOrHidePassword() {
      this.toggleText = !this.toggleText;
      const passwordInput = document.getElementById('user_password');
      const passwordConfirmationInput = document.getElementById('password_confirmation');

      if (this.toggleText) {
        passwordInput.type = 'text';
        passwordConfirmationInput.type = 'text';
      } else {
        passwordInput.type = 'password';
        passwordConfirmationInput.type = 'password';
      }
    },
  },
};
</script>
<style scoped>
  .pseudo-input {
    border: 1px solid var(--medium-charcoal);
    width: 325px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .hidden-input {
    border: 1px solid transparent !important;
    font-size: 14px !important;
  }
</style>
