<template>
  <!-- Using dynamic components -->
  <component :is="currentIconComponent" :title="status" style='flex-shrink: 0;'></component>
</template>

<script>
// Import your individual icon components
import ApprovedIcon from '../../../../assets/images/icons/Icon-Circle-Approved.svg';
import NotApprovedIcon from '../../../../assets/images/icons/Icon-Circle-Rejected.svg';
import MissingIcon from '../../../../assets/images/icons/Icon-Circle-Blank.svg';
import ReviewNeededIcon from '../../../../assets/images/icons/Icon-Circle-Alert.svg';
import NotApplicableIcon from '../../../../assets/images/icons/Icon-Circle-NA.svg';

export default {
  components: {
    ApprovedIcon,
    NotApprovedIcon,
    MissingIcon,
    ReviewNeededIcon,
    NotApplicableIcon,
  },
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    currentIconComponent() {
      // Map status to the corresponding icon component
      const statusToComponentMap = {
        Approved: ApprovedIcon,
        'Not Approved': NotApprovedIcon,
        Missing: MissingIcon,
        'Review Needed': ReviewNeededIcon,
        'Not Applicable': NotApplicableIcon,
      };

      return statusToComponentMap[this.status] || null;
    },
  },
};
</script>
