<template>
  <div class="sl-mb-14px sl-sub-header-blue-10">
    Step 2 of 4 | Select all service(s) for estimating pricing
  </div>

  <p class="sl-partner-blue-text">
    Final order selections will be made in step 4.
  </p>

  <!-- TODO: (Paul) -->
  <!-- If agency client, and has commonly ordered services -->
  <!-- <p>
    Commonly ordered services for this account are preselected for convenience
  </p> -->

  <!-- Spinning wheel loader -->
  <div v-if="default_services_column_1.length === 0">
    <div class="sl-flex-justify-align-center" style="width: 100%; height: 300px;">
      <div class="sl-spinning-wheel-50px"></div>
    </div>
  </div>

  <Transition>
    <div v-if="default_services_column_1.length > 0" class="sl-flex-justify-content-space-btwn">
      <!-- Column 1 (AGENCY ONLY) -->
      <div :style="{ width: columnWidth }" v-if="this.isFinancingAgency">
        <div class="sl-flex-justify-content-space-btwn sl-mb-10px sl-border-bottom-2px-charcoal sl-pb-5px">
          <div class="sl-paragraph-med-bold">Service Recommendations</div>
        </div>
        <div class="sl-mtb-10px">
          Recommendations are based on the agency and loan type selected in the previous step.
        </div>
        <!-- Select All Checkbox -->
        <label class='sl-custom-checkbox sl-pt-2px'>
          <input type="checkbox" id="select_all" v-model="selectAllChecked" @change="toggleSelectAll($event)" />
          <label for="select_all" class="sl-font-size-12px">
            <span>Select All:</span>
          </label>
          <span class="checkmark"></span>
        </label>

        <div class="sl-ml-20px" v-for="service in bundled_services" :key="service.id">
          <!-- Display bundled services -->
          <ul class="sl-ml-20px">
            <li class="sl-font-size-12px">
              {{ service.department_name }} - {{ service.service_name }}
            </li>
          </ul>
        </div>
      </div>

      <!-- Column 2 -->
      <div :style="{ width: columnWidth }">
        <div class="sl-mb-20px" v-for="group in default_services_column_1" :key="group.department">
          <div class="sl-flex-justify-content-space-btwn sl-mb-10px sl-border-bottom-2px-charcoal sl-pb-5px">
            <div class="sl-paragraph-med-bold">{{ group.department }}</div>
          </div>

        <div v-for="service in group.services" :key="service.id">
          <label class='sl-custom-checkbox sl-pt-2px' :name="'checkbox_' + service.id">
            <input type="checkbox" :id="'checkbox_' + service.id" :value="service" v-model="this.passed_in_order_form_data.requested_services_data" />
            <label :for="'checkbox_' + service.id" class="sl-font-size-12px">
              <span>{{ service.service_name }}</span>
            </label>
            <span class="checkmark"></span>
          </label>

          <div v-if="service.service_name.includes('Radon')" style="background-color: #FCDBD3;" class="sl-p-all-5px">
            <span class="sl-bold-text">Recommendation: </span>
            Radon testing should be completed for Zone 1 properties with residential units.
          </div>
        </div>
      </div>
    </div>

    <!-- Column 3 -->
    <div :style="{ width: columnWidth }" class="sl-custom-flex-column-justify-space-btwn">
      <div>
        <div class="sl-mb-20px" v-for="group in default_services_column_2" :key="group.department">
          <div class="sl-flex-only sl-mb-10px sl-border-bottom-2px-charcoal sl-pb-5px">
            <div class="sl-paragraph-med-bold">{{ group.department }}</div>
          </div>

          <div v-for="service in group.services" :key="service.id">
            <label class='sl-custom-checkbox sl-pt-2px' :name="'checkbox_' + service.id">
              <input type="checkbox" :id="'checkbox_' + service.id" :value="service" v-model="this.passed_in_order_form_data.requested_services_data" />
              <label :for="'checkbox_' + service.id" class="sl-font-size-12px">
                <span>{{ service.service_name }}</span>
              </label>
              <span class="checkmark"></span>
            </label>

            <div v-if="service.service_name.includes('Radon')" style="background-color: #FCDBD3;" class="sl-p-all-5px">
              <span class="sl-bold-text">Recommendation: </span>
              Radon testing should be completed for Zone 1 properties with residential units.
            </div>
          </div>
        </div>

        <!-- Additional Services selected from the pop-up -->
        <div v-if="additional_services.length > 0" class="sl-mb-20px" >
          <div class="sl-flex-only sl-mb-10px sl-border-bottom-2px-charcoal sl-pb-5px">
            <div><span class="sl-paragraph-med-bold">Additional Services</span> (From the popup list)</div>
          </div>

          <div v-for="service in additional_services" :key="service.id">
            <label class='sl-custom-checkbox sl-pt-2px' :name="'checkbox_' + service.id">
              <input type="checkbox" :id="'checkbox_' + service.id" :value="service" v-model="this.passed_in_order_form_data.requested_services_data" />
              <label :for="'checkbox_' + service.id" class="sl-font-size-12px">
                <span>{{ service.service_name }}</span>
              </label>
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </div>

      <!-- Pop-up service list -->
      <ServiceListModal :additional_services="additional_services"
                        :passed_in_order_form_data="passed_in_order_form_data"
                        @update_order_form_data_from_service_list_modal="update_order_form_data_from_service_list_modal" />
    </div>
  </div>
  </Transition>

  <hr/>
  <div class="sl-mtb-10px sl-flex-justify-content-space-btwn">
    <button @click="previous_page" class="sl-simple-outline-btn sl-custom-btn-padding-1">Go Back</button>
    <span v-if="required_fields_missing" class="sl-flex-align-items-center sl-flex-justify-content-end sl-plr-10px sl-required-text"
                                         style="flex-grow: 1;">
      * One or more selections are required
    </span>
    <button @click="next_page" class="sl-generic-blue-background-btn sl-custom-btn-padding-1">Continue</button>
  </div>
</template>

<script>
import axios from 'axios';
import ServiceListModal from './ServiceListModal.vue';

export default {
  props: {
    passed_in_order_form_data: {
      type: Object,
    },
  },

  components: {
    ServiceListModal,
  },

  data() {
    return {
      required_fields_missing: false,
      default_services_column_1: [],
      default_services_column_2: [],
      additional_services: [], // Non-default services that were selected from the popup modal
      bundled_services: [],
      selectAllChecked: false,
    };
  },

  computed: {
    // Computed property to capture the 'Financing Agency' condition
    isFinancingAgency() {
      return this.passed_in_order_form_data.loan_data.purpose_of_order === 'Financing Agency';
    },

    columnWidth() {
      // Dynamically return the width based on the condition
      return this.isFinancingAgency ? '32%' : '48%';
    },
  },

  async mounted() {
    await axios.get('/project_orders/return_service_list', {
      params: {
        defaults_only: true,
      },
    })
      .then((response) => {
        const services_mid_point = Math.ceil(response.data.service_list.length / 2);
        this.default_services_column_1 = response.data.service_list.slice(0, services_mid_point);
        this.default_services_column_2 = response.data.service_list.slice(services_mid_point, response.data.service_list.length);

        // updates the additional services group on the page
        this.update_additional_services_list(this.passed_in_order_form_data.requested_services_data);
      })
      .catch((error) => {
        alert(error);
      });

    if (this.isFinancingAgency) {
      await this.checkAndLoadBundledServices();
    }
  },

  methods: {
    // When the user selects a service from the popup modal,
    // this method is called to update the "Additional Services" group on the page
    update_additional_services_list(passed_in_requested_services_data) {
      const temp_all_services = [];

      this.default_services_column_1.forEach((group) => {
        group.services.forEach((service) => {
          temp_all_services.push(service);
        });
      });

      this.default_services_column_2.forEach((group) => {
        group.services.forEach((service) => {
          temp_all_services.push(service);
        });
      });

      // If the service is not in the temp_all_services array, then it is an additional service that was selected from the popup modal
      this.additional_services = passed_in_requested_services_data.filter(
        (service) => temp_all_services.some((temp_service) => temp_service.id === service.id) === false,
      );
    },

    // This method is called from the ServiceListModal component
    update_order_form_data_from_service_list_modal(data) {
      this.$emit('update_order_form_data', data);

      // updates the additional services group on the page
      this.update_additional_services_list(data.requested_services_data);
    },

    previous_page() {
      this.$emit('previous_page');
    },

    next_page() {
      if (this.check_required_fields()) {
        this.required_fields_missing = true;
        return;
      }

      this.$emit('next_page');
    },

    check_required_fields() {
      return this.passed_in_order_form_data.requested_services_data.length === 0;
    },

    // Persist previous agency and loan types in localStorage
    getPreviousAgencyAndLoanType() {
      return {
        previousAgencyType: localStorage.getItem('previousAgencyType'),
        previousLoanType: localStorage.getItem('previousLoanType'),
      };
    },

    setPreviousAgencyAndLoanType(currentAgencyType, currentLoanType) {
      localStorage.setItem('previousAgencyType', currentAgencyType);
      localStorage.setItem('previousLoanType', currentLoanType);
    },

    async checkAndLoadBundledServices() {
      const currentAgencyType = this.passed_in_order_form_data.loan_data.agency_type;
      const currentLoanType = this.passed_in_order_form_data.loan_data.loan_type;

      // Get the previously stored agency and loan types from localStorage
      const { previousAgencyType, previousLoanType } = this.getPreviousAgencyAndLoanType();

      // Check if the agency_type or loan_type has changed
      const agencyOrLoanTypeChanged = (previousAgencyType !== currentAgencyType) || (previousLoanType !== currentLoanType);

      // If agency_type or loan_type has changed, toggle off all selected services
      if (agencyOrLoanTypeChanged) {
        // Clear the selected services data to reset all checkboxes to unchecked
        this.passed_in_order_form_data.requested_services_data = [];
      }

      // Store the current values in localStorage for future comparisons
      this.setPreviousAgencyAndLoanType(currentAgencyType, currentLoanType);

      // Fetch bundled services
      await axios.get('/project_orders/return_bundled_services', {
        params: {
          agency_type: currentAgencyType,
          loan_type: currentLoanType,
        },
      })
        .then((response) => {
          this.bundled_services = response.data.bundled_services;

          // Ensure the "Select All" checkbox reflects the current state
          this.checkIfAllSelected();
        })
        .catch((error) => {
          alert(error);
        });
    },

    toggleSelectAll(event) {
      const isChecked = event.target.checked;

      Object.values(this.bundled_services).forEach((service) => {
        this.updateServiceSelection(service, isChecked);
      });
    },

    // Update the selected services in the order form data
    updateServiceSelection(service, isSelected) {
      const index = this.passed_in_order_form_data.requested_services_data.findIndex(
        (selectedService) => selectedService.id === service.id,
      );

      if (isSelected && index === -1) {
        this.passed_in_order_form_data.requested_services_data.push(service);
      } else if (!isSelected && index !== -1) {
        this.passed_in_order_form_data.requested_services_data.splice(index, 1);
      }
    },

    checkIfAllSelected() {
      if (Object.keys(this.bundled_services).length === 0) {
        // Avoid marking select all as true if there are no bundled services
        this.selectAllChecked = false;
        return;
      }

      const allSelected = Object.values(this.bundled_services).every((service) => this.passed_in_order_form_data.requested_services_data.some(
        (selectedService) => selectedService.id === service.id,
      ));

      this.selectAllChecked = allSelected;
    },
  },

  watch: {
    passed_in_order_form_data: {
      handler() {
        this.checkIfAllSelected();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
  .sl-custom-flex-column-justify-space-btwn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  li {
    list-style-type: disc;
    line-height: 15.6px;
  }
</style>
