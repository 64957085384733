<template>
  <SpinningModal v-if="showLoadingModal"/>
  <div class="sl-flex-justify-content-space-btwn sl-mb-10px" style="color: #005199">
    <span class="sl-font-size-16px sl-bold-text">PCA Cost Factor Versions</span>
    <span style="color: red;">{{ alert }}</span>
    <div class='sl-flex-align-items-center'>
      <a class="sl-blue-link pl-circle-edit-btn sl-tooltip">
        <plusIcon @click="newVersion" class="figma-icon-blue links-center sl-mr-15px"/>
        <span class="sl-tooltiptext">Create New Version</span>
      </a>
      <a class='sl-simple-outline-btn' href='/pca_cost_factors'>PCA Cost Factors</a>
    </div>
  </div>

  <table class="sl-w-100per sl-border-b-1px sl-mb-10px" style="border-collapse: collapse;">
    <thead>
      <tr class="sl-border-tb-2px-primary-blue sl-h-42px sl-bold-text">
        <td class="sl-w-200px sl-pl-10px">Current Version</td>
        <td class="sl-w-220px sl-pl-5px">Name</td>
        <td class="sl-w-200px">Number of Cities</td>
        <td class="sl-w-200px">Uploaded By</td>
        <td class="sl-w-200px">Date Created</td>
        <td class="sl-w-100px"></td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(version, index) in versions" class="sl-table__row--striped sl-h-42px">
        <td>
          <label v-if="!version.new" :for="version.id" class="sl-custom-checkbox sl-ml-5px sl-mb-20px">
            <input @click="toggleCurrentVersion(version, index)" type="checkbox"  v-model="version.current_version" :id="version.id">
            <span class="checkmark sl-ml-5px"></span>
          </label>
        </td>
        <td>
          <input v-if="version.new" v-model="newVersionName" type="text"
                class="sl-w-200px sl-border-1px-medium-charcoal sl-pl-5px sl-ptb-5px"
                placeholder="Enter the version name here..."/>
          <input v-else-if="version.edit" v-model="version.name" type="text"
                class="sl-w-200px sl-border-1px-medium-charcoal sl-pl-5px sl-ptb-5px"
                placeholder="Enter the version name here..."/>
          <a v-else :href="`/pca_cost_factor_versions/${version.id}`" class="sl-pl-5px sl-blue-link pl-circle-edit-btn sl-tooltip">
            {{ version.name }}
            <span class="sl-tooltiptext">Import From Template</span>
          </a>
        </td>
        <td>{{ version.number_of_cities }}</td>
        <td>
          <button v-if="version.new" @click="create" class="sl-simple-outline-btn sl-w-85px">Save</button>
          <button v-else-if="version.edit" @click="update(version)" class="sl-simple-outline-btn sl-w-85px">Save</button>
          <span v-else>{{ version.updated_by }}</span>
        </td>
        <td>
          <button v-if="version.new" @click="cancelCreate" class="sl-simple-outline-btn sl-w-85px">Cancel</button>
          <button v-else-if="version.edit" @click="cancelUpdate(version)" class="sl-simple-outline-btn sl-w-85px">Cancel</button>
          <span v-else>
            <div class="sl-mb-5px">{{ version.created_date }}</div>
          </span>
        </td>
        <td class="sl-text-align-center sl-flex-justify-content-end sl-flex-align-items-center sl-h-42px">
          <div v-if="!version.new">
            <editIcon @click="version.edit = true;" class="figma-icon sl-mr-10px"/>
            <deleteIcon @click="destroy(version, index)" transform='scale(0.8)' class="figma-icon sl-mr-10px"/>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <br>
  <br>
</template>
<script>
import axios from 'axios';
import SpinningModal from '/javascript/components/SpinningModal.vue';
import editIcon from '../../../../assets/images/icons/Icon-Edit_Charcoal-Dark-100_20.svg';
import deleteIcon from '../../../../assets/images/icons/Icon-Archive-Delete_Charcoal-Dark-100_24.svg';
import plusIcon from '../../../../assets/images/icons/Icon-Add_Charcoal-Dark-100_24.svg';

export default {
  components: {
    SpinningModal,
    editIcon,
    deleteIcon,
    plusIcon,
  },

  data() {
    return {
      versions: [],
      showNewRow: false,
      newVersionName: null,
      showLoadingModal: false,
      currentVersion: null,
      alert: '',
    };
  },

  async created() {
    this.fetchCostFactorVersions();
  },

  methods: {
    async fetchCostFactorVersions() {
      this.showLoadingModal = true;
      await axios.get('pca_cost_factor_versions/vue_index')
        .then((response) => {
          this.versions = response.data.versions;
          this.setCurrentVersion();
          this.showLoadingModal = false;
        })
        .catch((error) => {
          console.log(error);
          this.showLoadingModal = false;
        });
    },

    // If there is a version marked as the current version set it to the the currentVersion variable
    setCurrentVersion() {
      this.versions.forEach((version) => {
        if (version.current_version == 'true') {
          this.currentVersion = version;
        }
      });
    },

    // Create temporary version object and show the new row for name to be entered
    newVersion() {
      this.showNewRow = true;
      this.versions.push({ new: true });
    },

    // Save new version with call to server
    async create() {
      this.showLoadingModal = true;
      await axios.post('/pca_cost_factor_versions', null, {
        params: {
          pca_cost_factor_version: {
            name: this.newVersionName,
          },
        },
      })
        .then((response) => {
          this.newVersionName = null;
          this.alert = null;
          this.showNewRow = false;
          const lastIndex = this.versions.length - 1;
          this.versions[lastIndex] = response.data.createdVersion;
          this.showLoadingModal = false;
        })
        .catch((error) => {
          this.alert = error.response.data.notice;
          this.showLoadingModal = false;
        });
    },

    // Update existing version with call to the server
    async update(version) {
      this.showLoadingModal = true;
      await axios.put(`/pca_cost_factor_versions/${version.id}`, null, {
        params: {
          pca_cost_factor_version: version,
        },
      })
        .then(() => {
          this.alert = null;
          version.edit = false;
          this.showLoadingModal = false;
        })
        .catch((error) => {
          this.alert = error.response.data.notice;
          this.showLoadingModal = false;
        });
    },

    // Delete a version with a server call
    async destroy(version, index) {
      if (confirm(`Are you sure you want to delete the ${version.name} cost factor version`)) {
        this.showLoadingModal = true;
        this.alert = null;
        await axios.delete(`/pca_cost_factor_versions/${version.id}`)
          .then(() => {
            this.versions.splice(index, 1);
            this.showLoadingModal = false;
          })
          .catch((error) => {
            console.log(error);
            this.showLoadingModal = false;
          });
      }
    },

    // Cancel the create new version action, go back to regular index view
    cancelCreate() {
      this.showNewRow = false;
      this.versions.splice(-1, 1);
      this.newVersionName = null;
      this.alert = null;
    },

    // Cancel the update version action, go back to regular index view
    cancelUpdate(version) {
      version.edit = false;
      this.alert = null;
    },

    // When a check mark is clicked set it to either the current version or unselect it as the current
    // version and make a call to the server to update the record
    async toggleCurrentVersion(version, index) {
      if (version.id === this.currentVersion?.id) {
        version.current_version = false;
      } else {
        version.current_version = true;
      }
      await axios.put(`/pca_cost_factor_versions/${version.id}`, null, {
        params: {
          pca_cost_factor_version: version,
        },
      })
        .then(() => {
          this.togglePreviousCurrentVersion(index);
          this.alert = 'Current version updated';
          setTimeout(() => { this.alert = ''; }, 1000);
        })
        .catch((error) => {
          console.log(error);
          this.alert = 'Error, something went wrong updating the current version.';
        });
    },

    // Unselect the check mark on the previous current version
    togglePreviousCurrentVersion(index) {
      this.versions.forEach((version, i) => {
        if (index != i) {
          version.current_version = false;
        }
      });
    },
  },
};
</script>
