<template>
  <SpinningModal v-if='showLoading'/>
  <div class='sl-flex-justify-content-center sl-h-15px' style='color: red'>{{ alertMessage }}</div>
  <div class='pl-page-container'>
    <h2 class='sl-static-h2-style' style='line-height:20px; padding-left:30px'>Site Map and Site Plan</h2>
  </div>
  <br>
  <div class='sl-flex-justify-content-center'>
    <!-- Left Side Pannel -->
    <div class='sl-flex-column sl-flex-align-items-center sl-mr-20px'>
      <div class='sl-w-100per'>
        <div class='sl-border-t-1px sl-border-b-1px' id='drawing_tools'>
          <div class='sl-flex-justify-content-center sl-mtb-10px'>
            <div @click="setDrawingMode('POINTER')"
              class='map_tool_btn'
              :class="{'active-tool-btn':drawingMode==='POINTER'}">
              <pointerIcon style='width: 25px'/>
            </div>
            <div @click="setDrawingMode('POLYLINE')"
              class='map_tool_btn'
              :class="{'active-tool-btn':drawingMode==='POLYLINE'}">
              <lineIcon style='width: 25px'/>
            </div>
            <div @click="setDrawingMode('RECTANGLE')"
              class='map_tool_btn'
              :class="{'active-tool-btn':drawingMode==='RECTANGLE'}">
              <rectangleIcon style='width: 25px'/>
            </div>
            <div @click="setDrawingMode('CIRCLE')"
              class='map_tool_btn'
              :class="{'active-tool-btn':drawingMode==='CIRCLE'}">
              <circleIcon style='width: 25px'/>
            </div>
            <div @click="setDrawingMode('TEXT')"
              class='map_tool_btn'
              :class="{'active-tool-btn':drawingMode==='TEXT'}">
              <textIcon style='width: 25px'/>
            </div>
            <div @click="setDrawingMode('MARKER')"
              class='map_tool_btn'
              :class="{'active-tool-btn':drawingMode==='MARKER'}">
              <markerIcon style='width: 25px'/>
            </div>
          </div>
        </div>
        <div class='sl-mt-10px sl-flex-align-items-center sl-flex-column'>
          <SiteMapDrawingSettings
            :drawingMode='drawingMode'
            :activeText='textInput'
            @set-stroke-color='setStrokeColor'
            @set-fill-color='setFillColor'
            @set-stroke-weight='setStrokeWeight'
            @set-stroke-opacity='setStrokeOpacity'
            @set-fill-opacity='setFillOpacity'
            @add-text-to-object='addTextToActiveObject'
          />
          <div class='sl-border-t-1px sl-w-100per sl-mt-10px'>
            <label for='portrait' class='sl-custom-radio sl-w-180px disabled-font sl-mt-20px sl-ml-20px'>
              Portrait
              <input @click="aspect='Portrait'" id='portrait' type='radio' :checked="aspect==='Portrait'"/>
              <span class='checkmark'></span>
            </label>
            <label for='landscape' class='sl-custom-radio sl-w-180px disabled-font sl-mt-10px sl-ml-20px'>
              Landscape
              <input @click="aspect='Landscape'" id='landscape' type='radio'  :checked="aspect==='Landscape'"/>
              <span class='checkmark'></span>
            </label>
          </div>
          <div class='sl-border-t-1px sl-w-100per sl-mt-10px'>
            <label for='sitelocation' class='sl-custom-radio sl-w-180px disabled-font sl-mt-20px sl-ml-20px'>
              Site Locatin Map
              <input @click="documentType='Site Location Map'" id='sitelocation' type='radio' :checked="documentType==='Site Location Map'"/>
              <span class='checkmark'></span>
            </label>
            <label for='siteplan' class='sl-custom-radio sl-w-180px disabled-font sl-mt-10px sl-ml-20px'>
              Site Plan
              <input @click="documentType='Site Plan'" id='siteplan' type='radio' :checked="documentType==='Site Plan'"/>
              <span class='checkmark'></span>
            </label>
          </div>
        </div>
        <div class='sl-border-t-1px sl-mt-10px sl-pt-10px sl-flex-justify-content-center'>
          <button @click='save'
                  class='sl-simple-outline-btn sl-w-85px sl-mt-5px'>
                  Save
          </button>
        </div>
      </div>
    </div>
    <!-- Map -->
    <div id='map' class="map-container-portrait" :class="{'map-container-landscape':aspect==='Landscape'}"></div>
    <!-- Right Side Pannel -->
    <div class='sl-flex-column sl-flex-align-items-center sl-pl-10px sl-ml-10px'
         style='height: 400px; width: 250px'>
      <div v-if='mapObjects.length' class='sl-w-100per'>
        <ul style='min-height: 244px' id='map-object-container'>
          <li @click='panToLocation'
              class='map-object-layer sl-border-t-1px sl-font-size-12px
                    sl-ptb-10px sl-flex-justify-content-space-btwn sl-flex-align-items-center
                    sl-cursor-pointer'
              :class="{'map-object-active':activeIndex===null}">
            <div class='sl-ml-5px sl-flex-align-items-center'>
              <span class='property-dot'></span>
              <label class='sl-ml-10px'>Property</label>
            </div>
          </li>
          <li v-for='(mapObject, index) in mapObjects'
                @click='setMapObjectActive(index)'
                class='map-object-layer sl-border-t-1px sl-font-size-12px sl-w-100per
                      sl-ptb-10px sl-flex-justify-content-space-btwn sl-flex-align-items-center
                      sl-cursor-pointer'
                :class="{'sl-border-b-1px':isLastRow(index), 'map-object-active':index===activeIndex}">
            <div class='sl-ml-5px sl-flex-align-items-center'>
              <span class='dot' :style="{'background-color': mapObject['stroke_color']}"></span>
              <label class='sl-ml-10px'>{{ `${mapObject.type}` }}</label>
            </div>
            <deleteIcon @click='deleteMapObject(index)' transform='scale(0.7)' class='figma-icon-delete links-center sl-mr-5px'/>
          </li>
        </ul>
      </div>
      <div v-else class='sl-w-100per'>
        <ul>
          <li @click='panToLocation'
              class='map-object-layer sl-border-t-1px sl-border-b-1px sl-font-size-12px
                    sl-ptb-10px sl-flex-justify-content-space-btwn sl-flex-align-items-center
                    sl-cursor-pointer'
              :class="{'map-object-active':activeIndex===null}">
            <div class='sl-ml-5px sl-flex-align-items-center'>
              <span class='property-dot'></span>
              <label class='sl-ml-10px'>Property</label>
            </div>
          </li>
        </ul>
        <ol class='sl-pl-20px sl-pt-10px sl-pb-10px' style='height: 190px'>
          <li class='sl-font-size-14px sl-mb-5px' style='color: #696E76'>Center map to the property</li>
          <li class='sl-font-size-14px sl-mb-5px' style='color: #696E76'> Draw the property boundary</li>
          <li class='sl-font-size-14px sl-mb-5px' style='color: #696E76'> Create document</li>
        </ol>
      </div>

      <div class='sl-pt-20px sl-w-90per sl-plr-5px'>
        <div class='sl-flex-align-items-center'>
          <a :href=siteMapLink() target='_blank'>
            <pdfIcon class='sl-mr-10px'/>
          </a>
          <div>
            <div class='sl-bold-text' style='color: #333E47'>Figure 1</div>
            <div class='disabled-font'>Site Location Map</div>
          </div>
        </div>
        <div class='sl-flex-align-items-center sl-mt-10px'>
          <a :href=sitePlanLink() target='_blank'>
            <pdfIcon class='sl-mr-10px'/>
          </a>
          <div>
            <div class='sl-bold-text' style='color: #333E47'>Figure 2</div>
            <div class='disabled-font'>Site Plan</div>
          </div>
        </div>
      </div>
      <div class='sl-border-t-1px sl-mt-10px sl-pt-20px sl-w-90per sl-flex-justify-content-center sl-plr-5px'>
        <button @click='createDocument()' class='sl-simple-outline-btn sl-w-150px sl-mr-5px sl-h-28px'>Create Document</button>
      </div>
    </div>
  </div>
  <div id='newCanvas'></div>
</template>
<script>
// @ts-nocheck
import axios from 'axios';
import { toRaw } from 'vue';
import deleteIcon from '../../../assets/images/icons/Icon-Archive-Delete_Charcoal-Dark-100_24.svg';
import pointerIcon from '../../../assets/images/icons/map_pointer.svg';
import markerIcon from '../../../assets/images/icons/map_marker.svg';
import rectangleIcon from '../../../assets/images/icons/map_square.svg';
import infoIcon from '../../../assets/images/icons/map_info_marker.svg';
import circleIcon from '../../../assets/images/icons/map_circle.svg';
import textIcon from '../../../assets/images/icons/map_text_icon.svg';
import lineIcon from '../../../assets/images/icons/map_line.svg';
import SiteMapDrawingSettings from './site_map/SiteMapDrawingSettings.vue';
import SpinningModal from '../components/SpinningModal.vue';
import pdfIcon from "../../../assets/images/icons/Icon-PDF-Doc_Charcoal-Dark-100_24.svg"

export default {
  components: {
    SiteMapDrawingSettings,
    SpinningModal,
    deleteIcon,
    pointerIcon,
    markerIcon,
    rectangleIcon,
    infoIcon,
    circleIcon,
    textIcon,
    lineIcon,
    pdfIcon,
  },

  data() {
    const urlParams = new URLSearchParams(window.location.search);
    return {
      projectId: urlParams.get('id'),
      projectScopeId: urlParams.get('project_scope_id'),
      project: null,
      colors: ['#005199', '#569900', '#9F9EA4', '#1F7A8C', '#FF3333', '#F04C25', '#000000'],
      googleMap: null,
      siteMap: null,
      sitemapdocIdId: null,
      siteplandocIdId: null,
      locationMarker: null,
      mapObjects: [],
      mapDrawings: [],
      infoWindows: [],
      location: {},
      activeIndex: null,
      textInput: null,
      drawingManager: null,
      drawingMode: 'POINTER',
      strokeColor: '#005199',
      fillColor: '#005199',
      strokeWeight: 10,
      strokeOpacity: 1.0,
      fillOpacity: 0.1,
      siteMapDownloadLink: null,
      overlayLimit: 15,
      showLoading: false,
      alertMessage: '',
      aspect: 'Portrait',
      documentType: 'Site Location Map',
    };
  },

  mounted() {
    this.fetchMap();
  },

  methods: {
    async fetchMap() {
      await axios.get('/site_maps/project_data', {
        params: {
          project_id: this.projectId,
          project_scope_id: this.projectScopeId,
        },
      })
        .then((response) => {
          this.project = response.data.project;
          this.siteMap = response.data.site_map;
          this.mapObjects = response.data.map_objects;
          this.location = response.data.location;
          this.sitemapdocId = response.data.sitemapdocId;
          this.siteplandocId = response.data.siteplandocId;
          this.initialize();
        })
        .catch(() => {
          this.alertMessage = 'Something went wrong, map not loaded';
        });
    },

    // Create Map
    async initialize() {
      const { Map } = await google.maps.importLibrary('maps');
      const map = new Map(document.getElementById('map'), {
        center: { lat: parseFloat(this.siteMap.lat), lng: parseFloat(this.siteMap.lng) },
        zoom: parseInt(this.siteMap.zoom),
        mapTypeId: this.siteMap.map_type,
        tilt: this.siteMap.tilt,
      });
      map.setOptions({ styles: this.mapStyles().hide })
      const location = new google.maps.Marker({
        position: { lat: parseFloat(this.location.lat), lng: parseFloat(this.location.lng) },
        map,
      });
      this.locationMarker = location;
      this.createListeners(map);
      this.loadDrawingManager(map);
      this.loadMapObjects(map);
      this.googleMap = map;
    },

    createListeners(map) {
      map.addListener('center_changed', () => {
        this.siteMap.lat = map.center.lat();
        this.siteMap.lng = map.center.lng();
      });
      map.addListener('zoom_changed', () => {
        this.siteMap.zoom = map.zoom;
      });
      map.addListener('maptypeid_changed', () => {
        this.siteMap.map_type = map.mapTypeId;
      });
      map.addListener('tilt_changed', () => {
        this.siteMap.tilt = map.tilt;
      });
    },

    panToLocation() {
      this.activeIndex = null;
      this.setAllEditable(false);
      this.googleMap.panTo(this.locationMarker.getPosition());
    },

    mapStyles() {
      return {
        default: [],
        hide: [
          {
            featureType: 'landscape',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }],
          },
          {
            featureType: 'water',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }],
          },
          {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }],
          },
          {
            featureType: 'transit',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }],
          },
        ],
      };
    },

    // Load Saved Map Objects
    loadMapObjects(map) {
      this.mapObjects.forEach((object) => {
        switch (object.type) {
          case 'Line':
            this.loadLine(object, map);
            break;
          case 'Rectangle':
            this.loadRectangle(object, map);
            break;
          case 'Circle':
            this.loadCircle(object, map);
            break;
          case 'Text':
            this.loadText(object, map);
            break;
          case 'Marker':
            this.loadMarker(object, map);
            break;
        }
      });
    },

    loadLine(data, map) {
      const points = [];
      data.points.forEach((datapoint) => {
        const point = new google.maps.LatLng(datapoint.lat, datapoint.lng);
        points.push(point);
      });
      const line = new google.maps.Polyline({
        index: this.mapObjects.length - 1,
        type: data.type,
        path: points,
        strokeColor: data.stroke_color,
        strokeOpacity: data.stroke_opacity,
        strokeWeight: data.stroke_weight,
        editable: false,
        map,
      });
      line.addListener('click', () => {
        this.setAllEditable(false);
        line.setEditable(true);
      });
      this.mapDrawings.push(line);
      this.infoWindows.push(null);
    },

    loadRectangle(data, map) {
      const rectangle = new google.maps.Rectangle({
        index: this.mapObjects.length - 1,
        type: data.type,
        strokeColor: data.stroke_color,
        strokeOpacity: data.stroke_opacity,
        strokeWeight: data.stroke_weight,
        fillColor: data.fill_color,
        fillOpacity: data.fill_opacity,
        editable: false,
        map,
        bounds: {
          north: parseFloat(data.north),
          south: parseFloat(data.south),
          east: parseFloat(data.east),
          west: parseFloat(data.west),
        },
      });
      rectangle.addListener('click', () => {
        this.setAllEditable(false);
        rectangle.setEditable(true);
      });
      this.mapDrawings.push(rectangle);
      this.infoWindows.push(null);
    },

    loadCircle(data, map) {
      const circle = new google.maps.Circle({
        index: this.mapObjects.length - 1,
        type: data.type,
        strokeColor: data.stroke_color,
        strokeOpacity: data.stroke_opacity,
        strokeWeight: data.stroke_weight,
        fillColor: data.fill_color,
        fillOpacity: data.fill_opacity,
        editable: false,
        map,
        center: { lat: parseFloat(data.lat), lng: parseFloat(data.lng) },
        radius: parseFloat(data.radius),
      });
      circle.addListener('click', () => {
        this.setAllEditable(false);
        circle.setEditable(true);
      });
      this.mapDrawings.push(circle);
      this.infoWindows.push(null);
    },

    loadText(data, map) {
      const marker = new google.maps.Marker({
        index: this.mapObjects.length - 1,
        type: data.type,
        position: { lat: parseFloat(data.lat), lng: parseFloat(data.lng) },
        draggable: true,
        icon: infoIcon,
        map,
      });
      const infowindow = new google.maps.InfoWindow({
        content: data.content,
        maxWidth: 200,
      });
      infowindow.open({
        anchor: marker,
        map,
      });
      marker.addListener('click', () => {
        this.setAllEditable(false);
        marker.setEditable(true);
      });
      this.mapDrawings.push(marker);
      this.infoWindows.push(infowindow);
    },

    loadMarker(data, map) {
      const marker = new google.maps.Marker({
        index: this.mapObjects.length - 1,
        type: data.type,
        position: { lat: parseFloat(data.lat), lng: parseFloat(data.lng) },
        draggable: true,
        map,
      });
      marker.addListener('click', () => {
        this.setAllEditable(false);
        marker.setEditable(true);
      });
      this.mapDrawings.push(marker);
      this.infoWindows.push(null);
    },

    // Drawing Manager
    loadDrawingManager(map) {
      const drawing_options = {
        strokeColor: this.strokeColor,
        fillColor: this.fillColor,
        strokeWeight: this.strokeWeight,
        strokeOpacity: this.strokeOpacity,
        fillOpacity: this.fillOpacity,
        clickable: true,
        editable: true,
        zIndex: 1,
      };
      const drawingManager = new google.maps.drawing.DrawingManager({
        drawingControl: false,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [
            google.maps.drawing.OverlayType.MARKER,
            google.maps.drawing.OverlayType.CIRCLE,
            google.maps.drawing.OverlayType.POLYGON,
            google.maps.drawing.OverlayType.POLYLINE,
            google.maps.drawing.OverlayType.RECTANGLE,
            google.maps.drawing.OverlayType.RECTANGLE,
          ],
        },
        markerOptions: {},
        polygonOptions: drawing_options,
        polylineOptions: drawing_options,
        rectangleOptions: drawing_options,
        circleOptions: drawing_options,
      });
      drawingManager.setMap(map);
      // Marker is added to the map
      google.maps.event.addListener(drawingManager, 'markercomplete', (item) => {
        if (this.mapObjects.length >= this.overlayLimit) {
          alert('You have reached the limit of items that can be added to the map. Please delete map items to add more.');
          return;
        }
        if (this.drawingMode === 'TEXT') {
          this.activeIndex = this.mapObjects.length;
          item.set('icon', infoIcon);
          const infowindow = new google.maps.InfoWindow({
            content: this.textInput,
            maxWidth: 200,
          });
          infowindow.open({
            anchor: item,
            map,
          });
          this.mapObjects.push({
            type: 'Text',
            lat: item.getPosition().lat(),
            lng: item.getPosition().lng(),
            content: this.textInput,
          });
          item.set('type', 'Text');
          item.set('draggable', true);
          this.infoWindows.push(infowindow);
        } else {
          this.mapObjects.push({
            type: 'Marker',
            lat: item.getPosition().lat(),
            lng: item.getPosition().lng(),
          });
          this.infoWindows.push(null);
        }
        item.addListener('click', () => {
          this.setAllEditable(false);
          item.setEditable(true);
        });
        item.set('type', 'Marker');
        this.mapDrawings.push(item);
        this.disablePreviousEditable();
      });
      // Rectangle is added to the map
      google.maps.event.addListener(drawingManager, 'rectanglecomplete', (item) => {
        if (this.mapObjects.length >= this.overlayLimit) {
          alert('You have reached the limit of items that can be added to the map. Please delete map items to add more.');
          return;
        }
        this.mapObjects.push({
          type: 'Rectangle',
          index: this.mapObjects.length - 1,
          north: item.getBounds().getNorthEast().lat(),
          south: item.getBounds().getSouthWest().lat(),
          east: item.getBounds().getNorthEast().lng(),
          west: item.getBounds().getSouthWest().lng(),
          stroke_color: item.strokeColor,
          fill_color: item.fillColor,
          stroke_opacity: item.strokeOpacity,
          fill_opacity: item.fillOpacity,
          stroke_weight: item.strokeWeight,
          z_index: item.zIndex,
        });
        item.addListener('click', () => {
          this.setAllEditable(false);
          item.setEditable(true);
        });
        item.set('type', 'Rectangle');
        this.mapDrawings.push(item);
        this.infoWindows.push(null);
        this.disablePreviousEditable();
      });
      // Circle is added to the map
      google.maps.event.addListener(drawingManager, 'circlecomplete', (item) => {
        if (this.mapObjects.length >= this.overlayLimit) {
          alert('You have reached the limit of items that can be added to the map. Please delete map items to add more.');
          return;
        }
        this.mapObjects.push({
          type: 'Circle',
          index: this.mapObjects.length - 1,
          lat: item.getCenter().lat(),
          lng: item.getCenter().lng(),
          radius: item.getRadius(),
          stroke_color: item.strokeColor,
          fill_color: item.fillColor,
          stroke_opacity: item.strokeOpacity,
          fill_opacity: item.fillOpacity,
          stroke_weight: item.strokeWeight,
          z_index: item.zIndex,
        });
        item.addListener('click', () => {
          this.setAllEditable(false);
          item.setEditable(true);
        });
        item.set('type', 'Circle');
        this.mapDrawings.push(item);
        this.infoWindows.push(null);
        this.disablePreviousEditable();
      });
      // Line is added to the map
      google.maps.event.addListener(drawingManager, 'polylinecomplete', (item) => {
        if (this.mapObjects.length >= this.overlayLimit) {
          alert('You have reached the limit of items that can be added to the map. Please delete map items to add more.');
          return;
        }
        const points = (item.getPath().getArray().map((point) => ({ lat: point.lat(), lng: point.lng() })));
        this.mapObjects.push({
          type: 'Line',
          index: this.mapObjects.length - 1,
          stroke_color: item.strokeColor,
          stroke_opacity: item.strokeOpacity,
          stroke_weight: item.strokeWeight,
          z_index: item.zIndex,
          points,
        });
        item.addListener('click', () => {
          this.setAllEditable(false);
          item.setEditable(true);
        });
        item.set('type', 'Line');
        this.mapDrawings.push(item);
        this.infoWindows.push(null);
        this.disablePreviousEditable();
      });

      this.drawingManager = drawingManager;
    },

    setDrawingMode(mode) {
      this.drawingMode = mode;
      this.textInput = null;
      if (mode === 'TEXT') {
        this.drawingManager.setOptions({
          drawingMode: google.maps.drawing.OverlayType.MARKER,
        });
      } else {
        this.drawingManager.setOptions({
          drawingMode: google.maps.drawing.OverlayType[mode],
        });
      }
    },

    setStrokeColor(color) {
      this.strokeColor = color;
      this.updateDrawingManager();
      const activeDrawing = this.mapDrawings[this.activeIndex];
      const activeObject = this.mapObjects[this.activeIndex];
      if (activeDrawing?.strokeColor) {
        activeDrawing.set('strokeColor', color);
        activeObject.stroke_color = color;
      }
    },

    setFillColor(color) {
      this.fillColor = color;
      this.updateDrawingManager();
      const activeDrawing = this.mapDrawings[this.activeIndex];
      const activeObject = this.mapObjects[this.activeIndex];
      if (activeDrawing?.fillColor) {
        activeDrawing.set('fillColor', color);
        activeObject.fill_color = color;
      }
    },

    setStrokeWeight(strokeWeight) {
      this.updateDrawingManager();
      const activeDrawing = this.mapDrawings[this.activeIndex];
      const activeObject = this.mapObjects[this.activeIndex];
      if (activeDrawing?.strokeWeight) {
        activeDrawing.set('strokeWeight', strokeWeight);
        activeObject.stroke_weight = strokeWeight;
      }
    },

    setStrokeOpacity(opacity) {
      this.updateDrawingManager();
      const activeDrawing = this.mapDrawings[this.activeIndex];
      const activeObject = this.mapObjects[this.activeIndex];
      if (activeDrawing?.strokeOpacity) {
        activeDrawing.set('strokeOpacity', opacity);
        activeObject.stroke_opacity = opacity;
      }
    },

    setFillOpacity(opacity) {
      this.updateDrawingManager();
      const activeDrawing = this.mapDrawings[this.activeIndex];
      const activeObject = this.mapObjects[this.activeIndex];
      if (activeDrawing?.fillOpacity) {
        activeDrawing.set('fillOpacity', opacity);
        activeObject.fill_opacity = opacity;
      }
    },

    updateDrawingManager() {
      const drawing_options = {
        strokeColor: this.strokeColor,
        fillColor: this.fillColor,
        strokeWeight: this.strokeWeight,
        strokeOpacity: this.strokeOpacity,
        fillOpacity: this.fillOpacity,
        clickable: true,
        editable: true,
        zIndex: 1,
      };
      this.drawingManager.setOptions({
        polygonOptions: drawing_options,
        polylineOptions: drawing_options,
        rectangleOptions: drawing_options,
        circleOptions: drawing_options,
      });
    },

    setAllEditable(editable) {
      this.mapDrawings.forEach((drawing) => {
        if (drawing.setEditable) {
          drawing.setEditable(editable);
        }
      });
    },

    disablePreviousEditable() {
      const index = this.mapDrawings.length - 1;
      this.setAllEditable(false);
      if (this.mapDrawings[index]?.setEditable) {
        this.mapDrawings[index].setEditable(true);
      }
    },

    showTextarea() {
      const activeObject = this.mapObjects[this.activeIndex];
      return this.drawingMode === 'TEXT' || (activeObject && activeObject.type === 'Text' && activeObject.content);
    },

    addTextToActiveObject(textInput) {
      const activeObject = this.mapObjects[this.activeIndex];
      if (activeObject && activeObject.type === 'Text') {
        const activeDrawing = this.mapDrawings[this.activeIndex];
        if (activeDrawing) {
          this.infoWindows[this.activeIndex].set('content', textInput);
          this.mapObjects[this.activeIndex].content = textInput;
        }
      }
    },

    // Save Map and Map Objects
    async save() {
      this.showLoading = true;
      if (this.updateMapObjects()) {
        await axios.put(`/site_maps/${this.siteMap.id}`, null, {
          params: {
            site_map: this.siteMap,
            map_objects: this.mapObjects,
          },
        })
          .then(() => {
            this.setAllEditable(false);
            this.textInput = null;
            setTimeout(() => {
              this.showLoading = false;
            }, 500);
            this.alertMessage = 'Map Saved';
            setTimeout(() => {
              this.alertMessage = '';
            }, 3000);
          })
          .catch(() => {
            this.alertMessage = 'Something went wrong, map not saved';
            this.showLoading = false;
          });
      } else {
        this.showLoading = false;
      }
    },

    updateMapObjects() {
      let valid = true;
      this.mapDrawings.forEach((drawing, index) => {
        if (drawing.type === 'Line') {
          this.mapObjects[index].points = drawing.getPath().getArray().map((point) => (
            { lat: point.lat(), lng: point.lng() }
          ));
          if (this.mapObjects[index].points.length > 30) {
            this.alertMessage = 'Line has too many points, please draw the line with less than 30 points';
            valid = false;
          }
        } else if (drawing.type === 'Rectangle') {
          this.mapObjects[index].north = drawing.getBounds().getNorthEast().lat();
          this.mapObjects[index].south = drawing.getBounds().getSouthWest().lat();
          this.mapObjects[index].east = drawing.getBounds().getNorthEast().lng();
          this.mapObjects[index].west = drawing.getBounds().getSouthWest().lng();
        } else if (drawing.type === 'Circle') {
          this.mapObjects[index].lat = drawing.getCenter().lat();
          this.mapObjects[index].lng = drawing.getCenter().lng();
          this.mapObjects[index].radius = drawing.getRadius();
        } else if (drawing.type === 'Marker') {
          this.mapObjects[index].lat = drawing.getPosition().lat();
          this.mapObjects[index].lng = drawing.getPosition().lng();
        } else if (drawing.type === 'Text') {
          this.mapObjects[index].lat = drawing.getPosition().lat();
          this.mapObjects[index].lng = drawing.getPosition().lng();
          this.mapObjects[index].content = this.infoWindows[index].content;
        }
      });
      return valid;
    },

    // Delete Map Objects
    async deleteMapObject(index) {
      toRaw(this.mapDrawings[index]).setMap(null);
      this.mapDrawings.splice(index, 1);
      this.mapObjects.splice(index, 1);
      this.infoWindows.splice(index, 1);
    },

    setMapObjectActive(index) {
      this.activeIndex = index;
      const activeObject = this.mapObjects[index];
      if (activeObject?.type === 'Text') {
        this.setDrawingMode('TEXT');
      } else if (activeObject?.type === 'Line') {
        this.setDrawingMode('POLYLINE');
      } else if (activeObject?.type === 'Rectangle') {
        this.setDrawingMode('RECTANGLE');
      } else if (activeObject?.type === 'Circle') {
        this.setDrawingMode('CIRCLE');
      } else if (activeObject?.type === 'Marker') {
        this.setDrawingMode('MARKER');
      } else {
        this.setDrawingMode('POINTER');
      }
      this.setAllEditable(false);
      if (activeObject && activeObject.type) {
        if (this.mapDrawings[index].setEditable) {
          this.mapDrawings[index].setEditable(true);
        }
        if (['Circle', 'Marker'].includes(activeObject.type)) {
          const position = { lat: parseFloat(activeObject.lat), lng: parseFloat(activeObject.lng) };
          this.googleMap.panTo(position);
        } else if (activeObject.type === 'Text') {
          const position = { lat: parseFloat(activeObject.lat), lng: parseFloat(activeObject.lng) };
          this.textInput = activeObject.content;
          this.googleMap.panTo(position);
        } else if (activeObject.type === 'Rectangle') {
          const difference1 = (parseFloat(activeObject.south) - parseFloat(activeObject.north)) / 2;
          const difference2 = (parseFloat(activeObject.east) - parseFloat(activeObject.west)) / 2;
          const position = {
            lat: parseFloat(activeObject.south) + difference1,
            lng: parseFloat(activeObject.west) + difference2,
          };
          this.googleMap.panTo(position);
        } else if (activeObject.type === 'Line') {
          const lat = parseFloat(activeObject.points[0].lat);
          const lng = parseFloat(activeObject.points[0].lng);
          const position = { lat, lng };
          this.googleMap.panTo(position);
        }
      }
    },

    isLastRow(index) {
      return index === this.mapObjects.length - 1;
    },

    // Document Creation
    async createDocument() {
      this.showLoading = true;
      // this.save();
      window.scrollTo(0, 0);
      await this.hideMapControls()
        .then(() => {
          this.captureMap();
          this.locationMarker.setVisible(true);
          document.body.style.overflowY = 'scroll';
        })
        .then(() => {
          this.alertMessage = 'Document created';
        })
        .catch(() => {
          this.alertMessage = 'Something went wrong, document not created';
        });
    },

    hideMapControls() {
      return new Promise((resolve) => {
        this.locationMarker.setVisible(false);
        this.googleMap.setOptions({ disableDefaultUI: true });
        setTimeout(() => {
          resolve('resolved');
        }, 500);
      });
    },

    captureMap() {
      const map = document.getElementById('map');
      let img;
      html2canvas(map, { useCORS: true })
        .then((canvas) => {
          const new_canvas = document.querySelector('#newCanvas').appendChild(canvas);
          img = new_canvas.toDataURL('image/png');
          document.querySelector('#newCanvas').removeChild(canvas);
        })
        .then(() => {
          this.sendDocument(img);
        });
    },

    async sendDocument(imgData) {
      await axios.post('/projects/download_pdf_vue', {
        project_id: this.projectId,
        img_data: imgData,
        site_plan_or_map: this.documentType,
        landscape_or_portrait: this.aspect,
        seismic: false,
        rmp: false,
        zoning: false,
      })
        .then((response) => {
          this.sitemapdocId = response.data.projectdoc_id;
          this.siteplanId = response.data.projectdoc_id;
          this.showLoading = false;
        })
        .catch(() => {
          this.alertMessage = 'Something went wrong, document not created';
          this.showLoading = false;
        });
    },

    siteMapLink() {
      if (this.sitemapdocId && this.project) {
        const projectNumberString = this.project.project_number_string;
        return `/projectdocs/${this.sitemapdocId}?custom_file_name=${projectNumberString}Figure+1+Site+Location+Map.pdf`;
      }
      return '';
    },

    sitePlanLink() {
      if (this.siteplandocId && this.project) {
        const projectNumberString = this.project.project_number_string;
        return `/projectdocs/${this.siteplandocId}?custom_file_name=${projectNumberString}Figure+1+Site+Location+Plan.pdf`;
      }
      return '';
    },
  },
};
</script>
<style scoped>
  .site-map-clr-btn {
    border: 0;
    width: 21px;
    height: 21px;
    cursor: pointer;
    margin-right: 10px;
  }
  .active-tool-btn {
    border: 1px solid #CCDCEB;
    background-color: #CCDCEB !important;
  }
  .map_tool_btn:hover {
    background-color: #FBFBFB;
  }
  .map_tool_btn {
    margin: 3px;
    border: 1px solid rgb(222, 218, 218);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 30px;
    height: 30px;
  }
  .map-object-layer {
    color: #005199;
    font-weight: bold;
  }
  .map-object-layer:hover {
    color: #005199;
    background-color: #FBFBFB;
  }
  .map-object-active {
    color: #005199;
    background-color: #E5EEF5 !important;
  }
  .dot {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: inline-block;
    background-color: #F04C25;
  }
  .property-dot {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: inline-block;
    border: 1px solid #005199;
  }
  .map-container-portrait {
    width: 600px;
    height: 790px;
  }
  .map-container-landscape {
    min-width: 790px!important;
    width: 790px!important;
    height: 600px;
  }
  .overflow {
    overflow-x: scroll;
  }
</style>
