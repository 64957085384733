<template>
    <div class='sl-mr-5px'>
        <Link id='sl-link-open-modal' class='figma-icon' @click="openModal"/>
    </div>
    <div v-if='showMainModal' id="sl-regenerate-link-modal-container">

        <div id="sl-regenerate-link-modal-content" >
            <div class='sl-close-div'>
          <CloseIcon @click="closeModal" id="sl-regenerate-close" class='figma-icon'/>
            </div>
          <h2>Paste in Project Doc link to check expiry status</h2>
          <input @input="checkLink" v-model="projectdocLink" id='sl-regenerate-link-input' />
          <div :class="expiredClass">{{ expiredMessage }}</div>
          <div class='sl-valid-message sl-mtb-10px'>{{ regenerate_message }}</div>
          <div class='sl-valid-message sl-mtb-10px'>{{ expiration_date }}</div>
          <div v-if='expired && projectdoc_id != null'>
            <button class='sl-simple-outline-btn' @click="regenerateLink">Renew</button>
          </div>

        </div>
    </div>
  </template>

<script>
import axios from 'axios';
import Link from '../../../assets/images/icons/Icon-Link_Charcoal-Dark-100_32.svg';
import CloseIcon from '../../../assets/images/icons/Icon-Close_Charcoal-Dark-100_24.svg';

export default {
  components: {
    Link,
    CloseIcon,
  },
  data() {
    return {
      showMainModal: false,
      projectdocLink: '',
      expired: false,
      expiredMessage: '',
      expiredClass: '',
      projectdoc_id: null,
      regenerate_message: '',
      expiration_date: '',
    };
  },
  methods: {
    async regenerateLink() {
      await axios.get('/projectdocs/regenerate_direct_link', {
        params: {
          id: this.projectdoc_id,
        },
      })
        .then((response) => {
          this.regenerate_message = response.data.regenerate_message;
          this.expiration_date = `Expiration Date: ${response.data.expiration_date}`;
          this.expired = false;
          this.expiredMessage = '';
        })
        .catch(() => {
          this.showErrorMessage('An error has occurred');
        });
    },
    openModal() {
      // reset the messages on open
      if (this.projectdocLink === '') {
        this.expiredMessage = '';
        this.expiredClass = '';
        this.expiration_date = '';
      }
      this.showMainModal = true;
    },
    closeModal() {
      this.showMainModal = false;
    },
    async checkLink() {
      // reset the messages and don't do anything if the projectdoc link field is empty
      // save an API call
      if (this.projectdocLink === '') {
        this.expiredMessage = '';
        this.expiredClass = '';
        this.expiration_date = '';
        return;
      }
      await axios.get('/projects/check_projectdoc_link_expired', {
        params: {
          projectdoc_link: this.projectdocLink,
        },
      })
        .then((response) => {
          this.expired = response.data.expired;
          this.projectdoc_id = response.data.projectdoc_id;
          // reset the messages before executing the updated logic
          this.expiredMessage = '';
          this.expiredClass = '';
          this.expiration_date = '';
          if (this.expired == null) {
            this.expiredMessage = 'Project Doc link not found';
            this.expiredClass = 'sl-expired-message sl-mtb-10px';
          } else if (this.expired) {
            this.expiredMessage = 'Project Doc link has expired. Click Renew to renew this link';
            this.expiredClass = 'sl-expired-message sl-mtb-10px';
          } else {
            this.expiredMessage = 'Project Doc link is still valid.';
            this.expiration_date = `Expiration Date: ${response.data.expiration_date}`;
            this.expiredClass = 'sl-valid-message sl-mtb-10px';
          }
        })
        .catch(() => {
          this.showErrorMessage('An error has occurred');
        });
    },
  },
};
</script>

  <style scoped lang="css">
    #sl-regenerate-link-modal-container {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
    }
    #sl-regenerate-link-modal-content {
      background-color: white;
      padding: 20px;
      border-radius: 5px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
      width: 30%;
      text-align: center;
    }
    #sl-regenerate-link-input {
      width: 100%;
      padding: 12px 20px;
      margin: 8px 0;
      display: inline-block;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
    }
    #sl-link-open-modal{
        cursor: pointer;
    }
    #sl-regenerate-close {
      font-size: 30px !important;
      float: right;
      cursor: pointer;
    }
    .sl-close-div {
      display: flex;
      justify-content: flex-end;
    }
    .sl-expired-message {
      color: red;
    }
    .sl-valid-message {
      color: green;
    }
  </style>
