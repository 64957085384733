<template>
  <td class="sl-w-100px sl-flex-column sl-text-wrap">
    <div class="sl-h-12px"></div>
    <a @click="sortValues.sort(column)"
        :class="{ 'sl-blue-link': sortValues.column === column }"
        class="links-center">
        <slot></slot>
    </a>
    <div class="sl-h-12px">
      <UpArrow v-if="sortValues.column === column && sortValues.direction === 'asc'"/>
      <DownArrow v-else-if="sortValues.column === column && sortValues.direction === 'desc'"/>
    </div>
  </td>
</template>
<script>
import UpArrow from '/javascript/components/icons/UpArrowSmall.vue';
import DownArrow from '/javascript/components/icons/DownArrowSmall.vue';

export default {
  components: {
    UpArrow,
    DownArrow,
  },
  props: ['column', 'sortValues'],
};
</script>
