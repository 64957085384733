<template>
  <div id="sl-history-log-modal-container" class="sl-flex-only">
    <div class="sl-history-log-modal-content sl-max-w-680px">
      <div class="sl-flex-justify-content-end">
        <div class="sl-w-40px">
          <CloseIcon class='figma-icon' @click="$emit('closeModal')" id="sl-history-log-close"/>
        </div>
      </div>
      <div class="sl-font-size-16px">Choose contacts to send reviewer feedback:</div>
      <br>
      <br>
      <div v-if="contacts.length">
        <div class="sl-font-size-14px">Project Contacts:</div>
        <div class="sl-flex-justify-content-center">
          <table class="sl-mt-10px sl-mb-10px sl-border-t-2px-primary-blue sl-border-b-2px sl-w-100per">
            <tbody>
              <tr v-for="contact in contacts" class="sl-table__row--striped-odd sl-h-42px">
                <td class="sl-w-16px">
                  <label class="sl-custom-checkbox sl-mr-5px sl-mb-20px">
                    <input type="checkbox" @change="enableSendBtn" v-model="contact.selected">
                    <span class="checkmark sl-ml-5px"></span>
                  </label>
                </td>
                <td class="sl-bold-text sl-w-40px sl-pl-10px">{{ contact.role }}</td>
                <td class="sl-pl-10px sl-w-130px">{{ contact.name }}</td>
                <td class="sl-pl-10px">{{ contact.email }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-else>
        <div class="sl-font-size-14px">There are no available project contacts:</div>
        <div class="sl-border-t-2px-primary-blue sl-w-100per sl-mt-10px"></div>
      </div>
      <br>
      <br>
      <br>
      <div class="sl-mb-20px">
        <div class="sl-font-size-14px sl-mb-10px">Choose additional contact(s) to include on the email (optional):</div>
        <div class="sl-flex-align-items-flex-start">
          <select v-model="staffNameSearch" class="sl-h-32px sl-w-300px sl-pl-10px sl-border-1px-medium-charcoal">
            <option v-for="staff in staff" :value="staff">{{ staff.name }}</option>
          </select>
          <plusIcon v-if="staffNameSearch" @click="addStaffContact" transform='scale(0.7)'
                    class="figma-icon-blue links-center sl-mt-5px sl-ml-10px"/>
        </div>
      </div>

      <div v-if="staffSelected.length" >
        <div class="sl-font-size-14px">Additional Contacts:</div>
        <div class="sl-flex-justify-content-center">
          <table class="sl-mt-10px sl-mb-10px sl-border-t-2px-primary-blue sl-border-b-2px sl-w-100per">
            <tbody>
              <tr v-for="contact in staffSelected" class="sl-table__row--striped-odd sl-h-42px">
                <td class="sl-w-16px">
                  <label class="sl-custom-checkbox sl-mr-5px sl-mb-20px">
                    <input type="checkbox" @change="enableSendBtn" v-model="contact.selected">
                    <span class="checkmark sl-ml-5px"></span>
                  </label>
                </td>
                <td class="sl-pl-10px sl-w-130px">{{ contact.name }}</td>
                <td class="sl-pl-10px">{{ contact.email }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <br>
      <br>
      <div class='sl-submit-report sl-mtb-20px sl-flex-justify-content-center'>
        <button @click="$emit('sendEmail')"
                :class="{'sl-simple-outline-btn-disabled':!emailSelected}"
                class="sl-simple-outline-btn sl-w-125px sl-h-32px">
                Send Email
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from '../../../../assets/images/icons/Icon-Close_Charcoal-Dark-100_24.svg';
import plusIcon from '../../../../assets/images/icons/Icon-Add_Charcoal-Dark-100_24.svg';

export default {
  props: ['contacts', 'staff', 'staffSelected'],
  components: {
    CloseIcon,
    plusIcon,
  },

  data() {
    return {
      emailSelected: false,
      staffNameSearch: null,
    };
  },

  methods: {
    // If at least one contact is selected, enable the send email button
    enableSendBtn() {
      const allContacts = this.contacts.concat(this.staffSelected);
      for (let i = 0; i < allContacts.length; i++) {
        if (allContacts[i].selected) {
          this.emailSelected = true;
          return;
        }
      }
      this.emailSelected = false;
    },

    // Add a staff member to the list of contacts to recieve the email
    addStaffContact() {
      if (this.staffSelected.includes(this.staffNameSearch)) {
        alert(`${this.staffNameSearch.name} is already included as a contact on this email.`);
      } else {
        this.staffSelected.push(this.staffNameSearch);
        this.staffNameSearch = null;
        this.enableSendBtn();
      }
    },
  },
};
</script>
