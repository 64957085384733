<template>
  <div v-show="isVisible" class="tooltip" :class="position" :style="tooltipStyle">
    <div class="tooltip-title">
      <slot name="title">Default Title</slot> <!-- Default content can be overridden -->
    </div>
    <div class="tooltip-content">
      <slot name="content">Default Content</slot> <!-- Default content can be overridden -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    position: {
      type: String,
      default: 'top', // Sets the default position of the tooltip
    },
  },
  data() {
    return {
      isVisible: false,
      tooltipStyle: {
        position: 'absolute',
        zIndex: '1000',
      },
    };
  },
  mounted() {
    // Add event listeners to the parent element
    const parent = this.$el.parentNode;
    parent.addEventListener('mouseover', this.showTooltip);
    parent.addEventListener('mouseleave', this.hideTooltip);
  },
  beforeDestroy() {
    // Clean up event listeners when the component is destroyed
    const parent = this.$el.parentNode;
    parent.removeEventListener('mouseover', this.showTooltip);
    parent.removeEventListener('mouseleave', this.hideTooltip);
  },
  methods: {
    showTooltip() {
      this.isVisible = true;
    },
    hideTooltip() {
      this.isVisible = false;
    },
  },
};
</script>

<style scoped>
.tooltip {
  display: inline-block;
  background-color: var(--white);
  color: var(--primary-blue);
  border: solid 1px var(--primary-blue);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 16px */
  padding: 8px;
  position: absolute;
  z-index: 1000;
  max-width: 400px;
  min-width: 200px; /* Set a minimum width to prevent the tooltip from becoming too narrow */
  box-sizing: border-box;
  white-space: normal;
  word-wrap: break-word;
}

.tooltip-title {
  text-decoration: underline;
}

/* Position the tooltip above the parent */
.top {
  transform: translateX(-50%);
  left: 50%;  /* Center horizontally relative to the parent */
  bottom: 100%;  /* Position it above the parent */
  margin-bottom: 4px;  /* Space between the tooltip and the parent */
}

.top::before {
  content: "";
  position: absolute;
  bottom: -11px;  /* Positioned at the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;  /* Center the arrow horizontally */
  border-width: 5px;
  border-style: solid;
  border-color: var(--primary-blue) transparent transparent transparent;  /* Arrow points upwards */
}

.top::after {
  content: "";
  position: absolute;
  bottom: -10px;  /* Positioned at the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;  /* Center the arrow horizontally */
  border-width: 5px;
  border-style: solid;
  border-color: var(--white) transparent transparent transparent;  /* Arrow points upwards */
}
</style>
