<template>
  <!-- PCA IRV -->
  <tr v-if="tableName==='pca irv'" class='sl-h-45px' style='font-size: 13.33px;'>
    <td></td>
    <td></td>
    <td></td>
    <!-- Structure -->
    <td class='sl-bold-text'>Total</td>
    <!-- Area -->
    <td>{{ b_column }}</td>
    <!-- Total Cost -->
    <td>{{ c_column }}</td>
    <!-- Cost Per Sf-->
    <td>{{ d_column }}</td>
  </tr>

  <!-- Fannie Assisted Living Unit Mix -->
  <tr v-if="tableName==='fannie assisted living unit mix'" class='sl-h-45px' style='font-size: 13.33px;'>
    <td></td>
    <td></td>
    <td></td>
    <!-- Unit Type -->
    <td class='sl-bold-text'>Total</td>
    <!-- Total No. of Beds -->
    <td>{{ b_column }}</td>
    <!-- Total No. of Beds Occupied -->
    <td>{{ c_column }}</td>
    <!-- Total No. of Units -->
    <td>{{ d_column }}</td>
    <!-- Total No. of Units Occupied -->
    <td>{{ e_column }}</td>
    <!-- Avg. Square Feet/Unit -->
    <td>{{ f_column }}<span class='sl-ml-10px sl-bold-text'>(Avg)</span></td>
    <!-- No. of Beds Vacant -->
    <td>{{ g_column }}</td>
  </tr>

    <!-- Fannie Multi-Family Unit Mix -->
  <tr v-if="tableName==='fannie multi-family unit mix'" class='sl-h-45px' style='font-size: 13.33px;'>
    <td class='sl-bold-text' colspan='5'>Total</td>
    <!-- Total No. of Occupied -->
    <td>{{ c_column }}</td>
    <!-- Total No. of Vacant -->
    <td>{{ d_column }}</td>
    <!-- Total No. of Down -->
    <td>{{ e_column }}</td>
    <!-- Total No. of Non-Revenue -->
    <td>{{ f_column }}</td>
    <!-- Total No. of Units -->
    <td>{{ g_column }}</td>
    <!-- Total Avg. Square Feet/Unit -->
    <td>{{ h_column }}</td>
    <!-- Total Square Footage -->
    <td>{{ i_column }}</td>
  </tr>
</template>
<script>
export default {
  name: 'ReportTableTotalsRow',
  props: ['tableName', 'tableData'],

  data() {
    return {
      a_column: null,
      b_column: null,
      c_column: null,
      d_column: null,
      e_column: null,
      f_column: null,
      g_column: null,
      h_column: null,
      i_column: null,
    };
  },

  mounted() {
    const table = document.getElementById('pl_table_body');
    if (this.tableName === 'pca irv') {
      this.pcaIrvTotals();
      this.pcaIrvCostPerSf();
      table.addEventListener('input', this.pcaIrvTotals);
    } else if (this.tableName === 'fannie assisted living unit mix') {
      this.fannieAssistedLivingUnitTotals();
      table.addEventListener('input', this.fannieAssistedLivingUnitTotals);
    } else if (this.tableName === 'fannie multi-family unit mix') {
      this.fannieMultiFamilyUnitMix();
      table.addEventListener('input', this.fannieMultiFamilyUnitMix);
    }
  },

  methods: {
    fannieAssistedLivingUnitTotals() {
      // Used for totals row values
      let b_column = 0;
      let c_column = 0;
      let d_column = 0;
      let e_column = 0;
      let g_column = 0;
      let h_column = 0;
      let i_column = 0;
      let avg_sq_ft_per_unit = 0;
      for (let i = 0; i < this.tableData.length; i++) {
        const row = this.tableData[i];
        if (row === undefined) { return; }
        // Update the row values
        b_column += Number(row.b_column);
        c_column += Number(row.c_column);
        d_column += Number(row.d_column);
        e_column += Number(row.e_column);
        g_column += Number(row.g_column);
        h_column += Number(row.h_column);
        i_column += Number(row.i_column);
        avg_sq_ft_per_unit += Number(row.f_column) * Number(row.d_column);
      }
      this.b_column = b_column;
      this.c_column = c_column;
      this.d_column = d_column;
      this.e_column = e_column;
      // Calculate the average square feet per unit (f_column)
      this.f_column = this.round(avg_sq_ft_per_unit / this.d_column, 2);
      this.g_column = g_column;
      this.h_column = h_column;
      this.i_column = i_column;
    },

    // Sumation to calculate total No. of Units and Total Square Footage for Fannie Multi-Family Unit Mix Table
    fannieMultiFamilyUnitMix() {
      // Used for totals row values
      let c_column = 0;
      let d_column = 0;
      let e_column = 0;
      let f_column = 0;
      let g_column = 0;
      let i_column = 0;

      for (let i = 0; i < this.tableData.length; i++) {
        const row = this.tableData[i];
        if (row === undefined) { return; }
        // Update the row values
        row.g_column = Number(row.c_column) + Number(row.d_column) + Number(row.e_column) + Number(row.f_column);
        row.i_column = Number(row.g_column) * Number(row.h_column);
        // Update the totals row values
        c_column += Number(row.c_column);
        d_column += Number(row.d_column);
        e_column += Number(row.e_column);
        f_column += Number(row.f_column);
        g_column += Number(row.c_column) + Number(row.d_column) + Number(row.e_column) + Number(row.f_column);
        i_column += Number(row.g_column) * Number(row.h_column);
      }
      this.c_column = c_column;
      this.d_column = d_column;
      this.e_column = e_column;
      this.f_column = f_column;
      this.g_column = g_column;
      this.h_column = this.round(i_column / g_column, 2);
      this.i_column = i_column;
    },

    // Calculate the cost per square foot cells for the PCA IRV table
    pcaIrvCostPerSf() {
      const table = document.getElementById('pl_table_body');
      table.addEventListener('input', () => {
        for (let i = 0; i < this.tableData.length; i++) {
          const row = this.tableData[i];
          if (row === undefined) { return; }
          let costPerSf;
          const area = parseFloat(row.b_column);
          const totalCost = parseFloat(row.c_column);
          if (area === 0 || area == null) {
            costPerSf = 0;
          } else {
            costPerSf = this.round(totalCost / area, 2);
          }
          row.d_column = this.numberToCurrency(costPerSf || 0);
        }
      });
    },

    pcaIrvTotals() {
      // Sum the b_column, c_column, and d_column values for the PCA IRV table
      const b_column_sum = this.tableData.reduce((acc, row) => acc + parseFloat(row.b_column || 0), 0);
      const c_column_sum = this.tableData.reduce((acc, row) => acc + parseFloat(row.c_column || 0), 0);
      // Remove all non-numeric characters from the d_column values before summing
      const d_column_sum = this.tableData.reduce((acc, row) => acc + parseFloat(row.d_column?.replace(/[^\d.-]/g, '') || 0), 0);
      this.b_column = this.numberWithDelimiter(b_column_sum);
      this.c_column = this.numberToCurrency(c_column_sum);
      // Cost per square foot (d_column), is an average of the cost per square foot values
      if (this.tableData.length === 0) {
        this.d_column = 0;
      } else {
        this.d_column = this.numberToCurrency(d_column_sum / this.tableData.length);
      }
    },

    numberToCurrency(number) {
      return `$${Intl.NumberFormat().format(Math.round(number * 100) / 100)}`;
    },

    numberWithDelimiter(number) {
      return Intl.NumberFormat().format(Math.round(number));
    },

    round(number, precision = 0) {
      if (precision > 0) {
        return Math.round(number * (10 ** precision)) / (10 ** precision);
      }
      return Math.round(number);
    },
  },
};
</script>
