<!-- Pass in the number of pages needed and the current page as props -->
<!-- Listen to the emit method, changePage, in parent component -->
<template>
  <div v-if="numberOfPages > 1" class="sl-paginate sl-text-align-center">
    <!-- Left Arrow -->
    <i v-if="currentPage > 1" @click="previousPage()" class="fa fa-angle-left sl-paginate-arrow"></i>
    <!-- Page Number Links -->
    <div v-for="pageNumber in pageNumbers"
         @click="changePage(pageNumber)"
         :class="{ 'sl-current-page':pageNumber == currentPage }"
         class="sl-paginate-link">
         <span v-if="pageNumber == currentPage">{{ pageNumber }}</span>
         <span v-else-if="pageNumber == '...'">{{ pageNumber }}</span>
         <a v-else class="links-center">{{ pageNumber }}</a>
    </div>
    <!-- Right Arrow -->
    <i v-if="currentPage < numberOfPages" @click="nextPage()" class="fa fa-angle-right sl-paginate-arrow"></i>
  </div>
</template>
<script>
export default {
  props: ['numberOfPages', 'currentPage'],

  data() {
    return {
      pageNumbers: [],
    };
  },

  async created() {
    this.createPageNumbers();
  },

  watch: {
    // Whenever the current page changes update the page links
    currentPage() {
      this.createPageNumbers();
    },

    // Whenever the number of pages changes update the page links
    numberOfPages() {
      this.createPageNumbers();
    },
  },

  methods: {
    // Create an array for the page number links
    createPageNumbers() {
      let pageNumbers;
      const numberOfPages = parseInt(this.numberOfPages);
      const currentPage = parseInt(this.currentPage);
      // e.g. [1, 2, 3, 4, 5, 6, 7, 8, 9, '...', 17, 18]
      if (numberOfPages > 9 && currentPage < 9) {
        pageNumbers = this.spreadArray(1, 9);
        pageNumbers.push('...');
        pageNumbers.push(numberOfPages - 1);
        pageNumbers.push(numberOfPages);
        // e.g. [1, '...', 12, 13, 14, 15, 16, 17, 18]
      } else if (numberOfPages > 9 && currentPage >= 9 && currentPage >= (numberOfPages - 3)) {
        pageNumbers = this.spreadArray((currentPage - 6), numberOfPages);
        pageNumbers = pageNumbers.reverse();
        pageNumbers.slice(0, 7);
        pageNumbers = pageNumbers.reverse();
        pageNumbers.unshift('...');
        pageNumbers.unshift(1);
      }
      // e.g. [1, ..., 11, 12, 13, 14, 15, 16, '...', 17, 18]
      else if (numberOfPages > 9 && currentPage >= 9) {
        pageNumbers = this.spreadArray((currentPage - 5), currentPage);
        pageNumbers.push(currentPage + 1);
        pageNumbers.push('...');
        pageNumbers.push(numberOfPages - 1);
        pageNumbers.push(numberOfPages);
        pageNumbers.unshift('...');
        pageNumbers.unshift(1);
      }
      // e.g. [1, 2, 3, 4]
      else {
        pageNumbers = this.spreadArray(1, numberOfPages);
      }
      this.pageNumbers = pageNumbers;
    },

    // Create an array from [firtNumber..lastNumber]
    spreadArray(first, last) {
      const array = [];
      for (let i = first; i <= last; i++) {
        array.push(i);
      }
      return array;
    },

    changePage(pageNumber) {
      this.$emit('changePage', pageNumber);
    },

    previousPage() {
      this.$emit('changePage', this.currentPage - 1);
    },

    nextPage() {
      this.$emit('changePage', this.currentPage + 1);
    },
  },
};
</script>
<style scoped>
  .sl-current-page {
    font-size: 22px !important;
    color: var(--primary-blue);
  }
  .sl-paginate-link {
    font-size: 14px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .sl-paginate-arrow {
    font-size: 40px;
    color: #005199;
  }
  .sl-paginate-arrow:hover {
    color:#F04C25;
  }
</style>
