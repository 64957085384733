<template>
  <div class="sl-flex-only sl-w-100per sl-mb-40px">
    <div class="sl-w-100px sl-ml-50px sl-flex-justify-content-end sl-mr-10px">Financing Agency</div>
    <div class="sl-border-l-2px-medium-charcoal sl-pl-20px">
      <table @input="$emit('service-changed')">
        <thead>
          <tr>
            <td style="width: 250px">Agency Type</td>
            <td style="width: 570px">Loan Type</td>
            <td style="width: 200px">Conversion Fee</td>
          </tr>
        </thead>
        <tbody>
          <tr class='sl-h-37px'>
            <td>
              <div class="pricing-factor-field-1">Undecided</div>
            </td>
            <td>
              <div class="pricing-factor-field-2">Conventional</div>
            </td>
            <td>
              <input class="pricing-factor-field-3 sl-disable-number-input-up-down-arrows"
                     v-model="localConversionFee"
                     @change="updateConversionFee"/>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    conversionFee: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      localConversionFee: this.conversionFee,
    };
  },
  methods: {
    updateConversionFee() {
      this.$emit('update-conversion-fee', this.localConversionFee);
    },
  },
};
</script>
