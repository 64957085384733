<template>
  <div class='sl-flex-justify-content-center sl-pb-10px'>
    <div class='sl-w-500px'>
      <strong class="report-table-instructions">Discuss ALL listings for the subject property in this section.
        Be sure to check all listings in the radius report for additional and historical addresses,
        and don’t forget to check the unplottable listings.
        If any subject property address falls under unplottable, discuss it here.
      </strong>
      <br><br>
      <strong class="report-table-instructions">
        For NPL sites where the subject property is located within an NPL Area of Concern (AOC):
      </strong>
      <ul class="instructions-list">
        <li class='report-table-instructions instructions-list-item'>
          Discuss the listing in Section 4.2.2 if the subject property has the potential to be or is confirmed as a potential responsible party (PRP)
        </li>
        <li class='report-table-instructions instructions-list-item'>
          If the subject property is not the source of the contamination
          (for example, no record of hazardous substances onsite, SP received NFA letter and/or the SP is NOT listed on the PRP list)
          <ul class="instructions-list">
            <li class='report-table-instructions instructions-list-item'>
              Discuss the listing in Section 4.2.3 if the adjoining site is the source of contamination
            </li>
            <li class='report-table-instructions instructions-list-item'>
              Discuss the listing in 4.2.4 if a site in the surrounding area is a source of contamination
            </li>
          </ul>
        </li>
        <li class='report-table-instructions instructions-list-item'>
          Regional contamination of hazardous substances, including PFAS is a REC to the subject property unless
          the full extent of the contamination has been identified as off-site AND there is a low likelihood it will impact the subject property in the future.
        </li>
      </ul>
    </div>
  </div>
</template>
