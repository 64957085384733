<template>
  <div class="vue-modal-background sl-box-styling" v-if="modal_visibility">
    <div v-if="show_confirmation_prompt" class="sl-custom-overlay"></div>
    <div v-if="show_confirmation_prompt" class="sl-custom-confirm-box sl-text-align-center sl-flex-justify-align-center">
      <div class="sl-p-all-20px">
        <p class="sl-paragraph-med-bold sl-partner-blue-text">Are you sure?</p>

        <p class="sl-paragraph-med">Any updates made will not be saved.</p>

        <p class="sl-mt-30px">
          <button class="sl-simple-outline-btn sl-mr-30px" @click="cancel_close_modal">cancel</button>
          <button class="sl-simple-outline-btn" @click="close_modal">Continue</button>
        </p>
      </div>
    </div>

    <div class="vue-modal-content">
      <!-- Report Addressee -->
      <div v-if="this.add_update_data_modal.section === 1">
        <div class="sl-flex-justify-content-space-btwn sl-border-bottom-2px-charcoal sl-pb-5px sl-mb-10px">
          <div class="sl-paragraph-med-bold">Report Addressed To</div>
          <xIcon class="sl-cursor-pointer" @click="close_with_no_save" />
        </div>

        <ReportAddressee :passed_in_order_form_data="order_form_data_to_update" :dropdown_selections="dropdown_selections" />
      </div>

      <!-- Invoice Details -->
      <div v-if="this.add_update_data_modal.section === 2">
        <div class="sl-flex-justify-content-space-btwn sl-border-bottom-2px-charcoal sl-pb-5px sl-mb-10px">
          <div class="sl-paragraph-med-bold">Invoice Details</div>
          <xIcon class="sl-cursor-pointer" @click="close_with_no_save" />
        </div>

        <InvoiceDetails :passed_in_order_form_data="order_form_data_to_update" :dropdown_selections="dropdown_selections" />
      </div>

      <!-- Site & Property Information Contact -->
      <div v-if="this.add_update_data_modal.section === 3" >
        <div class="sl-flex-justify-content-space-btwn sl-border-bottom-2px-charcoal sl-pb-5px sl-mb-10px">
          <div class="sl-paragraph-med-bold">Site & Property Information Contact</div>
          <xIcon class="sl-cursor-pointer" @click="close_with_no_save" />
        </div>

        <SiteAndPropertyContact :passed_in_order_form_data="order_form_data_to_update" :dropdown_selections="dropdown_selections" />
      </div>

      <!-- Email CC -->
      <div v-if="this.add_update_data_modal.section === 4">
        <div class="sl-flex-justify-content-space-btwn sl-border-bottom-2px-charcoal sl-pb-5px sl-mb-10px">
          <div class="sl-paragraph-med-bold">Add or Edit Email CCs</div>
          <xIcon class="sl-cursor-pointer" @click="close_with_no_save" />
        </div>

        <p class="sl-bold-text">
          If completed, email notifications, such as project order summaries and specific project updates like order uploads or added notes,
          will send to the designated "email CCs" for this particular project.
        </p>

        <p class="sl-bold-text">
          Separate multiple emails with a comma
        </p>

        <div class="sl-mt-10px">
          <textarea style="width: 99%; height: 100px;" maxlength="200" v-model="this.order_form_data_to_update.email_cc_data"></textarea>
        </div>
      </div>

      <div class="sl-text-align-right sl-mt-30px">
        <span class="sl-red-text" v-if="incomplete_fields">* There are incomplete fields. </span>
        <button class="sl-simple-outline-btn" @click="save_and_close">save</button>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep as _cloneDeep, isEqual as _isEqual } from 'lodash';
import InvoiceDetails from './InvoiceDetails.vue';
import ReportAddressee from './ReportAddressee.vue';
import SiteAndPropertyContact from './SiteAndPropertyContact.vue';
import xIcon from '../../../../assets/images/icons/Icon-Clear_Charcoal-Dark-100_20.svg';

export default {
  props: {
    passed_in_order_form_data: {
      type: Object,
    },
    add_update_data_modal: {
      type: Object,
    },
    dropdown_selections: {
      type: Object,
    },
  },

  components: {
    InvoiceDetails,
    ReportAddressee,
    SiteAndPropertyContact,
    xIcon,
  },

  data() {
    return {
      incomplete_fields: false,
      order_form_data_to_update: {},
      show_confirmation_prompt: false,
    };
  },

  computed: {
    // Gets called when the add_update_data_modal.show changes (when the modal is shown or hidden)
    modal_visibility() {
      // reset the incomplete_fields to false
      this.incomplete_fields = false;

      if (this.add_update_data_modal.show) {
        // Make a copy of the object passed_in_order_form_data to order_form_data_to_update
        // do not directly assign the objects to each other because we want to keep the original object if the user cancels the changes
        this.order_form_data_to_update = _cloneDeep(this.passed_in_order_form_data);

        // console.log(this.order_form_data_to_update);
        return true;
      }
      return false;
    },
  },

  methods: {
    close_with_no_save() {
      // Check if the order_form_data_to_update is different from the original passed_in_order_form_data
      if (_isEqual(this.passed_in_order_form_data, this.order_form_data_to_update)) {
        this.$emit('hide_pop_up');
      } else {
        // Show "are you sure" section
        this.show_confirmation_prompt = true;
      }
    },

    save_and_close() {
      // check if the email_cc_data is valid
      // if any invalid emails found, show an error message and return, if valid, continue and save
      if (this.add_update_data_modal.section === 4 && this.order_form_data_to_update.email_cc_data !== '') {
        const valid_emails = [];
        const emails_to_check = this.order_form_data_to_update.email_cc_data.split(',');
        let invalid_emails = false;

        for (let i = 0; i < emails_to_check.length; i++) {
          emails_to_check[i] = emails_to_check[i].trim();

          if (this.validate_simple_email(emails_to_check[i])) {
            valid_emails.push(emails_to_check[i]);
          } else {
            invalid_emails = true;
          }
        }

        if (invalid_emails) {
          alert('Invalid email formatting found. Please check and try again.');
          this.incomplete_fields = true;
          return;
        }

        this.order_form_data_to_update.email_cc_data = valid_emails.join(', ');
      }

      // send the updated order_form_data_to_update to the parent component
      this.$emit('update_order_form_data', _cloneDeep(this.order_form_data_to_update));

      // send up the event to hide the modal
      this.$emit('hide_pop_up');
    },

    close_modal() {
      // reset the show_confirmation_prompt to false
      this.show_confirmation_prompt = false;

      this.$emit('hide_pop_up');
    },

    cancel_close_modal() {
      // reset the show_confirmation_prompt to false
      this.show_confirmation_prompt = false;
    },

    // simple email validation. EX: anystring@anystring.anystring
    // returns true or false
    validate_simple_email(email) {
      // regex for email validation
      const regex = /\S+@\S+\.\S+/;
      return email.match(regex);
    },
  },
};
</script>

<style scoped>
  .vue-modal-background {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.1);
  }

  .vue-modal-content {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    width: 100%;
    max-width: 600px;
    max-height: 80%;
    overflow: auto;
  }

  .sl-custom-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .sl-custom-confirm-box {
    position: absolute;
    z-index: 4;
    background-color: #fefefe;
    box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
    max-width: 600px;
  }
</style>
