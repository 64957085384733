<template>
  <div class='sl-flex-justify-content-center sl-pb-30px'>
    <div class='sl-w-500px'>
      <strong class="report-table-instructions">Discuss all listings for the adjoining properties here;
        copy and paste this to create a new paragraph for each adjoining property identified.
        Be sure to review unplottable listings for any adjoining property address and include discussion here.
      </strong>
      <br><br>
      <strong class="report-table-instructions">
        To assist you in making a determination if a release has a risk of impacting the subject property,
        consider the following guidelines:
      </strong>
      <ul class="instructions-list">
        <li class='report-table-instructions instructions-list-item'>
          Existing data (must be below CURRENT acceptable regulatory criteria)
        </li>
        <li class='report-table-instructions instructions-list-item'>
          Nature of historical and existing operations related to the listing(s)
        </li>
        <li class='report-table-instructions instructions-list-item'>
          Listing is a pointer to other listings (ECHO and FINDS)
        </li>
        <li class='report-table-instructions instructions-list-item'>
          Only if one or more of the above are applicable as determined by the EP, then the following can be stated:
          lack of a documented release or violation
        </li>
        <li class='report-table-instructions instructions-list-item'>
          Petroleum hydrocarbons: 1/10 mile or 528 feet from UST/dispenser area AND
        </li>
        <li class='report-table-instructions instructions-list-item'>
          All other releases (including chlorinated solvents): 1/3 mile or 1,760 feet
          (Source: ASTM E2600-15 Standard Guide for Vapor Encroachment)
          <ul class="instructions-list">
            <li class='report-table-instructions instructions-list-item'>
              Measure from the closest subject property boundary to the release site.
              If the extent of the plume is known, measure to the closest boundary of the plume to the subject property.
            </li>
            <li class='report-table-instructions instructions-list-item'>
              If the plume has not been characterized, measure to the closest boundary of origin of the release.
            </li>
            <li class='report-table-instructions instructions-list-item'>
              Take into account the status of remediation and if higher concentrations are upgradient from the subject property
              the release is likely to impact the subject property
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>
